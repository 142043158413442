import React from 'react'
import { default as Link } from 'components/MenuLink'
import {
  getAssigneeLanding1Route,
  getOwnerLandingRoute,
  getJobsRoute,
  getJobNewRoute,
  getPolicyRoute,
  getCategoriesRoute,
  getSignInRoute,
  getSignUpRoute,
  getUsersRoute,
} from 'routes'
import Header from 'components/Header'
import SocialIcon from 'components/SocialIcon'
import { ReactComponent as AppStoreIcon } from 'assets/images/social-icons/app-store-apple.svg'
import InstallPWABtn from 'components/InstallPWABtn'
import Loader from 'components/Loader'
import cn from 'classnames'
import css from './styles.module.scss'
// import logoColored from 'assets/images/logo/logo-h-c.png'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'

const Layout = ({ children, loading }) => {
  const { data } = useQuery(
    gql`
      query {
        getMe {
          me {
            id
          }
        }
      }
    `,
    {
      pollInterval: 5000,
    }
  )
  const meExists = get(data, 'getMe.me.hasUnreadMessages')
  return (
    <div className={css.pageAndFooter}>
      <div className={css.page}>
        <Header />
        {loading ? <Loader type="page" /> : children}
      </div>
      {/*<InstallPWA />*/}
      <div className={cn(css.section, css.footer)}>
        <div className={css.wrapper}>
          <div className={css.left}>
            <div className={css.col}>
              <p className={css.title}>Company</p>
              <div className={css.links}>
                <a className={css.link} href={'/'}>
                  About
                </a>
                <a className={css.link} href={'https://blog.suretask.com'}>
                  Blog
                </a>
                <a className={css.link} href={'/'}>
                  Knowledge base
                </a>
                <Link className={css.link} to={getCategoriesRoute()}>
                  Categories
                </Link>
                <Link className={css.link} to={getPolicyRoute()}>
                  Privacy policy
                </Link>
              </div>
            </div>
            <div className={css.col}>
              <p className={css.title}>Professionals</p>
              <div className={css.links}>
                <Link className={css.link} to={getAssigneeLanding1Route()}>
                  SureTask for Pros
                </Link>
                <Link className={css.link} to={getJobsRoute()}>
                  Browse all tasks
                </Link>
              </div>
            </div>
            <div className={css.col}>
              <p className={css.title}>Customers</p>
              <div className={css.links}>
                <Link className={css.link} to={getOwnerLandingRoute()}>
                  How it works
                </Link>
                {!meExists && (
                  <Link className={css.link} to={getSignInRoute()}>
                    Login
                  </Link>
                )}
                {!meExists && (
                  <Link className={css.link} to={getSignUpRoute()}>
                    Sign up
                  </Link>
                )}
                <Link className={css.link} to={getUsersRoute()}>
                  Find a Pro
                </Link>
                <Link className={css.link} to={getJobNewRoute()}>
                  Post a task
                </Link>
              </div>
            </div>
          </div>
          <div className={css.right}>
            <div className={css.col}>
              <p className={css.title}>Contact us</p>
              <div className={css.links}>
                <a className={css.link} href={'mailto:support@suretask.com'}>
                  support@suretask.com
                </a>
              </div>
              <div className={css.social}>
                <div className={css.socialLinks}>
                  <a className={css.socialLink} href={'https://www.facebook.com/SureTask.co.uk/'}>
                    <SocialIcon size={32} name={'facebook'} />
                  </a>
                  <a className={css.socialLink} href={'https://www.instagram.com/suretask.co.uk/'}>
                    <SocialIcon size={32} name={'instagram'} />
                  </a>
                  <a className={css.socialLink} href={'https://mobile.twitter.com/sure_task'}>
                    <SocialIcon size={32} name={'twitter'} />
                  </a>
                  <a className={css.socialLink} href={'https://m.me/SureTask.co.uk'}>
                    <SocialIcon size={32} name={'facebook-messages'} />
                  </a>
                  <a className={css.socialLink} href={'https://www.youtube.com/channel/UC6PgkLUumaM_rmjyNEchuuQ'}>
                    <SocialIcon size={32} name={'youtube'} />
                  </a>
                </div>
                <a className={css.appStoreLink} href={'https://apps.apple.com/us/app/suretask/id1495826976'}>
                  <AppStoreIcon className={css.appStoreLogo} />
                </a>
                <InstallPWABtn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
