import React, { useState } from 'react'
import PrettyText from 'components/PrettyText'
import gql from 'graphql-tag'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import JobInvationModal from 'components/JobInvationModal'
import Segment from 'components/Segment'
import Button from 'components/Button'
import Gallery from 'components/Gallery'
import getUploadUrl from 'utils/getUploadUrl'
import Reviews from 'components/Reviews'
import css from './styles.module.scss'
import BlockUser from 'components/BlockUser'
import Alert from 'components/Alert'
import { canBlockUser } from 'utils/can'
import toHumanDate from 'utils/toHumanDate'
import UserContacts from 'components/UserContacts'
import ProfileManagerEditor from 'components/ProfileManagerEditor'
import Helmet from 'react-helmet'
import DocumentTitle from 'react-document-title'

const UserPage = ({ match }) => {
  const [isJobInvationModalOpen, setJobInvationModalOpen] = useState(false)
  return (
    <PageWrapper
      query={gql`
        query($input: GetUserInput!) {
          getMe {
            me {
              id
              permissions
            }
          }
          getUser(input: $input) {
            user {
              id
              deleted
              avatar
              city
              displayName
              about
              coverImage
              portfolioImages
              asAssigneePoisitiveReviewsCount
              asAssigneeNegativeReviewsCount
              asAssigneeNeutralReviewsCount
              asOwnerPoisitiveReviewsCount
              asOwnerNegativeReviewsCount
              asOwnerNeutralReviewsCount
              isBusiness
              regNumber
              skills
              canBeAssignee
              blocked
              blockComment
              createdAt
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
            }
          }
        }
      `}
      parser={[['user', 'getUser.user'], ['me', 'getMe.me']]}
      variables={{ input: { userId: match.params.userId } }}
      checkExists={({ user }) => user && !user.deleted}
    >
      {({ user, me }) => {
        const isMeCanBlock = canBlockUser(me)
        const schemaOrg = {
          '@context': 'https://schema.org/',
          '@type': 'Person',
          name: user.displayName,
          city: user.city,
        }
        if (user.blocked && !isMeCanBlock)
          return (
            <PageSegment title={'Blocked user'} titleAlign="left">
              {user.blocked && <Alert message="User profile has been blocked." />}
            </PageSegment>
          )
        return (
          <>
            <Helmet>
              <title>{user.displayName} | SureTask connects you to safe and reliable professionals in your area</title>
              {!!user.about && (
                <meta name="description" content={user.about} />
              )}
              {!!user.skills && !!user.skills.length && (
                <meta name="keywords" content={user.skills.map((skill, i) => skill)} />
              )}
              <meta property="og:title" content={user.displayName} />
              <meta property="og:image" content={getUploadUrl(user.avatar, 'photo')} />
              <meta property="og:type" content={
                !user.canBeAssignee
                ? user.isBusiness
                  ? 'Business profile'
                  : 'Personal profile'
                : user.isBusiness
                ? 'Business service provider profile'
                : 'Personal service provider profile'
              } />
              {!!user.about && (
                <meta property="og:description" content={user.about} />
              )}
              <meta property="og:url" content={window.location.href} />
              <meta property="og:site_name" content="SureTask.com - connects you to safe and reliable professionals in your area" />
            </Helmet>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrg) }} />
            <div className={css.section}>
              <div className={css.wrapper}>
                <div
                  className={css.panel}
                  style={{
                    backgroundImage: `url("${getUploadUrl(user.coverImage, 'coverImage')}")`,
                  }}
                />
                <div className={css.cols}>
                  <div className={css.leftCol}>
                    <div className={css.avatarPlace}>
                      <img className={css.avatar} alt="" src={getUploadUrl(user.avatar, 'avatar')} />
                    </div>
                    <p className={css.name}>{user.displayName}</p>
                    <div className={css.attrs}>
                      <p className={css.attr}>
                        {!user.canBeAssignee
                          ? user.isBusiness
                            ? 'Business profile'
                            : 'Personal profile'
                          : user.isBusiness
                          ? 'Business service provider profile'
                          : 'Personal service provider profile'}
                      </p>
                      {user.city && <p className={css.attr}>City: {user.city}</p>}
                      {user.isBusiness && user.regNumber && (
                        <p className={css.attr}>Registration number: {user.regNumber}</p>
                      )}
                      <p className={css.attr}>Member since {toHumanDate(user.createdAt, 'Do MMMM YYYY')}</p>
                    </div>
                    {!!me && me.id !== user.id && user.canBeAssignee && (
                      <Button className={css.offerButton} type="button" onClick={() => setJobInvationModalOpen(true)}>
                        Offer a task
                      </Button>
                    )}
                  </div>
                  <div className={css.rightCol}>
                    {isMeCanBlock && <BlockUser user={user} />}
                    {!!user.contacts && (
                      <Segment title="Contacts">
                        <UserContacts user={user} />
                      </Segment>
                    )}
                    {!!user.about && (
                      <Segment title="About">
                        <PrettyText className={css.about}>{user.about}</PrettyText>
                      </Segment>
                    )}
                    {!!user.skills && !!user.skills.length && (
                      <Segment title="Skills">
                        <div className={css.skills}>
                          {user.skills.map((skill, i) => (
                            <div key={i} className={css.skill}>
                              {skill}
                            </div>
                          ))}
                        </div>
                      </Segment>
                    )}
                    {!!user.portfolioImages && !!user.portfolioImages.length && (
                      <Segment title="Portfolio">
                        <Gallery images={user.portfolioImages} />
                      </Segment>
                    )}
                    {isMeCanBlock && (
                      <Segment title="Manage User">
                        <ProfileManagerEditor userId={user.id} />
                      </Segment>
                    )}
                    <Segment title="Reviews">
                      <Reviews userId={user.id} />
                    </Segment>
                  </div>
                </div>
              </div>
              <JobInvationModal
                {...{
                  isJobInvationModalOpen,
                  setJobInvationModalOpen,
                  userId: user.id,
                  userName: user.displayName,
                }}
              />
            </div>
          </>
        )
      }}
    </PageWrapper>
  )
}

export default UserPage
