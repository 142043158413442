import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Query from 'components/Query'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Checkbox from 'components/Checkbox'
import PushAccess from 'components/PushAccess'
import { track, setUserData } from 'utils/mixpanel'
import css from './styles.module.scss'
import _ from 'lodash'

const ProfileEmailNotifications = () => {
  const [updateNotifications] = useMutation(gql`
    mutation($input: UpdateNotificationsInput!) {
      updateNotifications(input: $input) {
        me {
          id
          acceptNotifications
        }
      }
    }
  `)

  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              acceptNotifications
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : (
          <>
            <PushAccess />
            <hr />
            <Form
              onSubmit={({ acceptNotifications }) => {
                return updateNotifications({
                  variables: {
                    input: {
                      acceptNotifications: _.uniq([
                        ...acceptNotifications,
                        'NOTIFY_PREFERENCE_MOBILEPUSH_STATUSES',
                        'NOTIFY_PREFERENCE_MOBILEPUSH_BIDS',
                        'NOTIFY_PREFERENCE_MOBILEPUSH_SUGGESTED_JOB',
                        'NOTIFY_PREFERENCE_MOBILEPUSH_MESSAGES',
                        'NOTIFY_PREFERENCE_MOBILEPUSH_COMMENTS',
                      ]),
                    },
                  },
                }).then(() => {
                  track('Update notification preferences', {
                    'Accepted notifications': acceptNotifications,
                  })
                  setUserData({
                    'Accepted notifications': acceptNotifications,
                  })
                })
              }}
              initialValues={{
                acceptNotifications: me.acceptNotifications,
              }}
              successMessage="Success"
            >
              {({ alertExists, alertProps, buttonProps }) => {
                return (
                  <>
                    <div className={css.items}>
                      <div className={css.item}>
                        <p className={css.info}>Notify about job statuses</p>
                        <div className={css.checkboxes}>
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_EMAIL_STATUSES"
                            label="Email"
                          />
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_PUSH_STATUSES"
                            label="Push"
                          />
                        </div>
                      </div>
                      <div className={css.item}>
                        <p className={css.info}>
                          Notify about new bids for my jobs
                        </p>
                        <div className={css.checkboxes}>
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_EMAIL_BIDS"
                            label="Email"
                          />
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_PUSH_BIDS"
                            label="Push"
                          />
                        </div>
                      </div>
                      <div className={css.item}>
                        <p className={css.info}>Notify about new jobs</p>
                        <div className={css.checkboxes}>
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_EMAIL_SUGGESTED_JOB"
                            label="Email"
                          />
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_PUSH_SUGGESTED_JOB"
                            label="Push"
                          />
                        </div>
                      </div>
                      <div className={css.item}>
                        <p className={css.info}>Notify about new messages</p>
                        <div className={css.checkboxes}>
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_EMAIL_MESSAGES"
                            label="Email"
                          />
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_PUSH_MESSAGES"
                            label="Push"
                          />
                        </div>
                      </div>
                      <div className={css.item}>
                        <p className={css.info}>Notify about new comments</p>
                        <div className={css.checkboxes}>
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_EMAIL_COMMENTS"
                            label="Email"
                          />
                          <Checkbox
                            className={css.checkbox}
                            name="acceptNotifications"
                            value="NOTIFY_PREFERENCE_PUSH_COMMENTS"
                            label="Push"
                          />
                        </div>
                      </div>
                    </div>

                    {alertExists && <Alert {...alertProps} />}
                    <div className="field is-grouped is-grouped-multiline">
                      <div className="control">
                        <Button {...buttonProps}>Save</Button>
                      </div>
                    </div>
                  </>
                )
              }}
            </Form>
          </>
        )
      }
    </Query>
  )
}

export default ProfileEmailNotifications
