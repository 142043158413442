import React from 'react'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import Wrapper from 'components/Wrapper'
import cn from 'classnames'
import css from './styles.module.scss'
import getUploadUrl from 'utils/getUploadUrl'
import Query from 'components/Query'
import Link from 'components/Link'
import Button from 'components/Button'
import {
  getServiceProviderFormRoute,
  getUserRoute,
  getMyBalanceRoute,
  getMyJobsRoute,
  getMyBidsRoute,
  getMyChatsRoute,
  getMyProfileRoute,
  getBidsRoute,
  getDashboardRoute,
  getCommentsRoute,
} from 'routes'
import { canBlockBid, canManageComment } from 'utils/can'

const DashboardLayout = ({ children, loading }) => {
  return (
    <Layout>
      <Wrapper nopadding>
        <div className={css.cols}>
          <Query preset="me" withoutLoader>
            {({ me }) =>
              !!me ? (
                <div className={css.left}>
                  <div className={css.avatarPlace}>
                    <img
                      alt=""
                      className={css.avatar}
                      src={getUploadUrl(me.avatar, 'avatar')}
                    />
                  </div>
                  <p className={css.name}>
                    <Link to={getUserRoute(me.id)}>
                      {me.name || me.email || 'Anonymous'}
                    </Link>
                  </p>
                  {!!me && !me.canBeAssignee && (
                    <div className={css.joinAsPro}>
                      <Button
                        fullWidth
                        className={css.buttonLink}
                        to={getServiceProviderFormRoute()}
                      >
                        Join as Pro
                      </Button>
                    </div>
                  )}
                  <div className={css.menu}>
                    <Link className={css.link} to={getDashboardRoute()}>
                      Dashboard
                    </Link>
                    <Link className={css.link} to={getMyBalanceRoute()}>
                      Balance: £ {me.balance.toFixed(2)}
                    </Link>
                    <Link
                      className={cn({
                        [css.link]: true,
                        [css.withGreenDot]: me.hasUnreadMessages,
                      })}
                      to={getMyChatsRoute()}
                    >
                      <span>Messages</span>
                    </Link>
                    <Link className={css.link} to={getMyJobsRoute('all')}>
                      Tasks
                    </Link>
                    <Link className={css.link} to={getMyBidsRoute('all')}>
                      Bids
                    </Link>
                    <Link className={css.link} to={getMyProfileRoute()}>
                      Profile Settings
                    </Link>
                    {canBlockBid(me) && (
                      <Link className={css.link} to={getBidsRoute()}>
                        Manage bids
                      </Link>
                    )}
                    {canManageComment(me) && (
                      <Link className={css.link} to={getCommentsRoute()}>
                        Manage comments
                      </Link>
                    )}
                  </div>
                </div>
              ) : null
            }
          </Query>
          <div className={css.right}>
            {loading ? <Loader type="page" /> : children}
          </div>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default DashboardLayout
