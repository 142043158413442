import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import { createValidator, required } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Segment from 'components/Segment'

const BlockUser = ({ user }) => {
  const [blockUser] = useMutation(gql`
    mutation($input: BlockUserInput!) {
      blockUser(input: $input) {
        user {
          id
          blocked
          blockComment
        }
      }
    }
  `)
  const [unblockUser] = useMutation(gql`
    mutation($input: UnblockUserInput!) {
      unblockUser(input: $input) {
        user {
          id
          blocked
          blockComment
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
      variables={{ input: {} }}
    >
      {({ me }) =>
        !me ? null : (
          <>
            {!user.blocked && (
              <Segment title="Block this user">
                <Form
                  validate={createValidator({
                    blockComment: [required],
                  })}
                  onSubmit={input =>
                    blockUser({
                      variables: {
                        input: {
                          blockComment: input.blockComment || null,
                          userId: user.id,
                        },
                      },
                    })
                  }
                  withPristine
                  resetOnSuccess
                  successMessage="Success"
                >
                  {({
                    alertExists,
                    alertProps,
                    buttonProps,
                    forgetSuccess,
                  }) => {
                    return (
                      <>
                        <Input label="Reason" name="blockComment" />
                        {alertExists && <Alert {...alertProps} />}
                        <div className="field is-grouped is-grouped-multiline">
                          <div className="control">
                            <Button {...buttonProps}>Block user</Button>
                          </div>
                        </div>
                      </>
                    )
                  }}
                </Form>
              </Segment>
            )}
            {user.blocked && (
              <Segment
                title="Unlock this user"
                desc={`Reason: ${user.blockComment}`}
              >
                <Form
                  onSubmit={() =>
                    unblockUser({
                      variables: {
                        input: {
                          userId: user.id,
                        },
                      },
                    })
                  }
                >
                  {({
                    alertExists,
                    alertProps,
                    buttonProps,
                    forgetSuccess,
                  }) => {
                    return (
                      <>
                        {alertExists && <Alert {...alertProps} />}
                        <div className="field is-grouped is-grouped-multiline">
                          <div className="control">
                            <Button {...buttonProps}>Unblock user</Button>
                          </div>
                        </div>
                      </>
                    )
                  }}
                </Form>
              </Segment>
            )}
          </>
        )
      }
    </Query>
  )
}

export default BlockUser
