import React from 'react'

import { ReactComponent as FacebookIcon } from 'assets/images/social-icons/facebook.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/social-icons/instagram.svg'
import { ReactComponent as GoogleIcon } from 'assets/images/social-icons/google.svg'
import { ReactComponent as AppleIcon } from 'assets/images/social-icons/apple.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/social-icons/youtube.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/social-icons/twitter.svg'
import { ReactComponent as FacebookMessagesIcon } from 'assets/images/social-icons/facebook-messages.svg'

const SocialIcon = ({ name, size = 48, ...restProps }) => {
  const style = { width: size, height: size }
  switch (name) {
    case 'facebook':
      return <FacebookIcon style={style} {...restProps} />
    case 'instagram':
      return <InstagramIcon style={style} {...restProps} />
    case 'google':
      return <GoogleIcon style={style} {...restProps} />
    case 'apple':
      return <AppleIcon style={style} {...restProps} />
    case 'youtube':
      return <YoutubeIcon style={style} {...restProps} />
    case 'twitter':
      return <TwitterIcon style={style} {...restProps} />
    case 'facebook-messages':
      return <FacebookMessagesIcon style={style} {...restProps} />
    default:
      return null
  }
}

export default SocialIcon
