import React from 'react'

import { ReactComponent as AppliancesInstallationRepairIcon } from 'assets/images/categories-icons/appliances-installation-repair.svg'
import { ReactComponent as BusinessIcon } from 'assets/images/categories-icons/business.svg'
import { ReactComponent as CourierServicesIcon } from 'assets/images/categories-icons/courier-services.svg'
import { ReactComponent as HomeImprovementsIcon } from 'assets/images/categories-icons/home-improvements.svg'
import { ReactComponent as HouseCleaningIcon } from 'assets/images/categories-icons/house-cleaning.svg'
import { ReactComponent as ItWebAndDesignIcon } from 'assets/images/categories-icons/it-web-and-design.svg'
import { ReactComponent as LegalIcon } from 'assets/images/categories-icons/legal.svg'
import { ReactComponent as LessonsLearningIcon } from 'assets/images/categories-icons/lessons-learning.svg'
import { ReactComponent as PetsIcon } from 'assets/images/categories-icons/pets.svg'
import { ReactComponent as RemovalsStorageIcon } from 'assets/images/categories-icons/removals-storage.svg'
import { ReactComponent as VirtualAssistantIcon } from 'assets/images/categories-icons/virtual-assistant.svg'
import { ReactComponent as WellnessIcon } from 'assets/images/categories-icons/wellness.svg'
import { ReactComponent as CrossIcon } from 'assets/images/other/cross-icon.svg'
import { ReactComponent as EventServicesIcon } from 'assets/images/categories-icons/event-services.svg'
import { ReactComponent as HealthBeautyIcon } from 'assets/images/categories-icons/health-beauty.svg'

const CategoryIcon = ({ name, ...restProps }) => {
  switch (name) {
    case 'appliances-installation-repair':
      return <AppliancesInstallationRepairIcon {...restProps} />
    case 'business':
      return <BusinessIcon {...restProps} />
    case 'courier-services':
      return <CourierServicesIcon {...restProps} />
    case 'home-improvements':
      return <HomeImprovementsIcon {...restProps} />
    case 'house-cleaning':
      return <HouseCleaningIcon {...restProps} />
    case 'it-web-and-design':
      return <ItWebAndDesignIcon {...restProps} />
    case 'legal':
      return <LegalIcon {...restProps} />
    case 'lessons-learning':
      return <LessonsLearningIcon {...restProps} />
    case 'pets':
      return <PetsIcon {...restProps} />
    case 'removals-storage':
      return <RemovalsStorageIcon {...restProps} />
    case 'virtual-assistant':
      return <VirtualAssistantIcon {...restProps} />
    case 'wellness':
      return <WellnessIcon {...restProps} />
    case 'event-services':
      return <EventServicesIcon {...restProps} />
    case 'health-beauty':
      return <HealthBeautyIcon {...restProps} />
    default:
      return <CrossIcon {...restProps} />
  }
}

export default CategoryIcon
