import React from 'react'
import Form from 'components/Form'
import Input from 'components/Input'
import { createValidator, required, confirmPassword } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { track } from 'utils/mixpanel'

const NewPassword = () => {
  const [changeMyPassword] = useMutation(gql`
    mutation($input: ChangeMyPasswordInput!) {
      changeMyPassword(input: $input) {
        me {
          id
        }
      }
    }
  `)
  return (
    <Form
      validate={createValidator({
        currentPassword: [required],
        newPassword: [required],
        confirmPassword: [
          required,
          confirmPassword({ passwordField: 'newPassword' }),
        ],
      })}
      onSubmit={({ currentPassword, newPassword }) =>
        changeMyPassword({
          variables: {
            input: { currentPassword, newPassword },
          },
        }).then(() => {
          track('Change password')
        })
      }
      resetOnSuccess
      successMessage="Password changed successfully"
    >
      {({ alertExists, alertProps, buttonProps }) => {
        return (
          <>
            <Input
              label="Current password"
              name="currentPassword"
              type="password"
            />
            <Input label="New password" name="newPassword" type="password" />
            <Input
              label="Repeat new password"
              name="confirmPassword"
              type="password"
            />
            {alertExists && <Alert {...alertProps} />}
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <Button {...buttonProps}>Change Password</Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default NewPassword
