import React, { useState } from 'react'
import css from './styles.module.scss'
import Carousel, { Modal, ModalGateway } from 'react-images'
import getUploadUrl from 'utils/getUploadUrl'

const Gallery = ({ images, title, previewPreset = 'photoPreview', fullPreset = 'photo' }) => {
  const [lightBoxVisible, setLightboxVisibility] = useState(false)
  const [lightBoxIndex, setLightboxIndex] = useState(0)
  return (
    <div className={css.images}>
      {images.map((image, i) => (
        <a
          href="#null"
          key={i}
          className={css.imagePlace}
          onClick={e => {
            e.preventDefault()
            setLightboxIndex(i)
            setLightboxVisibility(true)
          }}
        >
          <img className={css.image} alt={title} title={title} src={getUploadUrl(image, previewPreset)} />
        </a>
      ))}
      <ModalGateway>
        {lightBoxVisible && (
          <Modal onClose={() => setLightboxVisibility(false)}>
            <Carousel
              currentIndex={lightBoxIndex}
              views={images.map(fullPreset => ({
                src: getUploadUrl(fullPreset, 'photo'),
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  )
}

export default Gallery
