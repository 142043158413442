import React, { useRef, useState, useEffect } from 'react'
import { createValidator, minSum, required, integerPositive } from 'lib/validation'
import Input from 'components/Input'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import Query from 'components/Query'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import Checkbox from 'components/Checkbox'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Elements, StripeProvider, CardElement, injectStripe } from 'react-stripe-elements'
import { track, trackCharge, setUserData } from 'utils/mixpanel'
import css from './styles.module.scss'
import cn from 'classnames'
import Hit from 'components/Hit'
import Segment from 'components/Segment'
import Modal from 'components/Modal'

const CreditForm = ({ stripe, me }) => {
  const [activePreset, setActivePreset] = useState('4')
  const [isPayPalSuccessModalOpened, setPayPalSuccessModalOpened] = useState(false)
  const [isPayPalErrorModalOpened, setPayPalErrorModalOpened] = useState(false)
  const [paypalError, setPaypalError] = useState('')
  let cardElement = useRef(null)
  const amountEl = useRef()
  useEffect(() => {
    // console.log(amountEl.current)
    amountEl.current.focus()
  }, [])
  const isPayPalAvailable = !!window.paypal
  useEffect(() => {
    if (isPayPalAvailable) {
      window.paypal.Buttons().render('#paypal-buttons-place')
    }
  }, [isPayPalAvailable])
  const [stripeConfirmPayment] = useMutation(gql`
    mutation($input: StripeConfirmPaymentInput!) {
      stripeConfirmPayment(input: $input) {
        requires_action
        payment_intent_client_secret
        success
        error
        me {
          id
          balance
          stripeCards {
            paymentMethodId
            last4
            expMonth
            expYear
            brand
          }
        }
      }
    }
  `)
  const [removeStripePaymentMethod] = useMutation(gql`
    mutation($input: RemoveStripePaymentMethodInput!) {
      removeStripePaymentMethod(input: $input) {
        me {
          id
          stripeCards {
            paymentMethodId
            last4
            expMonth
            expYear
            brand
          }
        }
      }
    }
  `)
  return (
    <Form
      validate={createValidator({
        sum: [required, minSum(5), integerPositive],
      })}
      onSubmit={async formInput => {
        // Send paymentMethod.id to your server (see Step 2)
        let response
        if (!formInput.paymentMethodId) {
          const { paymentMethod, error } = await stripe.createPaymentMethod(
            'card'
            // ,{
            //   billing_details: { name: cardholderName.value },
            // }
          )
          if (error) throw error
          response = await stripeConfirmPayment({
            variables: {
              input: {
                payment_method_id: paymentMethod.id,
                amount: parseFloat(formInput.sum),
                saveCard: !!formInput.saveCard,
              },
            },
            refetchQueries: ['MyTxs'],
          })
        } else {
          response = await stripeConfirmPayment({
            variables: {
              input: {
                payment_method_id: formInput.paymentMethodId,
                amount: parseFloat(formInput.sum),
              },
            },
            refetchQueries: ['MyTxs'],
          })
        }

        const handleServerResponse = async response => {
          if (response.requires_action) {
            // Use Stripe.js to handle the required card action
            const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
              response.payment_intent_client_secret
            )

            if (errorAction) throw errorAction
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            response = await stripeConfirmPayment({
              variables: {
                input: {
                  payment_intent_id: paymentIntent.id,
                  saveCard: !!formInput.saveCard,
                },
              },
              refetchQueries: ['MyTxs'],
            })
            await handleServerResponse(response.data.stripeConfirmPayment)
          } else {
            // Show success message
            trackCharge(parseFloat(formInput.sum))
            track('Transaction', {
              Amount: parseFloat(formInput.sum),
              'With card saving': !!formInput.saveCard,
              'By saved card': !!formInput.paymentMethodId,
            })
            setUserData({
              'Stripe cards count': response.me.stripeCards.length,
            })
            cardElement.current.clear()
          }
        }

        await handleServerResponse(response.data.stripeConfirmPayment)
      }}
      resetOnSuccess
      successMessage="Success"
    >
      {({ alertExists, alertProps, buttonProps, values, form }) => {
        return (
          <>
            <Hit
              args={[values.sum]}
              action={() => {
                if (values.sum) {
                  window.document.getElementById('paypal-buttons-place').innerHTML = ''
                  window.paypal
                    .Buttons({
                      createOrder: function(data, actions) {
                        // This function sets up the details of the transaction, including the amount and line item details.
                        return actions.order.create({
                          purchase_units: [
                            {
                              description: `£ ${values.sum} user balance replenishment ${me.id}`,
                              amount: {
                                value: values.sum,
                                currency_code: 'GBP',
                              },
                              metadata: {
                                id: me.id,
                              },
                            },
                          ],
                        })
                      },
                      onApprove: function(data, actions) {
                        // This function captures the funds from the transaction.
                        return actions.order.capture().then(function(details) {
                          // This function shows a transaction success message to your buyer.
                          // alert('Transaction completed by ' + details.payer.name.given_name)
                          setPayPalSuccessModalOpened(true)
                        })
                      },
                      onError: function(err) {
                        setPaypalError(err.message)
                      },
                    })
                    .render('#paypal-buttons-place')
                }
              }}
            />
            <div className="field">
              <label className="label" htmlFor="sum">
                Amount
              </label>
              <div className="control">
                <RadioButtons name="presetSum">
                  <RadioButton
                    value="0"
                    name="presetSum"
                    label="£ 5"
                    className={activePreset === '0' ? cn(css.presetSum, css.activePreset) : css.presetSum}
                    onClick={() => {
                      form.mutators.setFieldValue('sum', '5')
                      setActivePreset('0')
                    }}
                  />
                  <RadioButton
                    value="1"
                    name="presetSum"
                    label="£ 10"
                    className={activePreset === '1' ? cn(css.presetSum, css.activePreset) : css.presetSum}
                    onClick={() => {
                      form.mutators.setFieldValue('sum', '10')
                      setActivePreset('1')
                    }}
                  />
                  <RadioButton
                    value="2"
                    name="presetSum"
                    label="£ 20"
                    className={activePreset === '2' ? cn(css.presetSum, css.activePreset) : css.presetSum}
                    onClick={() => {
                      form.mutators.setFieldValue('sum', '20')
                      setActivePreset('2')
                    }}
                  />
                  <RadioButton
                    value="3"
                    name="presetSum"
                    label="£ 30"
                    className={activePreset === '3' ? cn(css.presetSum, css.activePreset) : css.presetSum}
                    onClick={() => {
                      form.mutators.setFieldValue('sum', '30')
                      setActivePreset('3')
                    }}
                  />
                </RadioButtons>
                <Input
                  ref={el => {
                    amountEl.current = el
                  }}
                  name="sum"
                  type="number"
                  validateOnTouch
                  onFocus={() => setActivePreset('4')}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Pay with PayPal</label>
              <div
                className="control"
                style={{
                  maxWidth: 300,
                  pointerEvents: values.sum && parseInt(values.sum) >= 5 ? 'auto' : 'none',
                  opacity: values.sum && parseInt(values.sum) >= 5 ? 1 : 0.5,
                }}
                id={'paypal-buttons-place'}
              />
            </div>
            <Modal isOpen={isPayPalSuccessModalOpened} onRequestClose={() => setPayPalSuccessModalOpened(false)}>
              <Segment title="Successful Transaction">
                <p>£ {values.sum} will be added to your account balance in next 3 minutes.</p>
              </Segment>
            </Modal>
            <Modal isOpen={isPayPalErrorModalOpened} onRequestClose={() => setPayPalErrorModalOpened(false)}>
              <Segment title="Failed Transaction">
                <p>{paypalError}</p>
              </Segment>
            </Modal>
            <div className="field">
              <label className="label">Or enter card info</label>
              <div className="control">
                <div className={css.cardInfo}>
                  <CardElement
                    onFocus={() => form.mutators.setFieldValue('paymentMethodId', '')}
                    onReady={el => (cardElement.current = el)}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <Checkbox name="saveCard" label="Save card" />
              </div>
            </div>
            {!!me.stripeCards.length && (
              <RadioButtons name="paymentMethodId" label="Or choose existing card">
                {me.stripeCards.map((card, i) => (
                  <span key={i}>
                    <RadioButton
                      value={card.paymentMethodId}
                      name="paymentMethodId"
                      label={` ... ${card.last4} (${{
                        visa: 'Visa',
                        mastercard: 'Mastercard',
                        'american-express': 'American Express',
                        'diners-club': 'Diners Club',
                        discover: 'Discover',
                        jcb: 'JCB',
                        unionpay: 'UnionPay',
                        maestro: 'Maestro',
                        mir: 'Mir',
                        elo: 'Elo',
                        hiper: 'Hiper',
                        hipercard: 'Hipercard',
                      }[card.brand] || card.brand})`}
                    />{' '}
                    <a
                      href="#null"
                      onClick={e => {
                        e.preventDefault()
                        removeStripePaymentMethod({
                          variables: {
                            input: {
                              paymentMethodId: card.paymentMethodId,
                            },
                          },
                        }).then(({ data }) => {
                          track('Delete stripe card')
                          setUserData({
                            'Stripe cards count': data.removeStripePaymentMethod.me.stripeCards.length,
                          })
                        })
                      }}
                    >
                      Delete
                    </a>
                    <br />
                  </span>
                ))}
              </RadioButtons>
            )}
            {alertExists && <Alert {...alertProps} />}
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <Button {...buttonProps}>
                  {!values.sum
                    ? `Pay with card`
                    : !values.paymentMethodId && values.saveCard
                    ? `Pay with card £ ${values.sum} & Save Card`
                    : `Pay with card £ ${values.sum}`}
                </Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

const CreditFormWrapper = props => {
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              stripeCards {
                paymentMethodId
                last4
                expMonth
                expYear
                brand
              }
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) => <CreditForm {...props} me={me} />}
    </Query>
  )
}

const CreditFormInjected = injectStripe(CreditFormWrapper)

export default () => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
    <Elements>
      <CreditFormInjected />
    </Elements>
  </StripeProvider>
)
