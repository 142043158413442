import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import { createValidator, required, email } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import { track } from 'utils/mixpanel'

const ChangeEmail = () => {
  const [requestEmailChanging] = useMutation(gql`
    mutation($input: RequestEmailChangingInput!) {
      requestEmailChanging(input: $input) {
        me {
          id
          email
          newEmail
          emailConfirmed
        }
      }
    }
  `)
  const [cancelEmailChanging, cancelEmailChangingResult] = useMutation(gql`
    mutation {
      cancelEmailChanging {
        me {
          id
          email
          newEmail
          emailConfirmed
        }
      }
    }
  `)
  const [
    resendEmailConfirmationSuccess,
    setResendEmailConfirmationSuccess,
  ] = useState(false)
  const [
    resendEmailConfirmation,
    resendEmailConfirmationResult,
  ] = useMutation(gql`
    mutation {
      resendEmailConfirmation {
        me {
          id
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              email
              newEmail
              emailConfirmed
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : (
          <Form
            validate={createValidator({
              email: [required, email],
            })}
            onSubmit={input => {
              setResendEmailConfirmationSuccess(false)
              return requestEmailChanging({
                variables: {
                  input: {
                    newEmail: input.email || null,
                  },
                },
              }).then(() => {
                track('Request email changing', { Email: input.email })
              })
            }}
            withPristine
            initialValues={{
              email: me.email,
            }}
            resetOnSuccess
            successMessage="Email address changed successfully. Now you should confirm it."
          >
            {({ alertExists, alertProps, buttonProps, forgetSuccess }) => {
              return (
                <>
                  {!me.newEmail && !me.emailConfirmed && (
                    <Alert
                      message={`Confirm your address. Open the link we sent you in an email.`}
                    />
                  )}
                  {!!me.newEmail && (
                    <Alert
                      message={`Confirm your new address "${me.newEmail}". Open the link we sent you in an email.`}
                    />
                  )}
                  <Input label="Current e-mail address" name="email" />
                  {alertExists && <Alert {...alertProps} />}
                  {resendEmailConfirmationSuccess && (
                    <Alert
                      type="success"
                      message="Confirmation e-mail was resended"
                    />
                  )}
                  <div className="field">
                    <Buttons>
                      <Button {...buttonProps}>Change e-mail</Button>
                      {!!me.newEmail && (
                        <Button
                          type="button"
                          onClick={e => {
                            e.preventDefault()
                            forgetSuccess()
                            setResendEmailConfirmationSuccess(false)
                            cancelEmailChanging()
                          }}
                          loading={cancelEmailChangingResult.loading}
                        >
                          Cancel e-mail changing
                        </Button>
                      )}
                      {(me.newEmail || !me.emailConfirmed) && (
                        <Button
                          type="button"
                          onClick={e => {
                            e.preventDefault()
                            forgetSuccess()
                            resendEmailConfirmation().then(() => {
                              track('Request resend email confirmation')
                              setResendEmailConfirmationSuccess(true)
                            })
                          }}
                          loading={resendEmailConfirmationResult.loading}
                        >
                          Resend confirmation e-mail
                        </Button>
                      )}
                    </Buttons>
                  </div>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default ChangeEmail
