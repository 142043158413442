import React from 'react'
import { getSignUpRoute, getRestorePasswordRoute, getHomeRoute } from 'routes'
import Form from 'components/Form'
import Input from 'components/Input'
import SocialButtons from 'components/SocialButtons'
import PageWrapper from 'components/PageWrapper'
import { createValidator, required, email } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import Segment from 'components/Segment'
import cookieStore from 'lib/cookieStore'
import gql from 'graphql-tag'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import get from 'lodash/get'
import { identifyUser, track } from 'utils/mixpanel'

const SignInPage = ({ history }) => {
  const apolloClient = useApolloClient()
  const [signIn] = useMutation(gql`
    mutation($input: SignInInput!) {
      signIn(input: $input) {
        token
        user {
          id
          email
        }
      }
    }
  `)
  return (
    <PageWrapper redirectAuthorized>
      <PageSegment title="Log In">
        <Segment>
          <Form
            validate={createValidator({
              email: [required, email],
              password: [required],
            })}
            onSubmit={input => signIn({ variables: { input } })}
            onSuccess={data => {
              const token = get(data, 'signIn.token', '')
              const user = get(data, 'signIn.user', {})
              identifyUser(user.id)
              track('Sign in', { Method: 'email' })
              if (token) {
                cookieStore.set('token', token)
                apolloClient
                  .resetStore()
                  .then(() => history.push(getHomeRoute()))
              }
            }}
            hideSuccessAlert
          >
            {({ alertExists, alertProps, buttonProps }) => {
              return (
                <>
                  <Input label="E-mail" name="email" type="email" />
                  <Input label="Password" name="password" type="password" />
                  {alertExists && <Alert {...alertProps} />}
                  <Buttons>
                    <Button {...buttonProps}>Log In</Button>
                    <Button outline to={getSignUpRoute()}>
                      Sign Up
                    </Button>
                    <Button outline to={getRestorePasswordRoute()}>
                      Forgot password?
                    </Button>
                  </Buttons>
                </>
              )
            }}
          </Form>
        </Segment>
        <Segment title="Or use social network">
          <SocialButtons />
        </Segment>
      </PageSegment>
    </PageWrapper>
  )
}

export default SignInPage
