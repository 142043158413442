import React from 'react'
import Form from 'components/Form'
import Alert from 'components/Alert'
import Hit from 'components/Hit'
import gql from 'graphql-tag'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { track, setUserData } from 'utils/mixpanel'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import cookieStore from 'lib/cookieStore'
import { getDashboardRoute } from 'routes'
import useGlobalState from 'lib/globalState'

const ConfirmEmailPage = ({ match, isNewEmail, history }) => {
  const apolloClient = useApolloClient()
  const dispatchGlobalAction = useGlobalState()[1]
  const [confirmEmail] = useMutation(
    isNewEmail
      ? gql`
          mutation($input: ConfirmNewEmailInput!) {
            confirmNewEmail(input: $input) {
              user {
                id
                email
                emailConfirmed
              }
              token
            }
          }
        `
      : gql`
          mutation($input: ConfirmEmailInput!) {
            confirmEmail(input: $input) {
              user {
                id
                email
                emailConfirmed
              }
              token
            }
          }
        `
  )
  return (
    <PageWrapper>
      <PageSegment title="Email Confirmation">
        <Form
          onSubmit={input =>
            confirmEmail({
              variables: {
                input: {
                  [isNewEmail
                    ? 'confirmNewEmailToken'
                    : 'confirmEmailToken']: input.confirmEmailToken,
                },
              },
            }).then(({ data }) => {
              track(isNewEmail ? 'Confirm new email' : 'Confirm email')
              const user =
                data[isNewEmail ? 'confirmNewEmail' : 'confirmEmail'].user
              if (user) {
                setUserData({
                  'User Id': user.id,
                  $email: user.email,
                  'Email confirmed': user.emailConfirmed,
                })
              }
              const token =
                data[isNewEmail ? 'confirmNewEmail' : 'confirmEmail'].token
              if (token) {
                dispatchGlobalAction({
                  type: 'showSuccessEmailConfirmationModal',
                })
                cookieStore.set('token', token)
                apolloClient.resetStore().then(() => {
                  history.push(getDashboardRoute())
                })
              }
            })
          }
          initialValues={{
            confirmEmailToken: match.params.confirmEmailToken,
          }}
          successMessage="Your email successfully confirmed"
        >
          {({ alertExists, alertProps, handleSubmit }) => {
            return (
              <>
                <Hit action={handleSubmit} />
                {alertExists && <Alert {...alertProps} />}
              </>
            )
          }}
        </Form>
      </PageSegment>
    </PageWrapper>
  )
}

export default ConfirmEmailPage
