import React, { useState, useRef, useEffect } from 'react'
import { default as Link } from 'components/MenuLink'
import Query from 'components/Query'
import Button from 'components/Button'
import getUploadUrl from 'utils/getUploadUrl'
import truncate from 'utils/truncate'
import {
  getHomeRoute,
  getJobsRoute,
  getSignUpRoute,
  getSignInRoute,
  getMyProfileRoute,
  getMyJobsRoute,
  getMyBidsRoute,
  getJobNewRoute,
  getMyBalanceRoute,
  getOwnerLandingRoute,
  getAssigneeLanding1Route,
  getServiceProviderFormRoute,
  // getUsersRoute,
  getBidsRoute,
  getCommentsRoute,
  getMyChatsRoute,
  getUserRoute,
  getDashboardRoute,
} from 'routes'
import cn from 'classnames'
import cookieStore from 'lib/cookieStore'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import css from './styles.module.scss'
import logoWhite from 'assets/images/logo/logo-h-w.png'
import { ReactComponent as RightArrowIcon } from 'assets/images/other/arrow-right.svg'
import { ReactComponent as LogoutIcon } from 'assets/images/other/logout-icon.svg'
import { ReactComponent as HamburgerIcon } from 'assets/images/other/hamburger-icon.svg'
import { canBlockBid, canManageComment } from 'utils/can'
import gql from 'graphql-tag'
import get from 'lodash/get'

const Header = () => {
  const apolloClient = useApolloClient()
  const [isHamburgerMenuActive, setHamburgerMenuActive] = useState(false)
  const [isProfileMenuActive, setProfileMenuActive] = useState(false)
  const profileMenuRef = useRef(null)
  const handleClickOutside = event => {
    if (profileMenuRef && profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
      setProfileMenuActive(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const { data } = useQuery(
    gql`
      query {
        getMe {
          me {
            id
            hasUnreadMessages
          }
        }
      }
    `,
    {
      pollInterval: 5000,
    }
  )
  const hasUnreadMessages = get(data, 'getMe.me.hasUnreadMessages')
  return (
    <div className={cn(css.header)}>
      <div className={cn(css.section, css.topLine)}>
        <div className={css.wrapper}>
          <Query preset="me">
            {({ me }) => {
              return (
                <Link className={css.logoPlace} to={me ? getDashboardRoute() : getHomeRoute()}>
                  <img width={131} height={34} src={logoWhite} className={css.logo} alt="" />
                </Link>
              )
            }}
          </Query>
          <div className={css.desktopLinks}>
            <Button className={css.buttonLink} to={getJobNewRoute()}>
              Post a Task
            </Button>
            <Query preset="me" withoutLoader>
              {({ me }) =>
                !me ? (
                  <>
                    <Link className={css.link} to={getAssigneeLanding1Route()}>
                      For Service Providers
                    </Link>
                    <Link className={css.link} to={getOwnerLandingRoute()}>
                      For Customers
                    </Link>
                  </>
                ) : null
              }
            </Query>
            <Link className={css.link} to={getJobsRoute()}>
              Find a Job
            </Link>
            {/*}<Link className={css.link} to={getUsersRoute()}>
              Service Providers
            </Link>*/}
          </div>
          <div className={css.rightCorner}>
            <Button
              href="#"
              color="white"
              square
              outline
              className={cn({
                [css.mobileMenuButton]: true,
                [css.withGreenDot]: hasUnreadMessages,
              })}
              onClick={e => {
                e.preventDefault()
                setHamburgerMenuActive(!isHamburgerMenuActive)
              }}
            >
              <HamburgerIcon />
            </Button>
            <Query preset="me" withoutLoader>
              {({ me }) =>
                me ? (
                  <div
                    className={cn({
                      [css.profileMenu]: true,
                      [css.hasUnreadMessages]: hasUnreadMessages,
                    })}
                    ref={profileMenuRef}
                  >
                    <div className={css.profileMenuButton} onClick={() => setProfileMenuActive(!isProfileMenuActive)}>
                      <img alt="" className={css.avatar} src={getUploadUrl(me.avatar, 'avatar')} />
                      <span className={css.name}>{truncate(me.name || me.email || 'Anonymous', 30)}</span>
                      <RightArrowIcon className={css.arrow} />
                    </div>
                    <div
                      className={cn({
                        [css.profileMenuContent]: true,
                        [css.active]: isProfileMenuActive,
                      })}
                    >
                      <div className={css.links}>
                        <Link className={css.link} to={getDashboardRoute()}>
                          Dashboard
                        </Link>
                        <Link className={css.link} to={getUserRoute(me.id)}>
                          Public Profile
                        </Link>
                        <Link className={css.link} to={getMyBalanceRoute()}>
                          Balance: £ {me.balance.toFixed(2)}
                        </Link>
                        <Link
                          className={cn({
                            [css.link]: true,
                            [css.withGreenDot]: hasUnreadMessages,
                          })}
                          to={getMyChatsRoute()}
                        >
                          <span>Messages</span>
                        </Link>
                        <Link className={css.link} to={getMyJobsRoute('all')}>
                          Tasks
                        </Link>
                        <Link className={css.link} to={getMyBidsRoute('all')}>
                          Bids
                        </Link>
                        <Link className={css.link} to={getMyProfileRoute()}>
                          Profile Settings
                        </Link>
                        {canBlockBid(me) && (
                          <Link className={css.link} to={getBidsRoute()}>
                            Manage bids
                          </Link>
                        )}
                        {canManageComment(me) && (
                          <Link className={css.link} to={getCommentsRoute()}>
                            Manage comments
                          </Link>
                        )}
                        {!!me && !me.canBeAssignee && (
                          <div className={css.buttonLinkPlace}>
                            <Button className={css.buttonLink} to={getServiceProviderFormRoute()}>
                              Join as Pro
                            </Button>
                          </div>
                        )}
                      </div>
                      <a
                        href="#logout"
                        className={css.logout}
                        onClick={e => {
                          e.preventDefault()
                          process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom('shutdown')
                          cookieStore.remove('token')
                          apolloClient.resetStore()
                        }}
                      >
                        <LogoutIcon className={css.icon} />
                        Log out
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className={css.signButtons}>
                    <Button color="white" outline className={css.button} to={getSignUpRoute()}>
                      Sign Up
                    </Button>
                    <Button color="white" outline className={css.button} to={getSignInRoute()}>
                      Log In
                    </Button>
                  </div>
                )
              }
            </Query>
          </div>
        </div>
      </div>
      <div
        className={cn({
          [css.section]: true,
          [css.mobileMenuContent]: true,
          [css.active]: isHamburgerMenuActive,
        })}
      >
        <div className={css.wrapper}>
          <Query preset="me" withoutLoader>
            {({ me }) => (
              <div className={css.links}>
                <div className={css.item}>
                  <Button className={css.buttonLink} to={getJobNewRoute()}>
                    Post a Task
                  </Button>
                </div>
                {!me && (
                  <>
                    <div className={css.item}>
                      <Link className={css.link} to={getAssigneeLanding1Route()}>
                        For Service Providers
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getOwnerLandingRoute()}>
                        For Customers
                      </Link>
                    </div>
                  </>
                )}
                <div className={css.item}>
                  <Link className={css.link} to={getJobsRoute()}>
                    Find a Job
                  </Link>
                </div>
                {/*}<div className={css.item}>
                  <Link className={css.link} to={getUsersRoute()}>
                    Service Providers
                  </Link>
                </div>*/}
                {!!me ? (
                  <>
                    <div className={css.item}>
                      <Link className={css.link} to={getDashboardRoute()}>
                        Dashboard
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getUserRoute(me.id)}>
                        Public profile
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getMyBalanceRoute()}>
                        Balance: £ {me.balance.toFixed(2)}
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={cn({
                          [css.link]: true,
                          [css.withGreenDot]: hasUnreadMessages,
                        })}
                        to={getMyChatsRoute()}
                      >
                        Messages
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getMyJobsRoute('all')}>
                        Tasks
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getMyBidsRoute('all')}>
                        Bids
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} to={getMyProfileRoute()}>
                        Profile Settings
                      </Link>
                    </div>
                    {canBlockBid(me) && (
                      <div className={css.item}>
                        <Link className={css.link} to={getBidsRoute()}>
                          Manage bids
                        </Link>
                      </div>
                    )}
                    {canManageComment(me) && (
                      <div className={css.item}>
                        <Link className={css.link} to={getCommentsRoute()}>
                          Manage comments
                        </Link>
                      </div>
                    )}
                    {!!me && !me.canBeAssignee && (
                      <div className={css.item}>
                        <Button className={css.buttonLink} to={getServiceProviderFormRoute()}>
                          Join as Pro
                        </Button>
                      </div>
                    )}
                    <div className={css.item}>
                      <Button
                        href="#"
                        color="white"
                        outline
                        className={css.button}
                        onClick={e => {
                          e.preventDefault()
                          cookieStore.remove('token')
                          process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom('shutdown')
                          apolloClient.resetStore()
                        }}
                      >
                        Log out
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={css.item}>
                      <Button color="white" outline className={css.button} to={getSignUpRoute()}>
                        Sign Up
                      </Button>
                    </div>
                    <div className={css.item}>
                      <Button color="white" outline className={css.button} to={getSignInRoute()}>
                        Log In
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </Query>
        </div>
      </div>
    </div>
  )
}

export default Header
