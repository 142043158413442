import React, { useState } from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import Title from 'components/Title'
import Link from 'components/Link'

const Segment = ({
  title,
  titleTag = 'h2',
  titleSize = 2,
  titleAlign = 'left',
  titleType = 'longLine',
  children,
  className,
  success,
  collapsable = false,
  initCollapsed = true,
  ...restProps
}) => {
  const [collpased, setCollapsed] = useState(initCollapsed)
  return (
    <div
      className={cn({
        [css.segment]: true,
        [css.collapsable]: !!collapsable,
        [css.collpased]: !!collpased,
        [className]: !!className,
      })}
      {...restProps}
    >
      {!!title && (
        <Title
          className={css.title}
          tag={titleTag}
          size={titleSize}
          align={titleAlign}
          type={collapsable && collpased ? 'noLine' : titleType}
          success={success}
          {...restProps}
        >
          {collapsable ? (
            <Link
              onClick={e => {
                e.preventDefault()
                setCollapsed(!collpased)
              }}
              type="dashed"
            >
              {title}
            </Link>
          ) : (
            title
          )}
        </Title>
      )}
      {!!children && (!collapsable || !collpased) && (
        <div className={css.content}>{children}</div>
      )}
    </div>
  )
}

export default Segment
