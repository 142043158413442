import React, { useEffect, useState } from 'react'
import { getJobRoute } from 'routes'
import PageWrapper from 'components/PageWrapper'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import Wrapper from 'components/Wrapper'
import LocationInput from 'components/LocationInput'
import PageSegment from 'components/PageSegment'
import Select from 'components/Select'
import Textarea from 'components/Textarea'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import UploadMultiple from 'components/UploadMultiple'
import Paper from 'components/Paper'
import css from './styles.module.scss'
import { createValidator, required, integerPositive, email, minLength } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import DatePicker from 'components/DatePicker'
import gql from 'graphql-tag'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import find from 'lodash/find'
import { track, fragments as mixpanelFragments } from 'utils/mixpanel'
import cookieStore from 'lib/cookieStore'
import useGlobalState from 'lib/globalState'
import getCategorySpecificValues from 'utils/getCategorySpecificValues'
import parseQueryString from 'utils/parseQueryString'

const JobNewPage = ({ history, location, categoryGroups, me, job }) => {
  const [isCategoriesSelectsVisible, setCategoriesSelectsVisibity] = useState(false)
  const dispatchGlobalAction = useGlobalState()[1]
  const apolloClient = useApolloClient()
  const [createJob] = useMutation(gql`
    mutation($input: CreateJobInput!) {
      createJob(input: $input) {
        job {
          id
          translitTitle
          ...Mixpanel_job
        }
        token
        user {
          isBusiness
        }
      }
    }
    ${mixpanelFragments.job}
  `)

  const initCategoryGroupAlias = parseQueryString(location.search).categoryGroupAlias
  const initCategoryGroup = find(categoryGroups, categoryGroup => categoryGroup.alias === initCategoryGroupAlias)
  const initCategoryGroupId = initCategoryGroup ? initCategoryGroup.id : ''
  const initCategoryAlias = parseQueryString(location.search).categoryAlias
  const initCategory = !initCategoryGroupId
    ? null
    : find(initCategoryGroup.categories, category => category.alias === initCategoryAlias)
  const initCategoryId = initCategory ? initCategory.id : ''
  const initialValues = job
    ? {
        title: job.title,
        text: job.text,
        photos: job.photos,
        city: job.lat ? job.city : null,
        postalCode: job.lat ? job.postalCode : null,
        address: job.lat ? job.address : null,
        lat: job.lat,
        lng: job.lng,
        remote: job.remote + '',
        categoryId: job.category && job.category.id,
        categoryGroupId: job.category && job.category.categoryGroup.id,
        price: job.averagePrice ? '' + job.averagePrice : job.maxPrice ? '' + job.maxPrice : '',
        // priceType: job.averagePrice
        //   ? 'average'
        //   : job.maxPrice
        //   ? 'max'
        //   : 'contract',
        isBusiness: me && me.isBusiness === true ? 'true' : 'false',
      }
    : {
        // priceType: 'max',
        remote: 'false',
        categoryGroupId: initCategoryId && initCategoryGroupId,
        categoryId: initCategoryGroupId && initCategoryId,
        isBusiness: me && me.isBusiness === true ? 'true' : 'false',
      }
  useEffect(() => {
    if (initialValues.categoryId && initialValues.categoryGroupId) {
      setCategoriesSelectsVisibity(true)
    }
  }, [initialValues.categoryId, initialValues.categoryGroupId])
  return (
    <Form
      validate={createValidator({
        title: [required],
        text: [required, minLength(100)],
        address: [(value, data) => data.remote === 'false' && required(value)],
        // categoryId: [required],
        // categoryGroupId: [required],
        // priceType: [required],
        // price: [
        //   (value, data) =>
        //     data['priceType'] !== 'contract' && required(value),
        //   integerPositive,
        // ],
        price: [required, integerPositive],
        ownerName: [value => (!me || !me.name) && required(value)],
        ownerEmail: [value => (!me || !me.email) && required(value), email],
      })}
      onSubmit={formInput => {
        const mutationInput = {
          title: formInput.title,
          categoryId: formInput.categoryId || null,
          city: formInput.remote === 'true' ? null : formInput.city,
          postalCode: formInput.remote === 'true' ? null : formInput.postalCode,
          address: formInput.remote === 'true' ? null : formInput.address,
          lat: formInput.remote === 'true' ? null : formInput.lat,
          lng: formInput.remote === 'true' ? null : formInput.lng,
          remote: formInput.remote === 'true',
          // maxPrice:
          //   formInput.priceType === 'max' ? +formInput.price : null,
          // averagePrice:
          //   formInput.priceType === 'average'
          //     ? +formInput.price
          //     : null,
          averagePrice: +formInput.price,
          text: formInput.text,
          photos: formInput.photos,
          ownerEmail: formInput.ownerEmail,
          ownerName: formInput.ownerName,
          isBusiness: formInput.isBusiness === 'true',
          deadline: formInput.deadline,
        }
        return createJob({
          variables: { input: mutationInput },
        })
      }}
      onSuccess={data => {
        track('Create job', {
          job: data.createJob.job,
          'Is new user': !me,
          'Is business account': data.createJob.user.isBusiness,
        })
        const token = data.createJob.token
        if (token) {
          dispatchGlobalAction({
            type: 'showAfterSignUpModal',
          })
          cookieStore.set('token', token)
          apolloClient
            .resetStore()
            .then(() => history.push(getJobRoute(data.createJob.job.id, data.createJob.job.translitTitle)))
        } else {
          history.push(getJobRoute(data.createJob.job.id, data.createJob.job.translitTitle))
        }
      }}
      initialValues={initialValues}
      hideSuccessAlert
      showValidationAlert
    >
      {({ alertExists, alertProps, buttonProps, values, form }) => {
        const {
          stepsTitle,
          stepsDesc,
          firstStep,
          secondStep,
          thirdStep,
          titleDesc,
          // textDesc,
          remoteAvailable,
        } = getCategorySpecificValues(values.categoryGroupId, values.categoryId, categoryGroups)
        if (values.remote === 'true' && !remoteAvailable) form.mutators.setFieldValue('remote', 'false')
        return (
          <>
            {!!stepsTitle && (
              <div className={css.steps}>
                <Wrapper wrapperClassName={css.wrapper}>
                  <h2 className={css.title}>{stepsTitle}</h2>
                  {!!stepsDesc && <p className={css.stepsDesc}>{stepsDesc}</p>}
                  <div className={css.items}>
                    <div className={css.item}>
                      <div className={css.number}>1</div>
                      <div className={css.text}>
                        <p className={css.name}>Post a Task</p>
                        <p className={css.desc}>{firstStep}</p>
                      </div>
                    </div>
                    <div className={css.item}>
                      <div className={css.number}>2</div>
                      <div className={css.text}>
                        <p className={css.name}>Get offers</p>
                        <p className={css.desc}>{secondStep}</p>
                      </div>
                    </div>
                    <div className={css.item}>
                      <div className={css.number}>3</div>
                      <div className={css.text}>
                        <p className={css.name}>Get things done</p>
                        <p className={css.desc}>{thirdStep}</p>
                      </div>
                    </div>
                  </div>
                </Wrapper>
              </div>
            )}
            <PageSegment title={'Post a Task'} withPaper={false}>
              <Paper>
                <Input name="title" label="Title" hint={titleDesc ? `Example: ${titleDesc}` : ''} />
                {isCategoriesSelectsVisible && (
                  <Select
                    fullwidth
                    label="Category Group"
                    name="categoryGroupId"
                    className="is-fullwidth"
                    onChange={() => {
                      form.mutators.setFieldValue('categoryId', '')
                    }}
                  >
                    <option value=""></option>
                    {categoryGroups.map((categoryGroup, i) => (
                      <option key={i} value={categoryGroup.id}>
                        {categoryGroup.title}
                      </option>
                    ))}
                  </Select>
                )}
                {isCategoriesSelectsVisible && !!values.categoryGroupId && (
                  <Select fullwidth label="Category" name="categoryId" className="is-fullwidth">
                    <option value=""></option>
                    {find(categoryGroups, {
                      id: values.categoryGroupId,
                    }).categories.map((category, i) => (
                      <option key={i} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </Select>
                )}
                {!!remoteAvailable && (
                  <RadioButtons label="Remote work" name="remote">
                    <RadioButton name="remote" label="Yes" value="true" />
                    <br />
                    <RadioButton name="remote" label="No" value="false" />
                  </RadioButtons>
                )}
                {values.remote === 'false' && (
                  <LocationInput
                    label="Location"
                    addressName="address"
                    latName="lat"
                    lngName="lng"
                    cityName="city"
                    postalCodeName="postalCode"
                    hint="Your full address will be visible only to selected service provider, others will only see city and post code."
                  />
                )}
                {/*}<RadioButtons label="Price type" name="priceType">
                              <RadioButton
                                name="priceType"
                                label="Maximum"
                                value="max"
                              />
                              <br />
                              <RadioButton
                                name="priceType"
                                label="Average"
                                value="average"
                              />
                              <br />
                              <RadioButton
                                name="priceType"
                                label="Contract"
                                value="contract"
                              />
                            </RadioButtons>
                            {values.priceType !== 'contract' && (
                              <Input name="price" label="Price value" />
                            )}*/}
                <Input name="price" label="What is your budget?" />
                <UploadMultiple name="photos" label="Photos" />
                <Textarea
                  name="text"
                  hint={
                    (!values.text || values.text.length < 100) &&
                    `${
                      values.text ? values.text.length : 0
                    }/100 characters minimum` /*textDesc ? `Example: ${textDesc}` : ''*/
                  }
                  label="Task description"
                />
                {!me && (
                  <RadioButtons label="Type" name="isBusiness">
                    <RadioButton name="isBusiness" label="Individual" value="false" />
                    <RadioButton name="isBusiness" label="Company" value="true" />
                  </RadioButtons>
                )}
                {(!me || !me.email) && (
                  <Input name="ownerEmail" label={values.isBusiness === 'true' ? 'Company email' : 'Your email'} />
                )}
                {(!me || !me.name) && (
                  <Input name="ownerName" label={values.isBusiness === 'true' ? 'Company name' : 'Your name'} />
                )}
                <DatePicker
                  name="deadline"
                  label="Deadline"
                  formik={form} />
                {alertExists && <Alert {...alertProps} />}
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control">
                    <Button {...buttonProps}>Post a Task</Button>
                  </div>
                </div>
              </Paper>
            </PageSegment>
          </>
        )
      }}
    </Form>
  )
}

export default props => {
  const sourceJobId = parseQueryString(props.location.search).sourceJobId
  return (
    <PageWrapper
      query={gql`
        query {
          getMe {
            me {
              id
              email
              emailConfirmed
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
              name
              isBusiness
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              textDesc
              titleDesc
              remoteAvailable
              stepsTitle
              stepsDesc
              firstStep
              secondStep
              thirdStep
              categories {
                id
                title
                alias
                textDesc
                titleDesc
                remoteAvailable
                stepsTitle
                stepsDesc
                firstStep
                secondStep
                thirdStep
              }
            }
          }
        }
      `}
      parser={[['categoryGroups', 'getCategoryGroups.categoryGroups'], ['me', 'getMe.me']]}
    >
      {({ categoryGroups, me }) => {
        return (
          <>
            <Query
              query={
                !sourceJobId
                  ? null
                  : gql`
                      query($input: GetJobInput!) {
                        getJob(input: $input) {
                          job {
                            id
                            translitTitle
                            title
                            owner {
                              id
                            }
                            text
                            remote
                            city
                            postalCode
                            lat
                            lng
                            address
                            photos
                            category {
                              id
                              categoryGroup {
                                id
                              }
                            }
                            averagePrice
                            maxPrice
                          }
                        }
                      }
                    `
              }
              variables={{ input: { jobId: sourceJobId } }}
              parser={[['job', 'getJob.job']]}
              withLoader
            >
              {({ job }) => <JobNewPage job={job} categoryGroups={categoryGroups} me={me} {...props} />}
            </Query>
          </>
        )
      }}
    </PageWrapper>
  )
}
