import gql from 'graphql-tag'

export default {
  me: {
    parser: [['me', 'getMe.me']],
    query: gql`
      query {
        getMe {
          me {
            id
            displayName
            email
            name
            balance
            avatar
            canBeAssignee
            dashlyHash
            permissions
            hasUnreadMessages
          }
        }
      }
    `,
  },
  empty: {},
}
