import React from 'react'
import cn from 'classnames'
import { Field } from 'react-final-form'

const Textarea = ({
  label,
  hint,
  width = 'default',
  hideError,
  className,
  inputClassName,
  autoComplete = false,
  ...restProps
}) => (
  <Field {...restProps}>
    {({ input, meta, placeholder, ...restFieldProps }) => {
      const isInvalid = (meta.error || meta.submitError) && meta.submitFailed
      return (
        <div className="field">
          {label && (
            <label className="label" htmlFor={restFieldProps.id || input.name}>
              {label}
            </label>
          )}
          <div className="control">
            <textarea
              {...input}
              {...restFieldProps}
              placeholder={placeholder}
              className={cn({
                textarea: true,
                'is-danger': isInvalid,
                [inputClassName]: !!inputClassName
              })}
              autoComplete={autoComplete ? 'on' : 'off'}
            />
            {isInvalid && !hideError && (
              <p
                className={cn({
                  help: true,
                  'is-danger': isInvalid,
                })}
              >
                {meta.error || meta.submitError}
              </p>
            )}
            {!!hint && <p className={'help'}>{hint}</p>}
          </div>
        </div>
      )
    }}
  </Field>
)

export default Textarea
