import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import toHumanDate from 'utils/toHumanDate'
import getUploadUrl from 'utils/getUploadUrl'
import { Link } from 'react-router-dom'
import ReviewsStat from 'components/ReviewsStat'
import PrettyText from 'components/PrettyText'
import Alert from 'components/Alert'
import { getUserRoute, getJobRoute } from 'routes'
import UserContacts from 'components/UserContacts'

const Bid = ({ bid, className, children, ...restProps }) => {
  return (
    <div
      className={cn({ [css.bid]: true, [className]: !!className })}
      {...restProps}
    >
      <div className={css.owner}>
        <img
          className={css.avatar}
          alt=""
          src={getUploadUrl(bid.owner.avatar, 'avatar')}
        />
        <div className={css.about}>
          <p className={css.name}>
            <Link className={css.link} to={getUserRoute(bid.owner.id)}>
              {bid.owner.displayName}
            </Link>
            , {toHumanDate(bid.createdAt)}
            {bid.job && (
              <>
                , <Link to={getJobRoute(bid.job.id, bid.job.translitTitle)}>{bid.job.title}</Link>
              </>
            )}
          </p>
          <ReviewsStat
            className={css.reviews}
            user={bid.owner}
            userRole="assignee"
          />
        </div>
      </div>
      <PrettyText className={css.content}>{bid.text}</PrettyText>
      {bid.price && (
        <p className={css.price}>
          <span className={css.label}>Price:</span>{' '}
          <b className={css.value}>£ {bid.price}</b>
        </p>
      )}
      {!!bid.owner.contacts && (
        <UserContacts className={css.contacts} user={bid.owner} />
      )}
      {bid.blocked && (
        <div className={css.alert}>
          <Alert
            message={
              <>
                Bid has been blocked: {bid.blockComment ? bid.blockComment : ''}
                . Email us{' '}
                <a href="mailto:support@suretask.com">
                  support@suretask.com
                </a>{' '}
                if you have any questions
              </>
            }
          />
        </div>
      )}
      {!!children && <div className={css.children}>{children}</div>}
    </div>
  )
}

export default Bid
