import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Modal from 'components/Modal'
import Query from 'components/Query'
import Textarea from 'components/Textarea'
import Link from 'components/Link'
import TagsInput from 'components/TagsInput'
import LocationInput from 'components/LocationInput'
import UploadSingle from 'components/UploadSingle'
import UploadMultiple from 'components/UploadMultiple'
import { createValidator, required, maxLength } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import { getUserRoute } from 'routes'
import {
  track,
  setUserData,
  fragments as mixpanelFragments,
} from 'utils/mixpanel'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import css from './styles.module.scss'

const ProfileEditor = ({ showAssigneeFields }) => {
  const [profileSuggestorVisible, setProfileSuggestorVisible] = useState(false)
  const [companySuggestions, setCompanySuggestions] = useState([])
  const searchCompanyQ = gql`
    query($input: SuggestCompanyInfoInput!) {
      suggestCompanyInfo(input: $input) {
        suggestions {
          address
          companyNumber
          companyName
        }
      }
    }
  `
  const apolloClient = useApolloClient()
  const [updateMyProfile] = useMutation(gql`
    mutation($input: UpdateMyProfileInput!) {
      updateMyProfile(input: $input) {
        me {
          id
          city
          address
          lat
          lng
          name
          about
          avatar
          skills
          coverImage
          portfolioImages
          isBusiness
          regNumber
          ...Mixpanel_user
        }
      }
    }
    ${mixpanelFragments.user}
  `)
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              name
              city
              address
              lat
              lng
              about
              skills
              avatar
              coverImage
              portfolioImages
              isBusiness
              regNumber
              canBeAssignee
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : (
          <Form
            validate={createValidator({
              name: [required, maxLength(1000)],
              about: [maxLength(1000)],
              ...(me.canBeAssignee || showAssigneeFields
                ? {
                    address: [required],
                  }
                : {}),
            })}
            onSubmit={input =>
              updateMyProfile({
                variables: {
                  input: {
                    name: input.name || null,
                    city: input.city || null,
                    address: input.address || null,
                    lat: input.lat || null,
                    lng: input.lng || null,
                    about: input.about || null,
                    avatar: input.avatar || null,
                    portfolioImages: input.portfolioImages || [],
                    skills: input.skills || [],
                    coverImage: input.coverImage || null,
                    isBusiness: input.isBusiness === 'true',
                    regNumber: input.regNumber || null,
                  },
                },
              }).then(({ data }) => {
                track('Update profile info')
                setUserData({ user: data.updateMyProfile.me })
              })
            }
            initialValues={{
              name: me.name,
              city: me.city,
              address: me.address,
              lat: me.lat,
              lng: me.lng,
              about: me.about,
              avatar: me.avatar,
              coverImage: me.coverImage,
              portfolioImages: me.portfolioImages,
              skills: me.skills,
              isBusiness: (me.isBusiness === true).toString(),
              regNumber: me.regNumber,
            }}
            resetOnSuccess
            successMessage="Profile updated successfully"
            showValidationAlert
          >
            {({ alertExists, alertProps, buttonProps, values, form }) => {
              const showAssigneeFieldsHere =
                me.canBeAssignee || showAssigneeFields
              return (
                <>
                  <Modal
                    isOpen={profileSuggestorVisible}
                    onRequestClose={() => {
                      setProfileSuggestorVisible(false)
                    }}
                  >
                    <Form
                      onSubmit={input => {
                        return apolloClient.query({
                          query: searchCompanyQ,
                          variables: {
                            input: {
                              query: input.searchCompany,
                            },
                          },
                        })
                      }}
                      onSuccess={data => {
                        setCompanySuggestions(
                          data.suggestCompanyInfo.suggestions
                        )
                      }}
                      withPristine
                      hideSuccessAlert
                    >
                      {({
                        alertExists,
                        alertProps,
                        buttonProps,
                        submitSucceeded,
                      }) => (
                        <>
                          <Input label="Company name" name="searchCompany" />
                          {alertExists && <Alert {...alertProps} />}
                          <Button fullWidth {...buttonProps}>
                            Search
                          </Button>

                          {!companySuggestions.length && submitSucceeded && (
                            <div className={css.companySuggestions}>
                              <Alert message="Nothing found." />
                            </div>
                          )}
                          {!!companySuggestions.length && (
                            <div className={css.companySuggestions}>
                              {companySuggestions.map((suggestion, i) => (
                                <div key={i} className={css.companySuggestion}>
                                  <Link
                                    type="dashed"
                                    onClick={e => {
                                      e.preventDefault()
                                      setProfileSuggestorVisible(false)
                                      form.mutators.setFieldValue(
                                        'name',
                                        suggestion.companyName
                                      )
                                      form.mutators.setFieldValue(
                                        'regNumber',
                                        suggestion.companyNumber
                                      )
                                      form.mutators.setFieldValue(
                                        'address',
                                        suggestion.address
                                      )
                                      form.mutators.setFieldValue(
                                        'forceUpdate',
                                        true
                                      )
                                    }}
                                  >
                                    {suggestion.companyName}
                                  </Link>
                                  <div>{`Registration number: ${suggestion.companyNumber}`}</div>
                                  <div>{`Address: ${suggestion.address}`}</div>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </Form>
                  </Modal>
                  <RadioButtons label="Type" name="isBusiness">
                    <RadioButton
                      name="isBusiness"
                      label="Individual"
                      value="false"
                    />
                    <RadioButton
                      name="isBusiness"
                      label="Company"
                      value="true"
                    />
                  </RadioButtons>
                  {values.isBusiness === 'true' && (
                    <div className="field">
                      <Button
                        outline
                        onClick={e => {
                          e.preventDefault()
                          setProfileSuggestorVisible(true)
                        }}
                      >
                        Get details using company house
                      </Button>
                    </div>
                  )}
                  <Input
                    label={
                      values.isBusiness === 'true' ? 'Company name' : 'Name'
                    }
                    name="name"
                  />
                  {values.isBusiness === 'true' && (
                    <Input label="Registration number" name="regNumber" />
                  )}
                  <Textarea
                    label={
                      values.isBusiness === 'true'
                        ? 'About company'
                        : 'About me'
                    }
                    name="about"
                    hint="Tell about yourself and what you do, other users will see this on your profile page. It is forbidden to share your contact details in this field."
                  />
                  {showAssigneeFieldsHere && (
                    <TagsInput
                      name="skills"
                      label="Skills"
                      placeholder="Add Skills"
                      hint={
                        'Add what are you good at. Keep your skills updated with any new skills so other members can see what you can offer.'
                      }
                    />
                  )}
                  <LocationInput
                    hint="This location is used on the jobs search page to specify distance. Other users will only see from which city you are from."
                    label="Location"
                    addressName="address"
                    latName="lat"
                    lngName="lng"
                    cityName="city"
                    postalCodeName="postalCode"
                  />
                  <UploadSingle
                    hint={
                      values.isBusiness === 'true'
                        ? ''
                        : 'Upload your real photo, this will increase the trust from other users. It will be easier for you to find a job or a service provider.'
                    }
                    accept="image/*"
                    label={
                      values.isBusiness === 'true' ? 'Company logo' : 'Userpic'
                    }
                    name="avatar"
                    uploadPreviewPreset="avatar"
                  />
                  <UploadSingle
                    hint={'Add your stand-out SureTask cover image.'}
                    accept="image/*"
                    label={'Cover image'}
                    name="coverImage"
                    uploadPreviewPreset="coverImagePreview"
                    previewSize="cover"
                  />
                  {showAssigneeFieldsHere && (
                    <UploadMultiple
                      name="portfolioImages"
                      label="Portfolio"
                      hint={
                        'Showcase your work by adding photos to your portfolio (visible on your profile).'
                      }
                    />
                  )}
                  {alertExists && <Alert {...alertProps} />}
                  <Buttons>
                    <Button {...buttonProps}>Save Changes</Button>
                    <Button outline to={getUserRoute(me.id)}>
                      View My Public Profile
                    </Button>
                  </Buttons>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default ProfileEditor
