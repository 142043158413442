import React from 'react'
import { Field } from 'react-final-form'

const Checkbox = ({ label, ...restProps }) => (
  <Field type="checkbox" {...restProps}>
    {({ input, meta, ...restFieldProps }) => {
      return (
        <label className="checkbox" {...restFieldProps}>
          <input {...input} /> {label}
        </label>
      )
    }}
  </Field>
)

export default Checkbox
