import find from 'lodash/find'

const getValue = (categoryGroup, category, key, dflt) => {
  return category && category[key] !== null
    ? category[key]
    : categoryGroup && categoryGroup[key] !== null
    ? categoryGroup[key]
    : dflt
}

const getCategorySpecificValues = (
  categoryGroupId,
  categoryId,
  categoryGroups
) => {
  const categoryGroup = !categoryGroupId
    ? null
    : find(
        categoryGroups,
        categoryGroup => categoryGroup.id === categoryGroupId
      )
  const category =
    !categoryGroup || !categoryId
      ? null
      : find(categoryGroup.categories, category => category.id === categoryId)
  const textDesc = getValue(categoryGroup, category, 'textDesc', '')
  const titleDesc = getValue(categoryGroup, category, 'titleDesc', '')
  const stepsTitle = getValue(categoryGroup, category, 'stepsTitle', '')
  const stepsDesc = getValue(categoryGroup, category, 'stepsDesc', '')
  const firstStep = getValue(
    categoryGroup,
    category,
    'firstStep',
    'Tell us what you need. It’s FREE to post'
  )
  const secondStep = getValue(
    categoryGroup,
    category,
    'secondStep',
    'Review offers from trusted service providers'
  )
  const thirdStep = getValue(
    categoryGroup,
    category,
    'thirdStep',
    'Hire the right service provider for your Task'
  )
  const remoteAvailable = getValue(
    categoryGroup,
    category,
    'remoteAvailable',
    true
  )
  return {
    stepsTitle,
    stepsDesc,
    firstStep,
    secondStep,
    thirdStep,
    titleDesc,
    textDesc,
    remoteAvailable,
  }
}

export default getCategorySpecificValues
