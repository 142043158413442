import gql from 'graphql-tag'

const getJobStatus = (job, me) => {
  if (job.blocked) {
    return `Job ad has been blocked. Reason: ${job.blockComment}`
  }
  if (me && job.owner.id === me.id) {
    if (job.closed && job.assignee)
      return 'Job is closed. Service Provider found'
    if (job.closed && !job.assignee)
      return 'Job is closed. No Service Providers found for this task'
    if (!job.bids.length) return 'Customer awaits offers'
    if (!job.assignee) return 'Make your offer'
    if (job.assignee && !job.assigneeAcceptStartOffer)
      return 'Waiting service provider to accept an offer'
    if (job.assigneeAcceptStartOffer && !job.ownerReview)
      return 'Send feedback about service provider job'
    if (job.assigneeAcceptStartOffer && !job.assigneeReview)
      return 'Wait for service provider to send you feedback'
  }

  if (me && job.assignee && job.assignee.id === me.id) {
    if (job.closed && job.assignee) return 'You have finished this job'
    if (!job.assigneeAcceptStartOffer)
      return 'Job offer is waiting for you. Accept or reject it'
    if (job.assigneeAcceptStartOffer && !job.assigneeReview)
      return 'Send feedback about customer'
    if (job.assigneeAcceptStartOffer && !job.ownerReview)
      return 'Waiting for service provider to send you feedback'
  }

  if (job.closed && !job.assignee)
    return 'Job is closed. No Service Providers found for this task'
  if (job.assignee && job.assigneeAcceptStartOffer)
    return 'Job closed, assignee found'
  if (job.myBid) return 'Waiting for customer to choose service provider'
  return 'Open job'
}

export const jobStatusFragment = gql`
  fragment JobStatus_job on Job {
    id
    translitTitle
    owner {
      id
    }
    assignee {
      id
    }
    assigneeAcceptStartOffer
    closed
    bids {
      id
    }
    myBid {
      id
    }
    assigneeReview {
      id
    }
    ownerReview {
      id
    }
    blocked
    blockComment
  }
`

export default getJobStatus
