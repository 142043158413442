import React, { useState } from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import gql from 'graphql-tag'
import Button from 'components/Button'
import getUploadUrl from 'utils/getUploadUrl'
import truncate from 'utils/truncate'
import { getUserRoute, getSignUpRoute } from 'routes'
import { Link } from 'react-router-dom'
import JobInvationModal from 'components/JobInvationModal'
import ReviewsStat from 'components/ReviewsStat'

const UserPreview = ({ user, me, className, ...restProps }) => {
  const [isJobInvationModalOpen, setJobInvationModalOpen] = useState(false)
  return (
    <div
      className={cn({ [css.userPreview]: true, [className]: !!className })}
      {...restProps}
    >
      <JobInvationModal
        {...{
          isJobInvationModalOpen,
          setJobInvationModalOpen,
          userId: user.id,
          userName: user.displayName
        }}
      />
      <div className={css.person}>
        <img
          className={css.avatar}
          alt=""
          src={getUploadUrl(user.avatar, 'avatar')}
        />
        <div className={css.about}>
          <p className={css.name}>
            <Link className={css.link} to={getUserRoute(user.id)}>
              {user.displayName}
            </Link>
            {user.city && <>, {user.city}</>}
          </p>
          <ReviewsStat
            className={css.reviews}
            user={user}
            userRole="assignee"
          />
        </div>
      </div>
      {user.about && <p className={css.content}>{truncate(user.about, 150)}</p>}
      {me ? (
        <div className={css.buttonPlace}>
          <Button
            type="button"
            color="green"
            outline
            onClick={() => setJobInvationModalOpen(true)}
          >
            Offer a task
          </Button>
        </div>
      ) : (
        <div className={css.buttonPlace}>
          <Button
            type="button"
            color="green"
            outline
            to={getSignUpRoute()}
          >
            Offer a task
          </Button>
        </div>
      )}
    </div>
  )
}

export const userPreviewFragment = gql`
  fragment UserPreview_user on User {
    id
    avatar
    city
    displayName
    about
    asAssigneePoisitiveReviewsCount
    asAssigneeNegativeReviewsCount
    asAssigneeNeutralReviewsCount
  }
`

export default UserPreview
