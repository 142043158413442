import React, { useEffect } from 'react'
import Modal from 'components/Modal'
import Segment from 'components/Segment'
import PushAccess, { usePushAccess } from 'components/PushAccess'
import useGlobalState from 'lib/globalState'

const PushAccessModal = ({ onRequestClose, children, ...restProps }) => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const usePushAccessResult = usePushAccess()
  const {
    subscription,
    loading,
    me,
    pushNotificationSupported,
  } = usePushAccessResult
  useEffect(() => {
    if (loading) return
    if (
      !globalState.askedForPushAccess &&
      !subscription &&
      !loading &&
      me &&
      pushNotificationSupported
    ) {
      dispatchGlobalAction({
        type: 'showPushAccessModal',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  useEffect(() => {
    if (loading) return
    if (
      subscription
    ) {
      dispatchGlobalAction({
        type: 'hidePushAccessModal',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, subscription])
  return (
    <Modal
      isOpen={
        globalState.isPushAccessModalOpen && !globalState.isAfterSignUpModalOpen
      }
      onRequestClose={() => {
        if (!globalState.isAfterSignUpModalOpen)
          dispatchGlobalAction({
            type: 'hidePushAccessModal',
          })
      }}
    >
      <Segment title="Allow notifications on this device">
        <PushAccess usePushAccessResult={usePushAccessResult} />
      </Segment>
    </Modal>
  )
}

export default PushAccessModal
