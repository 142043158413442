import React from 'react'
import { getSignUpRoute, getSignInRoute } from 'routes'
import Form from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import { createValidator, required, email } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { track } from 'utils/mixpanel'

const RestorePasswordPage = () => {
  const [requestPasswordReset] = useMutation(gql`
    mutation($input: RequestPasswordResetInput!) {
      requestPasswordReset(input: $input) {
        success
      }
    }
  `)
  return (
    <PageWrapper redirectAuthorized>
      <PageSegment title="Restore Password">
        <Form
          validate={createValidator({
            email: [required, email],
          })}
          onSubmit={input =>
            requestPasswordReset({ variables: { input } }).then(() => {
              track('Request password reset', {
                Email: input.email,
              })
            })
          }
          resetOnSuccess
          successMessage="Password reset link send to email"
        >
          {({ alertExists, alertProps, buttonProps }) => {
            return (
              <>
                <Input label="E-mail" name="email" type="email" />
                {alertExists && <Alert {...alertProps} />}
                <Buttons>
                  <Button {...buttonProps}>Restore Password</Button>
                  <Button outline to={getSignInRoute()}>
                    Log In
                  </Button>
                  <Button outline to={getSignUpRoute()}>
                    Sign Up
                  </Button>
                </Buttons>
              </>
            )
          }}
        </Form>
      </PageSegment>
    </PageWrapper>
  )
}

export default RestorePasswordPage
