import React from 'react'
import cn from 'classnames'
import { Field } from 'react-final-form'

const Select = ({
  label,
  hint,
  width = 'default',
  hideError,
  className,
  fullwidth,
  autoComplete = false,
  children,
  options,
  onChange,
  ...restProps
}) => (
  <Field {...restProps}>
    {({ input, meta, placeholder, ...restFieldProps }) => {
      const isInvalid = (meta.error || meta.submitError) && meta.submitFailed
      return (
        <div className="field">
          {label && (
            <label className="label" htmlFor={restFieldProps.id || input.name}>
              {label}
            </label>
          )}
          <div className="control">
            <div
              className={cn({
                select: true,
                'is-fullwidth': !!fullwidth,
                'is-danger': isInvalid,
              })}
            >
              <select
                {...input}
                {...restFieldProps}
                onChange={(...args) => {
                  !!onChange && onChange(...args)
                  input.onChange(...args)
                }}
                autoComplete={autoComplete ? 'on' : 'off'}
              >
                {children
                  ? children
                  : options.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.title}
                      </option>
                    ))}
              </select>
            </div>
            {isInvalid && !hideError && (
              <p
                className={cn({
                  help: true,
                  'is-danger': isInvalid,
                })}
              >
                {meta.error || meta.submitError}
              </p>
            )}
            {!!hint && <p className={'help'}>{hint}</p>}
          </div>
        </div>
      )
    }}
  </Field>
)

export default Select
