import React, { useEffect, useState } from 'react'
import PageWrapper from 'components/PageWrapper'
import gql from 'graphql-tag'
import flatten from 'lodash/flatten'
import find from 'lodash/find'
import Form from 'components/Form'
import Button from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import CategoriesSelect from 'components/CategoriesSelect'
import Input from 'components/Input'
import Hit from 'components/Hit'
import CityInput from 'components/CityInput'
import { useApolloClient } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'
import useGlobalState from 'lib/globalState'
import { geolocated } from 'react-geolocated'
import { toMixpanelCategories, track } from 'utils/mixpanel'
import UserPreview, { userPreviewFragment } from 'components/UserPreview'
import parseQueryString from 'utils/parseQueryString'
import { getUsersRoute } from '../../routes'

const getUsersQuery = gql`
  query($input: GetUsersInput!) {
    getUsers(input: $input) {
      users {
        ...UserPreview_user
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${userPreviewFragment}
`

const UsersPage = ({ coords, location, history }) => {
  return (
    <PageWrapper
      query={gql`
        query {
          getMe {
            me {
              id
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
              }
            }
          }
        }
      `}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['me', 'getMe.me'],
      ]}
    >
      {({ me, categoryGroups }) => (
        <UsersPageInner
          {...{ me, categoryGroups, coords, location, history }}
        />
      )}
    </PageWrapper>
  )
}

const UsersPageInner = ({ me, categoryGroups, location, history }) => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const initalValues = globalState.getUsersInput
    ? globalState.getUsersInput
    : {
        categoryIds: flatten(
          categoryGroups.map(categoryGroup =>
            categoryGroup.categories.map(category => category.id)
          )
        ),
        categoryGroupIds: categoryGroups.map(categoryGroup => categoryGroup.id),
        city: '',
        keywords: '',
      }

  const initCategoryGroupAlias = parseQueryString(location.search)
    .categoryGroupAlias
  const initCategoryGroup = find(
    categoryGroups,
    categoryGroup => categoryGroup.alias === initCategoryGroupAlias
  )
  const initCategoryGroupId = initCategoryGroup ? initCategoryGroup.id : ''
  useEffect(() => {
    if (initCategoryGroupId) {
      const categoryIds = find(
        categoryGroups,
        categoryGroup => categoryGroup.id === initCategoryGroupId
      ).categories.map(category => category.id)
      dispatchGlobalAction({
        type: 'saveGetUsersInput',
        input: {
          ...initalValues,
          categoryGroupIds: [initCategoryGroupId],
          categoryIds,
        },
      })
      history.replace(getUsersRoute())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCategoryGroupId, categoryGroups])

  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    endCursor: null,
  })
  const [users, setUsers] = useState([])
  const apolloClient = useApolloClient()

  const formInputToQueryInput = formInput => {
    return {
      city: formInput.city || null,
      categoryIds: formInput.categoryIds,
      keywords: formInput.keywords || null,
    }
  }

  return initCategoryGroupId ? null : (
    <PageSegment
      title="Find a service provider"
      titleAlign="left"
      withPaper={false}
    >
      <Form
        onSubmit={async input => {
          const queryInput = formInputToQueryInput(input)
          const { data, errors } = await apolloClient.query({
            query: getUsersQuery,
            variables: {
              input: {
                ...queryInput,
                limit: 30,
              },
            },
          })
          track('Users search', {
            Categories: toMixpanelCategories(
              queryInput.categoryIds,
              categoryGroups
            ),
            City: queryInput.city,
            Keywords: queryInput.keywords,
            'Results exists': !!data.getUsers.users.length,
          })
          if (!errors)
            dispatchGlobalAction({
              type: 'saveGetUsersInput',
              input,
            })
          setUsers(data.getUsers.users)
          setPageInfo(data.getUsers.pageInfo)
        }}
        initialValuesEqual={() => true}
        initialValues={initalValues}
        hideSuccessAlert
      >
        {({ buttonProps, handleSubmit, values }) => (
          <>
            <Hit action={handleSubmit} />
            <div className="columns is-variable is-8">
              <div className="column is-narrow" style={{ width: '300px' }}>
                <h3 className="title is-5">Categories</h3>
                <CategoriesSelect categoryGroups={categoryGroups} />
              </div>
              <div className="column">
                <div className="columns is-variable is-3">
                  <div className="column">
                    <Input label="Keywords" name="keywords" />
                  </div>
                </div>
                <div className="columns is-variable is-3">
                  <div className="column">
                    <div className="control is-expanded">
                      <CityInput label="City" name="city" />
                    </div>
                  </div>

                  <div className="column is-narrow" style={{ paddingTop: 44 }}>
                    <Button {...buttonProps}>Search</Button>
                  </div>
                </div>
                <div className="container" style={{ marginTop: '3em' }}>
                  {!users.length ? (
                    <Alert message="There are no users matching your criteria" />
                  ) : (
                    <InfiniteScroll
                      loadMore={async () => {
                        const { data } = await apolloClient.query({
                          query: getUsersQuery,
                          variables: {
                            input: {
                              ...formInputToQueryInput(values),
                              cursor: pageInfo.endCursor,
                              limit: 10,
                            },
                          },
                        })
                        setUsers([...users, ...data.getUsers.users])
                        setPageInfo(data.getUsers.pageInfo)
                      }}
                      hasMore={pageInfo.hasNextPage}
                      loader={<div className="loader" key={0} />}
                    >
                      {users.map((user, i) => (
                        <UserPreview user={user} key={i} me={me} />
                      ))}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Form>
    </PageSegment>
  )
}

export default geolocated()(UsersPage)
