import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Form from 'components/Form'
// import Query from 'components/Query'
import Button from 'components/Button'
import Alert from 'components/Alert'
// import Checkbox from 'components/Checkbox'
// import usePushNotifications from 'utils/usePushNotifications'
import { track, setUserData } from 'utils/mixpanel'

const pushNotificationSupported =
  'serviceWorker' in navigator && 'PushManager' in window

export const usePushAccess = ({ result } = {}) => {
  const { loading: queryLoading, data } = useQuery(
    gql`
      query {
        getMe {
          me {
            id
            pushSubscriptions
          }
        }
      }
    `,
    {
      skip: !!result,
    }
  )
  const me = data && data.getMe && data.getMe.me
  const [permission, setPermission] = useState(
    window.Notification && window.Notification.permission
  )
  const [subscription, setSubscription] = useState()
  const [
    existingSubscriptionLoading,
    setExistingSubscriptionLoading,
  ] = useState(true)
  useEffect(() => {
    if (!me || !!result) return
    if (!pushNotificationSupported) {
      setExistingSubscriptionLoading(false)
      return
    }
    navigator.serviceWorker.ready
      .then(serviceWorker => {
        return serviceWorker.pushManager.getSubscription()
      })
      .then(pushSubscription => {
        if (me.pushSubscriptions.includes(JSON.stringify(pushSubscription))) {
          setSubscription(pushSubscription)
        }
        setExistingSubscriptionLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])
  return (
    result || {
      loading: queryLoading || existingSubscriptionLoading,
      subscription,
      setSubscription,
      permission,
      setPermission,
      me,
      pushNotificationSupported,
    }
  )
}

const PushAccess = ({ usePushAccessResult }) => {
  const [addPushSubscription] = useMutation(gql`
    mutation($input: AddPushSubscriptionInput!) {
      addPushSubscription(input: $input) {
        me {
          id
          pushSubscriptions
        }
      }
    }
  `)

  const {
    loading,
    subscription,
    setSubscription,
    permission,
    setPermission,
    pushNotificationSupported,
  } = usePushAccess({ result: usePushAccessResult })

  if (loading) return <p>Loading...</p>

  return (
    <>
      {!pushNotificationSupported ? (
        <p>This device does not support push notifications.</p>
      ) : !!subscription ? (
        <p>
          This device receive push notifications. Notifications can be disabled in browser settings.
        </p>
      ) : permission === 'denied' ? (
        <p>
          Push notifications are disabled on this device. Manually change browser setting to allow push notifications.
        </p>
      ) : (
        <>
          <div className="field">
            <p>
              SureTask would like to send you notifications. Click Allow to receive notifications.
            </p>
          </div>
          <Form
            onSubmit={async () => {
              const requestedPermission = await Notification.requestPermission()
              setPermission(requestedPermission)
              if (requestedPermission !== 'granted') return
              const serviceWorker = await navigator.serviceWorker.ready
              const subscription = await serviceWorker.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
              })
              setSubscription(subscription)
              return addPushSubscription({
                variables: {
                  input: {
                    subscription: JSON.stringify(subscription),
                  },
                },
              }).then(() => {
                track('Add push subscription')
                setUserData({
                  'Has push subscriptions': true,
                })
              })
            }}
            hideSuccessAlert
          >
            {({ alertExists, alertProps, buttonProps }) => {
              return (
                <>
                  {alertExists && <Alert {...alertProps} />}
                  <div className="field is-grouped is-grouped-multiline">
                    <div className="control">
                      <Button {...buttonProps}>Allow</Button>
                    </div>
                  </div>
                </>
              )
            }}
          </Form>
        </>
      )}
    </>
  )
}

// export usePushAccess

export default PushAccess
