import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import toExternalUrl from 'utils/toExternalUrl'
import phoneIcon from 'assets/images/contacts-icons/phone.png'
import emailIconPath from 'assets/images/contacts-icons/mail.png'
import instagramIconPath from 'assets/images/contacts-icons/instagram.png'
import facebookIconPath from 'assets/images/contacts-icons/facebook.png'
import websiteIconPath from 'assets/images/contacts-icons/website.png'

const UserContacts = ({ user, inline, className, ...restProps }) => (
  <div
    className={cn({
      [css.contacts]: true,
      [css.inline]: !!inline,
      [className]: !!className,
    })}
  >
    {user.contacts.phone && (
      <div className={css.item}>
        <div className={css.iconPlace}>
          <img alt="" width={13} height={14} src={phoneIcon} className={css.icon} />
        </div>
        <div className={css.value}>
          <a target={'_blank'} rel="nofollow" className={css.link} href={`tel:${user.contacts.phone}`}>
            {user.contacts.phone}
          </a>
        </div>
      </div>
    )}
    {user.contacts.email && (
      <div className={css.item}>
        <div className={css.iconPlace}>
          <img alt="" width={14} height={12} src={emailIconPath} className={css.icon} />
        </div>
        <div className={css.value}>
          <a target={'_blank'} rel="nofollow" className={css.link} href={`mailto:${user.contacts.email}`}>
            {user.contacts.email}
          </a>
        </div>
      </div>
    )}
    {user.contacts.instagram && (
      <div className={css.item}>
        <div className={css.iconPlace}>
          <img alt="" width={13} height={13} src={instagramIconPath} className={css.icon} />
        </div>
        <div className={css.value}>
          <a target={'_blank'} className={css.link} href={user.contacts.instagram} rel="nofollow">
            {user.contacts.instagram}
          </a>
        </div>
      </div>
    )}
    {user.contacts.facebook && (
      <div className={css.item}>
        <div className={css.iconPlace}>
          <img alt="" width={9} height={15} src={facebookIconPath} className={css.icon} />
        </div>
        <div className={css.value}>
          <a target={'_blank'} className={css.link} href={user.contacts.facebook} rel="nofollow">
            {user.contacts.facebook}
          </a>
        </div>
      </div>
    )}
    {user.contacts.website && (
      <div className={css.item}>
        <div className={css.iconPlace}>
          <img alt="" width={15} height={15} src={websiteIconPath} className={css.icon} />
        </div>
        <div className={css.value}>
          <a target={'_blank'} className={css.link} href={toExternalUrl(user.contacts.website)} rel="nofollow">
            {user.contacts.website}
          </a>
        </div>
      </div>
    )}
  </div>
)

export default UserContacts
