import get from 'lodash/get'

export default (source, scheme) => {
  const result = {}
  if (!scheme) return result
  const schemeArray = scheme[0] && !Array.isArray(scheme[0]) ? [scheme] : scheme
  for (const item of schemeArray) {
    result[item[0]] = get(source, item[1], item[2])
  }
  return result
}
