import React from 'react'
import cn from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import css from './styles.module.scss'

const MenuLink = ({ to, href, className, disabled, selected, bold, nowrap, type = 'solid', ...etcProps }) => {
  const classNameHere = cn({
    [css.link]: true,
    [css.disabled]: disabled,
    [css.bold]: bold,
    [css.selected]: selected,
    [css.nowrap]: nowrap,
    [css[`type-${type}`]]: true,
    [className]: !!className,
  })
  const loc = window.location.pathname
  return to && loc !== to ? (
    <RouterLink to={to} className={classNameHere} {...etcProps} />
  ) : (
    <div className={classNameHere} {...etcProps} />
  )
}

export default MenuLink
