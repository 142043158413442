import React from 'react'
import css from './styles.module.scss'
import Wrapper from 'components/Wrapper'
import Button from 'components/Button'
import useGlobalState from 'lib/globalState'

const AcceptCookies = () => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  return globalState.cookiesAccepted ? null : (
    <div className={css.acceptCookies}>
      <Wrapper wrapperClassName={css.wrapper}>
        <p className={css.message}>
          SureTask uses cookies to make the site simpler
        </p>
        <Button
          type="button"
          outline
          onClick={() => {
            dispatchGlobalAction({
              type: 'acceptCookies',
            })
          }}
        >
          Accept Cookies
        </Button>
      </Wrapper>
    </div>
  )
}

export default AcceptCookies
