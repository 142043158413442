import React, { useState } from 'react'
import cn from 'classnames'
import { Field, FormSpy } from 'react-final-form'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import getUploadUrl from 'utils/getUploadUrl'
import Button from 'components/Button'
import css from './styles.module.scss'

const UploadSingle = ({
  label,
  hint,
  hideError,
  autoComplete = false,
  accept,
  maxSize = 10 * 1024 * 1024,
  uploadPreviewPreset = 'uploadPreview',
  previewSize = 'small',
  ...restProps
}) => {
  const [loading, setLoading] = useState(false)
  const [sizeErr, setSizeErr] = useState(false)
  const [uploadSingle] = useMutation(gql`
    mutation($file: Upload!) {
      uploadSingle(file: $file) {
        name
      }
    }
  `)
  return (
    <FormSpy>
      {({ form }) => (
        <Field {...restProps}>
          {({ input, meta, placeholder, ...restFieldProps }) => {
            const isInvalid =
              ((meta.error || meta.submitError) && meta.submitFailed) || sizeErr
            return (
              <div className={cn("field", css.uploadSingle)}>
                {label && (
                  <label
                    className="label"
                    htmlFor={restFieldProps.id || input.name}
                  >
                    {label}
                  </label>
                )}
                <div className="control">
                  <>
                    {input.value && !loading && (
                      <p
                        className={cn({
                          [css.preview]: true,
                          [css[`previewSize-${previewSize}`]]: true,
                        })}
                        style={{ marginBottom: 10 }}
                      >
                        <img
                          alt=""
                          src={getUploadUrl(input.value, uploadPreviewPreset)}
                        />
                      </p>
                    )}
                    <div className={cn("field is-grouped is-grouped-multiline", css.buttons)}>
                      <div className="control">
                        <div
                          className="file"
                          style={{
                            pointerEvents: loading ? 'none' : 'auto',
                          }}
                        >
                          <label className="file-label">
                            <input
                              className="file-input"
                              type="file"
                              disabled={!!loading}
                              accept={accept}
                              onChange={async ({
                                target: {
                                  validity,
                                  files: [file],
                                },
                              }) => {
                                if (maxSize) {
                                  setSizeErr(false)
                                  if (file.size > maxSize) {
                                    setSizeErr(true)
                                    return
                                  }
                                }
                                setLoading(true)
                                try {
                                  const response = await uploadSingle({
                                    variables: { file },
                                  })
                                  const uploadedFileName =
                                    response.data.uploadSingle.name
                                  form.mutators.setFieldValue(
                                    input.name,
                                    uploadedFileName
                                  )
                                  form.mutators.setFieldTouched(input.name)
                                  setLoading(false)
                                } catch (err) {
                                  console.error(err)
                                  setLoading(false)
                                }
                              }}
                            />
                            <span className="file-cta">
                              <span className="file-label">
                                {loading
                                  ? 'Loading...'
                                  : input.value
                                  ? 'Upload another'
                                  : 'Upload'}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                      {input.value && !loading && (
                        <div className="control">
                          <Button
                            href="#null"
                            outline
                            onClick={e => {
                              e.preventDefault()
                              form.mutators.setFieldValue(input.name, '')
                              form.mutators.setFieldTouched(input.name)
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                  {isInvalid && !hideError && (
                    <p
                      className={cn({
                        help: true,
                        'is-danger': isInvalid,
                      })}
                    >
                      {meta.error ||
                        meta.submitError ||
                        'Max file size is 10 Mb'}
                    </p>
                  )}
                  {!!hint && <p className={'help'}>{hint}</p>}
                </div>
              </div>
            )
          }}
        </Field>
      )}
    </FormSpy>
  )
}

export default UploadSingle
