import React, { useState } from 'react'
import _ from 'lodash'
import flatten from 'lodash/flatten'
import { Field, useFormState, useForm } from 'react-final-form'

const CategoriesSelect = ({ categoryGroups, withOther }) => {
  const [categoryGroupsVisibility, setCategoryGroupsVisibility] = useState({})

  const toggleCategoryGroupVisibility = (e, id) => {
    e.preventDefault()
    setCategoryGroupsVisibility({
      ...categoryGroupsVisibility,
      [id]: !categoryGroupsVisibility[id],
    })
  }

  const isCategoryGroupsCheckboxInd = (
    categoryGroupId,
    checkedGroupIds,
    categoryGroups
  ) => {
    const categoryGroup = _.find(categoryGroups, { id: categoryGroupId })
    const categoryIds = categoryGroup.categories.map(category => category.id)
    const difference = _.difference(categoryIds, checkedGroupIds)
    return difference.length > 0 && difference.length < categoryIds.length
  }

  const { values } = useFormState()
  const form = useForm()

  return (
    <>
      <div className="field is-grouped is-grouped-multiline">
        <div className="control">
          <a
            href="#null"
            onClick={e => {
              e.preventDefault()
              const categoryIds = flatten(
                categoryGroups.map(categoryGroup =>
                  categoryGroup.categories.map(category => category.id)
                )
              )
              form.mutators.setFieldValue(
                'categoryIds',
                withOther ? [...categoryIds, null] : categoryIds
              )
              form.mutators.setFieldValue(
                'categoryGroupIds',
                categoryGroups.map(categoryGroup => categoryGroup.id)
              )
            }}
            className="link dashed"
          >
            Select all
          </a>
        </div>
        <div className="control">
          <a
            href="#null"
            onClick={e => {
              e.preventDefault()
              form.mutators.setFieldValue('categoryGroupIds', [])
              form.mutators.setFieldValue('categoryIds', [])
            }}
            className="link dashed"
          >
            Clear all
          </a>
        </div>
      </div>
      {categoryGroups.map((categoryGroup, i) => (
        <div key={i} style={{ marginBottom: '20px' }}>
          <div className="field" key={i}>
            <Field
              name="categoryGroupIds"
              value={categoryGroup.id}
              type="checkbox"
            >
              {({ input: { onChange, ...inputEtcProps } }) => (
                <div className="field">
                  <label className="checkbox">
                    <input
                      ref={el =>
                        el &&
                        (el.indeterminate = isCategoryGroupsCheckboxInd(
                          categoryGroup.id,
                          values.categoryIds,
                          categoryGroups
                        ))
                      }
                      onChange={(...args) => {
                        const thisGroupCategoryIds = categoryGroup.categories.map(
                          category => category.id
                        )
                        if (
                          _.includes(values.categoryGroupIds, categoryGroup.id)
                        ) {
                          form.mutators.setFieldValue(
                            'categoryIds',
                            _.without(
                              values.categoryIds,
                              ...thisGroupCategoryIds
                            )
                          )
                        } else {
                          form.mutators.setFieldValue(
                            'categoryIds',
                            _.union(values.categoryIds, thisGroupCategoryIds)
                          )
                        }
                        onChange(...args)
                      }}
                      {...inputEtcProps}
                    />{' '}
                    <b>{categoryGroup.title}</b>
                  </label>{' '}
                  <a
                    className="link dashed"
                    href="#null"
                    onClick={e =>
                      toggleCategoryGroupVisibility(e, categoryGroup.id)
                    }
                  >
                    {categoryGroupsVisibility[categoryGroup.id]
                      ? 'hide'
                      : 'show'}
                  </a>
                </div>
              )}
            </Field>
          </div>
          <div
            style={{
              paddingLeft: '20px',
              overflow: 'hidden',
              display: categoryGroupsVisibility[categoryGroup.id]
                ? 'block'
                : 'none',
            }}
          >
            {categoryGroup.categories.map((category, i) => (
              <Field
                key={i}
                name="categoryIds"
                value={category.id}
                type="checkbox"
              >
                {({ input }) => (
                  <div className="field">
                    <label className="checkbox">
                      <input {...input} /> {category.title}
                    </label>
                    <br />
                  </div>
                )}
              </Field>
            ))}
          </div>
        </div>
      ))}
      {withOther && (
        <div className="field">
          <Field name="categoryIds" value={''} type="checkbox">
            {({ input: { onChange, ...inputEtcProps } }) => (
              <div className="field">
                <label className="checkbox">
                  <input
                    onChange={(...args) => {
                      if (_.includes(values.categoryIds, null)) {
                        form.mutators.setFieldValue(
                          'categoryIds',
                          _.without(values.categoryIds, null)
                        )
                      } else {
                        form.mutators.setFieldValue('categoryIds', [
                          ...values.categoryIds,
                          null,
                        ])
                      }
                    }}
                    {...inputEtcProps}
                    checked={_.includes(values.categoryIds, null)}
                  />{' '}
                  <b>Other</b>
                </label>
                <br />
              </div>
            )}
          </Field>
        </div>
      )}
    </>
  )
}

export default CategoriesSelect

export const getCheckedCategoryGroupIds = (
  checkedCategoryIds,
  categoryGroups
) => {
  const checkedCategoryGroupIds = []
  for (const categoryGroup of categoryGroups) {
    const categoryIds = categoryGroup.categories.map(category => category.id)
    const difference = _.difference(categoryIds, checkedCategoryIds)
    if (difference.length < categoryIds.length) {
      checkedCategoryGroupIds.push(categoryGroup.id)
    }
  }
  return checkedCategoryGroupIds
}
