import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import Comment from 'components/Comment'
import BlockComment from 'components/BlockComment'
import EditComment from 'components/EditComment'
import DashboardLayout from 'components/DashboardLayout'
import { canManageComment } from 'utils/can'

const CommentsPage = ({ match, history }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      authorizedOnly
      query={gql`
        query Comments($input: GetCommentsInput) {
          getComments(input: $input) {
            comments {
              id
              text
              createdAt
              blocked
              blockComment
              job {
                id
                translitTitle
                title
              }
              owner {
                id
                displayName
                avatar
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `}
      parser={[
        ['comments', 'getComments.comments'],
        ['pageInfo', 'getComments.pageInfo'],
      ]}
      variables={{ input: { limit: 30 } }}
      fetchPolicy="network-only"
      checkAccess={({ me }) => canManageComment(me)}
      withMe
    >
      {({ comments, pageInfo, fetchMore, me }) => (
        <PageSegment title="All Comments" titleAlign="left" withPaper={false}>
          {!comments.length ? (
            <Alert message="No comments found for this filter." />
          ) : (
            <InfiniteScroll
              loadMore={async () => {
                fetchMore({
                  variables: {
                    input: {
                      cursor: pageInfo.endCursor,
                      limit: 10,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      getComments: {
                        ...fetchMoreResult.getComments,
                        comments: [
                          ...prev.getComments.comments,
                          ...fetchMoreResult.getComments.comments,
                        ],
                      },
                    }
                  },
                })
              }}
              hasMore={pageInfo.hasNextPage}
              loader={<div className="loader" key={0} />}
            >
              {comments.map((comment, i) => (
                <Comment key={i} me={me} comment={comment}>
                  <div style={{ marginBottom: 30 }}>
                    <EditComment comment={comment} />
                  </div>
                  <BlockComment comment={comment} />
                </Comment>
              ))}
            </InfiniteScroll>
          )}
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default CommentsPage
