import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import { getJobsRoute } from 'routes'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import PageWrapper from 'components/PageWrapper'
import CategoryIcon from 'components/CategoryIcon'
import Button from 'components/Button'
import { ReactComponent as PositiveIcon } from 'assets/images/reviews-icons/positive.svg'
import { ReactComponent as NeutralIcon } from 'assets/images/reviews-icons/neutral.svg'
import { ReactComponent as NegativeIcon } from 'assets/images/reviews-icons/negative.svg'
import { ReactComponent as RightArrowIcon } from 'assets/images/other/arrow-right.svg'
import triggerImgSrc1 from 'assets/images/landing-assignee/triggers/1.png'
import triggerImgSrc2 from 'assets/images/landing-assignee/triggers/2.png'
import triggerImgSrc3 from 'assets/images/landing-assignee/triggers/3.png'
import triggerImgSrc4 from 'assets/images/landing-assignee/triggers/4.png'
import { ReactComponent as StepIcon1 } from 'assets/images/landing-assignee/steps/1.svg'
import { ReactComponent as StepIcon2 } from 'assets/images/landing-assignee/steps/2.svg'
import { ReactComponent as StepIcon3 } from 'assets/images/landing-assignee/steps/3.svg'
import { ReactComponent as StepIcon4 } from 'assets/images/landing-assignee/steps/4.svg'
import { ReactComponent as StepIcon5 } from 'assets/images/landing-assignee/steps/5.svg'

const AssigneeLandingPage = () => {
  return (
    <PageWrapper
      query={gql`
        query {
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
            }
          }
        }
      `}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['jobs', 'getJobs.jobs'],
      ]}
    >
      {({ categoryGroups, jobs }) => (
        <>
          <div className={cn(css.header, css.section)}>
            <div className={css.wrapper}>
              <div className={css.intro}>
                <h1 className={css.title}>Need work?</h1>
                <p className={css.desc}>Search for job offers in your area</p>
                <Button color="green" shadow to={getJobsRoute()}>
                  Find a Job
                </Button>
              </div>
              <div className={css.userInfoPlace}>
                <div className={css.userInfo}>
                  <div className={css.stat}>
                    <span className={cn(css.item, css.positive)}>
                      <PositiveIcon className={css.icon} width={14} />
                      <span className={css.count}>112</span>
                    </span>
                    <span className={cn(css.item, css.negative)}>
                      <NegativeIcon className={css.icon} width={14} />
                      <span className={css.count}>0</span>
                    </span>
                    <span className={cn(css.item, css.neutral)}>
                      <NeutralIcon className={css.icon} width={14} />
                      <span className={css.count}>4</span>
                    </span>
                  </div>
                  <div className={css.text}>
                    <b>Hanna Morris,</b> Accountant
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.triggers, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                We will help you find new customers and earn money
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc1} className={css.img} />
                  <p className={css.text}>Decent income</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc2} className={css.img} />
                  <p className={css.text}>Flexible schedule</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc3} className={css.img} />
                  <p className={css.text}>Safe service</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc4} className={css.img} />
                  <p className={css.text}>Advertising savings</p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.steps, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Simple rules, many orders
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>1</p>
                    <StepIcon1 className={css.icon} />
                  </div>
                  <p className={css.text}>Respond to all relevant ads</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>2</p>
                    <StepIcon2 className={css.icon} />
                  </div>
                  <p className={css.text}>Receive feedback notifications</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>3</p>
                    <StepIcon3 className={css.icon} />
                  </div>
                  <p className={css.text}>If you choose</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>4</p>
                    <StepIcon4 className={css.icon} />
                  </div>
                  <p className={css.text}>You work with a customer</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>5</p>
                    <StepIcon5 className={css.icon} />
                  </div>
                  <p className={css.text}>Send each other reviews</p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.steps2, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                3 steps to money
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <p className={css.number}>1</p>
                  <p className={css.itemTitle}>Sign up</p>
                  <p className={css.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.number}>2</p>
                  <p className={css.itemTitle}>
                    Select a task and respond to it
                  </p>
                  <p className={css.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.number}>3</p>
                  <p className={css.itemTitle}>Get paid</p>
                  <p className={css.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.categories, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                We will help you find new customers and earn money
              </h2>
              <div className={css.cards}>
                {categoryGroups.map((categoryGroup, i) => (
                  <div key={i} className={css.cardPlace}>
                    <Link
                      className={css.card}
                      to={getJobsRoute({
                        categoryGroupAlias: categoryGroup.alias,
                      })}
                    >
                      <CategoryIcon
                        name={categoryGroup.alias}
                        className={css.icon}
                      />
                      <span className={css.namePlace}>
                        <span className={css.name}>{categoryGroup.title}</span>
                      </span>
                      <span className={css.more}>
                        Find a Job{' '}
                        <RightArrowIcon
                          width={7}
                          height={9}
                          className={css.rightArrow}
                        />
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/*}<div className={cn(css.popular, css.section)}>

          </div>*/}
          {/*<div className={cn(css.faq, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Frequently Asked Questions
              </h2>
              <div className={css.items}>
                <FAQItem
                  question="What projects can be done on SureTask?"
                  answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice  mmodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <FAQItem
                  question="What projects can be done on SureTask?"
                  answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice  mmodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <FAQItem
                  question="What projects can be done on SureTask?"
                  answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice  mmodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <FAQItem
                  question="What projects can be done on SureTask?"
                  answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice  mmodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <FAQItem
                  question="What projects can be done on SureTask?"
                  answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice  mmodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
              </div>
            </div>
          </div>*/}
        </>
      )}
    </PageWrapper>
  )
}

// const FAQItem = ({ question, answer }) => {
//   const [visible, setVisibility] = useState(false)
//   return (
//     <div className={cn({ [css.item]: true, [css.open]: visible })}>
//       <div className={css.question} onClick={() => setVisibility(!visible)}>
//         <span className={css.text}>{question}</span>
//         <RightArrowIcon className={css.arrow} />
//       </div>
//       <div className={css.answer}>{answer}</div>
//     </div>
//   )
// }

export default AssigneeLandingPage
