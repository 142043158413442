import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import Textarea from 'components/Textarea'
import TagsInput from 'components/TagsInput'
import LocationInput from 'components/LocationInput'
import UploadSingle from 'components/UploadSingle'
import UploadMultiple from 'components/UploadMultiple'
import { createValidator, required, maxLength } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import RadioButton, { RadioButtons } from 'components/RadioButton'

const ProfileManagerEditor = ({ userId }) => {
  const [updateUserProfile] = useMutation(gql`
    mutation($input: UpdateUserProfileInput!) {
      updateUserProfile(input: $input) {
        user {
          id
          email
          emailConfirmed
          city
          address
          lat
          lng
          name
          about
          avatar
          skills
          coverImage
          portfolioImages
          isBusiness
          regNumber
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query($input: GetUserInput!) {
          getUser(input: $input) {
            user {
              id
              email
              emailConfirmed
              name
              city
              address
              lat
              lng
              about
              skills
              avatar
              coverImage
              portfolioImages
              isBusiness
              regNumber
              canBeAssignee
            }
          }
        }
      `}
      withLoader
      parser={[['user', 'getUser.user']]}
      variables={{ input: { userId: userId } }}
    >
      {({ user }) =>
        !user ? null : (
          <Form
            validate={createValidator({
              name: [required, maxLength(1000)],
              about: [maxLength(1000)],
              ...(user.canBeAssignee
                ? {
                    address: [required],
                  }
                : {}),
            })}
            onSubmit={input =>
              updateUserProfile({
                variables: {
                  input: {
                    userId: user.id,
                    email: input.email || null,
                    emailConfirmed: input.emailConfirmed === 'true',
                    name: input.name || null,
                    city: input.city || null,
                    address: input.address || null,
                    lat: input.lat || null,
                    lng: input.lng || null,
                    about: input.about || null,
                    avatar: input.avatar || null,
                    portfolioImages: input.portfolioImages || [],
                    skills: input.skills || [],
                    coverImage: input.coverImage || null,
                    isBusiness: input.isBusiness === 'true',
                    regNumber: input.regNumber || null,
                  },
                },
              })
            }
            initialValues={{
              email: user.email,
              emailConfirmed: (user.emailConfirmed === true).toString(),
              name: user.name,
              city: user.city,
              address: user.address,
              lat: user.lat,
              lng: user.lng,
              about: user.about,
              avatar: user.avatar,
              coverImage: user.coverImage,
              portfolioImages: user.portfolioImages,
              skills: user.skills,
              isBusiness: (user.isBusiness === true).toString(),
              regNumber: user.regNumber,
            }}
            resetOnSuccess
            successMessage="Profile updated successfully"
            showValidationAlert
          >
            {({ alertExists, alertProps, buttonProps, values, form }) => {
              const showAssigneeFieldsHere = user.canBeAssignee
              return (
                <>
                  <Input label="Email" name="email" />
                  <RadioButtons label="Email confirmed" name="emailConfirmed">
                    <RadioButton
                      name="emailConfirmed"
                      label="Yes"
                      value="false"
                    />
                    <RadioButton
                      name="emailConfirmed"
                      label="No"
                      value="true"
                    />
                  </RadioButtons>
                  <RadioButtons label="Type" name="isBusiness">
                    <RadioButton
                      name="isBusiness"
                      label="Individual"
                      value="false"
                    />
                    <RadioButton
                      name="isBusiness"
                      label="Company"
                      value="true"
                    />
                  </RadioButtons>
                  <Input
                    label={
                      values.isBusiness === 'true' ? 'Company name' : 'Name'
                    }
                    name="name"
                  />
                  {values.isBusiness === 'true' && (
                    <Input label="Registration number" name="regNumber" />
                  )}
                  <Textarea
                    label={
                      values.isBusiness === 'true'
                        ? 'About company'
                        : 'About user'
                    }
                    name="about"
                  />
                  {showAssigneeFieldsHere && (
                    <TagsInput
                      name="skills"
                      label="Skills"
                      placeholder="Add Skills"
                    />
                  )}
                  <LocationInput
                    label="Location"
                    addressName="address"
                    latName="lat"
                    lngName="lng"
                    cityName="city"
                    postalCodeName="postalCode"
                  />
                  <UploadSingle
                    accept="image/*"
                    label={
                      values.isBusiness === 'true' ? 'Company logo' : 'Userpic'
                    }
                    name="avatar"
                    uploadPreviewPreset="avatar"
                  />
                  <UploadSingle
                    accept="image/*"
                    label={'Cover image'}
                    name="coverImage"
                    uploadPreviewPreset="coverImagePreview"
                    previewSize="cover"
                  />
                  {showAssigneeFieldsHere && (
                    <UploadMultiple name="portfolioImages" label="Portfolio" />
                  )}
                  {alertExists && <Alert {...alertProps} />}
                  <Buttons>
                    <Button {...buttonProps}>Save Changes</Button>
                  </Buttons>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default ProfileManagerEditor
