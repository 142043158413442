import React from 'react'
import { getJobRoute } from 'routes'
import PageWrapper from 'components/PageWrapper'
import Form from 'components/Form'
import Input from 'components/Input'
import LocationInput from 'components/LocationInput'
import Select from 'components/Select'
import Textarea from 'components/Textarea'
import UploadMultiple from 'components/UploadMultiple'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import {
  createValidator,
  required,
  integerPositive,
  minLength,
} from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import gql from 'graphql-tag'
import find from 'lodash/find'
import { useMutation } from '@apollo/react-hooks'
import { track, fragments as mixpanelFragments } from 'utils/mixpanel'
import getCategorySpecificValues from 'utils/getCategorySpecificValues'
import DashboardLayout from 'components/DashboardLayout'
import { canManageJob } from 'utils/can'
import DatePicker from 'components/DatePicker'

const JobEditPage = ({ match, history }) => {
  const [updateJob] = useMutation(gql`
    mutation($input: UpdateJobInput!) {
      updateJob(input: $input) {
        job {
          id
          translitTitle
          title
          photos
          owner {
            id
          }
          text
          remote
          city
          postalCode
          lat
          lng
          address
          category {
            id
            categoryGroup {
              id
            }
          }
          averagePrice
          maxPrice
          ...Mixpanel_job
        }
      }
    }
    ${mixpanelFragments.job}
  `)
  return (
    <PageWrapper
      Layout={DashboardLayout}
      query={gql`
        query($input: GetJobInput!) {
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              remoteAvailable
              categories {
                id
                title
                alias
                remoteAvailable
              }
            }
          }
          getJob(input: $input) {
            job {
              id
              translitTitle
              deadline
              title
              photos
              owner {
                id
              }
              text
              remote
              city
              postalCode
              lat
              lng
              address
              category {
                id
                categoryGroup {
                  id
                }
              }
              averagePrice
              maxPrice
            }
          }
        }
      `}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['job', 'getJob.job'],
      ]}
      variables={{ input: { jobId: match.params.jobId } }}
      authorizedOnly
      checkExists={({ job }) => job}
      checkAccess={({ me, job }) => canManageJob(me) || me.id === job.owner.id}
    >
      {({ categoryGroups, job }) => {
        const initialValues = {
          title: job.title,
          text: job.text,
          deadline: job.deadline,
          photos: job.photos,
          city: job.city,
          postalCode: job.postalCode,
          address: job.address,
          lat: job.lat,
          lng: job.lng,
          remote: job.remote + '',
          categoryId: job.category && job.category.id,
          categoryGroupId: job.category && job.category.categoryGroup.id,
          price: job.averagePrice
            ? '' + job.averagePrice
            : job.maxPrice
            ? '' + job.maxPrice
            : '',
          // priceType: job.averagePrice
          //   ? 'average'
          //   : job.maxPrice
          //   ? 'max'
          //   : 'contract',
        }
        return (
          <PageSegment title={job.title}>
            <Form
              initialValues={initialValues}
              validate={createValidator({
                title: [required],
                text: [required, minLength(100)],
                address: [
                  (value, data) => data.remote === 'false' && required(value),
                ],
                // categoryId: [required],
                // categoryGroupId: [required],
                // priceType: [required],
                // price: [
                //   (value, data) =>
                //     data['priceType'] !== 'contract' && required(value),
                //   integerPositive,
                // ],
                price: [required, integerPositive],
              })}
              onSubmit={formInput => {
                const mutationInput = {
                  jobId: job.id,
                  title: formInput.title,
                  categoryId: formInput.categoryId || null,
                  city: formInput.remote === 'true' ? null : formInput.city,
                  postalCode:
                    formInput.remote === 'true' ? null : formInput.postalCode,
                  address:
                    formInput.remote === 'true' ? null : formInput.address,
                  lat: formInput.remote === 'true' ? null : formInput.lat,
                  lng: formInput.remote === 'true' ? null : formInput.lng,
                  remote: formInput.remote === 'true',
                  deadline: formInput.deadline,
                  // maxPrice:
                  //   formInput.priceType === 'max' ? +formInput.price : null,
                  // averagePrice:
                  //   formInput.priceType === 'average' ? +formInput.price : null,
                  averagePrice: +formInput.price,
                  text: formInput.text,
                  photos: formInput.photos,
                }
                return updateJob({
                  variables: { input: mutationInput },
                }).then(({ data }) => {
                  track('Update job', { job: data.updateJob.job })
                })
              }}
              successMessage="Changes saved successfully"
              showValidationAlert
            >
              {({ alertExists, alertProps, buttonProps, values, form }) => {
                const { remoteAvailable } = getCategorySpecificValues(
                  values.categoryGroupId,
                  values.categoryId,
                  categoryGroups
                )
                if (values.remote === 'true' && !remoteAvailable)
                  form.mutators.setFieldValue('remote', 'false')
                return (
                  <>
                    <Input name="title" label="Title" />
                    <Select
                      fullwidth
                      label="Category Group"
                      name="categoryGroupId"
                      className="is-fullwidth"
                      onChange={() => {
                        form.mutators.setFieldValue('categoryId', '')
                      }}
                    >
                      <option value=""></option>
                      {categoryGroups.map((categoryGroup, i) => (
                        <option key={i} value={categoryGroup.id}>
                          {categoryGroup.title}
                        </option>
                      ))}
                    </Select>
                    {!!values.categoryGroupId && (
                      <Select
                        fullwidth
                        label="Category"
                        name="categoryId"
                        className="is-fullwidth"
                      >
                        <option value=""></option>
                        {find(categoryGroups, {
                          id: values.categoryGroupId,
                        }).categories.map((category, i) => (
                          <option key={i} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </Select>
                    )}
                    {!!remoteAvailable && (
                      <RadioButtons label="Remote work" name="remote">
                        <RadioButton name="remote" label="Yes" value="true" />
                        <br />
                        <RadioButton name="remote" label="No" value="false" />
                      </RadioButtons>
                    )}
                    {values.remote === 'false' && (
                      <LocationInput
                        label="Location"
                        addressName="address"
                        latName="lat"
                        lngName="lng"
                        cityName="city"
                        postalCodeName="postalCode"
                        hint="Your full address will be visible only to selected service provider, others will only see city and post code."
                      />
                    )}
                    {/*}<RadioButtons label="Price type" name="priceType">
                      <RadioButton
                        name="priceType"
                        label="Maximum"
                        value="max"
                      />
                      <br />
                      <RadioButton
                        name="priceType"
                        label="Average"
                        value="average"
                      />
                      <br />
                      <RadioButton
                        name="priceType"
                        label="Contract"
                        value="contract"
                      />
                    </RadioButtons>
                    {values.priceType !== 'contract' && (
                      <Input name="price" label="Price value" />
                    )}*/}
                    <Input name="price" label="What is your budget?" />
                    <UploadMultiple name="photos" label="Photos" />
                    <Textarea
                      name="text"
                      label="Task description"
                      hint={
                        (!values.text || values.text.length < 100) &&
                        `${
                          values.text ? values.text.length : 0
                        }/100 characters minimum`
                      }
                    />
                    <DatePicker
                      name="deadline"
                      label="Deadline"
                      formik={form}
                      deadlineValue={job.deadline} />
                    {alertExists && <Alert {...alertProps} />}
                    <Buttons>
                      <Button {...buttonProps}>Save Changes</Button>
                      <Button outline to={getJobRoute(job.id, job.translitTitle)}>
                        View Job
                      </Button>
                    </Buttons>
                  </>
                )
              }}
            </Form>
          </PageSegment>
        )
      }}
    </PageWrapper>
  )
}

export default JobEditPage
