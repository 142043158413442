import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

const Loader = ({ type }) => {
  return (
    <div className={cn({ [css.loader]: true, [css[type]]: true })}>
      <div className="loader" />
    </div>
  )
}

export default Loader
