import React, { useState } from 'react'
import cn from 'classnames'
import { Field, useForm } from 'react-final-form'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { GoogleApiWrapper } from 'google-maps-react'
import _ from 'lodash'
import { geolocated } from 'react-geolocated'
import css from './styles.module.scss'

const CityInput = ({
  label,
  hint,
  hideError,
  autoComplete = false,
  name,
  google,
  coords,
  ...restProps
}) => {
  const form = useForm()
  const values = form.getState().values
  const [search, setSearch] = useState(values[name] || '')
  const searchOptions = {
    componentRestrictions: { country: ['uk'] },
    types: ['(cities)'],
  }
  const clearInput = () => {
    form.mutators.setFieldValue(name, '')
  }

  const parseGoogleResult = result => {
    const addressComponentWithCityname =
      _.find(result.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'postal_town')
      }) ||
      _.find(result.address_components, addressComponent => {
        return _.includes(addressComponent.types, 'locality')
      })
    if (!addressComponentWithCityname) return ''
    return addressComponentWithCityname.long_name
  }

  const applyGoogleResult = city => {
    const parsed = parseGoogleResult(city)
    form.mutators.setFieldValue(name, parsed)
    setSearch(parsed)
  }

  return (
    <div className={cn('field', css.cityInput)}>
      <Field {...restProps} name={name}>
        {({ input, meta, placeholder, ...restFieldProps }) => {
          const isInvalid =
            (meta.error || meta.submitError) && meta.submitFailed
          return (
            <div className="field">
              {label && (
                <label
                  className="label"
                  htmlFor={restFieldProps.id || input.name}
                >
                  {label}
                </label>
              )}
              <div className="control">
                <PlacesAutocomplete
                  searchOptions={searchOptions}
                  value={search}
                  onChange={value => {
                    if (!value) clearInput()
                    setSearch(value)
                  }}
                  debounce={500}
                  onSelect={async search => {
                    try {
                      const results = await geocodeByAddress(search)
                      applyGoogleResult(results[0])
                    } catch (err) {
                      console.error(err)
                    }
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    const { onBlur, ...inputProps } = getInputProps({
                      placeholder: 'Search city',
                      className: 'input',
                    })
                    const suggestionsHere = suggestions.filter(x => {
                      return x.description.split(',').length < 3
                    })
                    return (
                      <div>
                        <input
                          {...inputProps}
                          onBlur={() => {
                            onBlur()
                            setSearch(values[name] || '')
                          }}
                          className={cn({
                            input: true,
                            'is-danger': isInvalid,
                          })}
                          autoComplete="off"
                        />
                        {(!!suggestions.length || loading) && (
                          <div className={css.dropdown}>
                            {loading && <div className="loader" />}
                            {suggestionsHere.map(suggestion => {
                              const className = suggestion.active
                                ? css.suggestionItemActive
                                : css.suggestionItem
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <span>
                                    {suggestion.description.split(',')[0]}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  }}
                </PlacesAutocomplete>
                {isInvalid && !hideError && (
                  <p
                    className={cn({
                      help: true,
                      'is-danger': isInvalid,
                    })}
                  >
                    {meta.error || meta.submitError}
                  </p>
                )}
                {!!hint && <p className={'help'}>{hint}</p>}
              </div>
            </div>
          )
        }}
      </Field>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer: () => <div className="loader" />,
})(geolocated()(CityInput))
