import React from 'react'
import isEqual from 'lodash/isEqual'
// import isBrowser from 'lib/isBrowser'
import toPromise from 'utils/toPromise'

class Hit extends React.Component {
  state = {
    hitted: false,
    inProgress: false,
    lastArgs: null,
  }

  componentDidMount() {
    this.handler()
  }

  componentDidUpdate() {
    this.handler()
  }

  componentWillUnmount() {
    this.willUnmount = true
    this.props.onUnmout && this.props.onUnmout()
  }

  handler = () => {
    const { waitUntil, args = {}, action, onSuccess, onError } = this.props
    const { hitted, inProgress, lastArgs } = this.state
    const { willUnmount } = this
    // if (!isBrowser()) return null
    if (waitUntil && waitUntil()) return null
    if (!isEqual(lastArgs, args)) {
      if (!willUnmount)
        this.setState({
          hitted: false,
          inProgress: false,
          lastArgs: args,
        })
    }
    if (hitted || inProgress) return null
    if (action) {
      if (!willUnmount) this.setState({ inProgress: true })
      toPromise(
        action({
          ...args,
          lastArgs: lastArgs,
        })
      )
        .then((...args) => {
          if (!willUnmount) this.setState({ hitted: true, inProgress: false })
          onSuccess && onSuccess(...args)
        })
        .catch(error => {
          console.error(error)
          if (!willUnmount) this.setState({ hitted: true, inProgress: false })
          onError && onError(error)
        })
    }
  }

  render() {
    return null
  }
}

export default Hit
