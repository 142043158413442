import React from 'react'
import Modal from 'components/Modal'
import Segment from 'components/Segment'
import useGlobalState from 'lib/globalState'
import Query from 'components/Query'
import gql from 'graphql-tag'

const AfterSignUpModal = ({ onRequestClose, children, ...restProps }) => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              email
            }
          }
        }
      `}
      parser={[['email', 'getMe.me.email']]}
      withoutLoader
    >
      {({ email }) => (
        <Modal
          isOpen={!!email && globalState.isAfterSignUpModalOpen}
          onRequestClose={() =>
            dispatchGlobalAction({
              type: 'hideAfterSignUpModal',
            })
          }
        >
          <Segment title="Thank you for registering!">
            <p>We have sent you a confirmation email.</p>
          </Segment>
        </Modal>
      )}
    </Query>
  )
}

export default AfterSignUpModal
