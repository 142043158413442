import React, { useEffect, useState } from 'react'
import css from './styles.module.scss'
import Wrapper from 'components/Wrapper'
import Button from 'components/Button'

const InstallPWABtn = () => {
  const [installable, setInstallable] = useState(!!window.deferredPWAPrompt)
  // const [installable, setInstallable] = useState(true)
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    const listener = e => {
      e.preventDefault()
      window.deferredPWAPrompt = e
      setInstallable(true)
      setDisabled(false)
    }
    window.addEventListener('beforeinstallprompt', listener)
    return () => window.removeEventListener('beforeinstallprompt', listener)
  }, [])

  return !installable ? null : (
    <div className={css.btnWrapper}>
      {' '}
      <Button
        className={css.btn}
        type="button"
        disabled={disabled}
        onClick={() => {
          setDisabled(true)
          window.deferredPWAPrompt.prompt()
          window.deferredPWAPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
              setInstallable(false)
            }
            window.deferredPWAPrompt = null
          })
        }}
      >
        Install App
      </Button>
    </div>
  )
}

export default InstallPWABtn
