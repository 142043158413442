import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import getJobStatus, { jobStatusFragment } from 'utils/getJobStatus'
import JobPreview, { jobPreviewFragment } from 'components/JobPreview'
import Link from 'components/Link'
import Button, { Buttons } from 'components/Button'
import { getJobNewRoute } from 'routes'
import DashboardLayout from 'components/DashboardLayout'

const MyJobsPage = ({ match, history }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      query={gql`
        query MyJobs($input: GetMyJobsInput) {
          getMyJobs(input: $input) {
            jobs {
              ...JobStatus_job
              ...JobPreview_job
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
        ${jobStatusFragment}
        ${jobPreviewFragment}
      `}
      parser={[['jobs', 'getMyJobs.jobs'], ['pageInfo', 'getMyJobs.pageInfo']]}
      authorizedOnly
      withMe
      variables={({ me }) => ({
        input: { limit: 10, filter: match.params.filter },
      })}
      fetchPolicy="network-only"
      withLoader
    >
      {({ jobs, pageInfo, fetchMore, me }) => (
        <PageSegment title="Tasks">
          <div style={{ marginBottom: 35 }}>
            <Buttons style={{ marginBottom: 4 }}>
              <Link
                selected={match.params.filter === 'all'}
                type="dashed"
                onClick={e => {
                  e.preventDefault()
                  history.push('all')
                }}
              >
                All
              </Link>
              <Link
                selected={match.params.filter === 'active'}
                type="dashed"
                onClick={e => {
                  e.preventDefault()
                  history.push('active')
                }}
              >
                Active
              </Link>
              <Link
                selected={match.params.filter === 'closed'}
                type="dashed"
                onClick={e => {
                  e.preventDefault()
                  history.push('closed')
                }}
              >
                Completed
              </Link>
            </Buttons>
            {match.params.filter === 'all' && <p>All your created tasks.</p>}
            {match.params.filter === 'active' && (
              <p>Tasks where any sort of action is requested.</p>
            )}
            {match.params.filter === 'closed' && <p>Completed tasks.</p>}
          </div>
          {!jobs.length ? (
            match.params.filter === 'all' ? (
              <>
                <Alert
                  message={
                    "You haven't post any Tasks yet. Start by posting your first"
                  }
                />
                <Button to={getJobNewRoute()}>Post a Task</Button>
              </>
            ) : (
              <Alert message="No jobs found for this filter." />
            )
          ) : (
            <InfiniteScroll
              loadMore={async () => {
                fetchMore({
                  variables: {
                    input: {
                      cursor: pageInfo.endCursor,
                      limit: 10,
                      filter: match.params.filter,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      getMyJobs: {
                        ...fetchMoreResult.getMyJobs,
                        jobs: [
                          ...prev.getMyJobs.jobs,
                          ...fetchMoreResult.getMyJobs.jobs,
                        ],
                      },
                    }
                  },
                })
              }}
              hasMore={pageInfo.hasNextPage}
              loader={<div className="loader" key={0} />}
            >
              {jobs.map((job, i) => (
                <JobPreview key={i} job={job} status={getJobStatus(job, me)} />
              ))}
            </InfiniteScroll>
          )}
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default MyJobsPage
