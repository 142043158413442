import React from 'react'
import SocialButton from 'components/SocialButton'
import { Buttons } from 'components/Button'
import { getSignInRoute } from 'routes'
import querystring from 'querystring'
import getAbsUrl from 'utils/getAbsUrl'
import { withRouter } from 'react-router'

const providers = [
  { provider: 'google' },
  { provider: 'facebook' },
  // { provider: 'instagram' },
  { provider: 'apple' },
]

const SocialButtons = ({ successRoute, successUrl, location }) => {
  const qs = querystring.stringify({
    successUrl: successUrl || getAbsUrl(successRoute || location.pathname),
    failUrl: getAbsUrl(getSignInRoute()),
  })

  return (
    <Buttons>
      {providers.map(item => (
        <SocialButton
          linkUrl={`${process.env.REACT_APP_BACKEND_URI}/oauth/${item.provider}?${qs}`}
          provider={item.provider}
          key={`signin-social-${item.provider}`}
        />
      ))}
    </Buttons>
  )
}
export default withRouter(SocialButtons)
