import React from 'react'
import SocialIcon from 'components/SocialIcon'

const SocialButton = ({ provider, linkUrl }) => {
  // const name = {
  //   facebook: 'Facebook',
  //   instagram: 'Instagram',
  //   google: 'Google +',
  // }[provider]

  return (
    <a href={linkUrl}>
      <SocialIcon name={provider} href={linkUrl} />
    </a>
  )
}

export default SocialButton
