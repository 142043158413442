import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

const Wrapper = ({ className, wrapperClassName, children, nopadding, ...restProps }) => (
  <div
    className={cn({ [css.section]: true, [css.nopadding]: !!nopadding, [className]: !!className })}
    {...restProps}
  >
    <div
      className={cn({
        [css.wrapper]: true,
        [wrapperClassName]: !!wrapperClassName,
      })}
    >
      {children}
    </div>
  </div>
)

export default Wrapper
