import React from 'react'
import ErrorPageContent from 'components/ErrorPageContent'
import PageWrapper from 'components/PageWrapper'

const ErrorPage = ({ Layout, ...restProps }) => {
  return (
    <PageWrapper Layout={Layout}>
      <ErrorPageContent {...restProps} />
    </PageWrapper>
  )
}

export default ErrorPage
