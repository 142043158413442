import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import { getJobRoute } from 'routes'
import gql from 'graphql-tag'
import getJobPrice from 'utils/getJobPrice'
import Button from 'components/Button'
import Alert from 'components/Alert'
import toHumanDate from 'utils/toHumanDate'

const JobPreview = ({ job, status, bid }) => {
  const jobHere = bid ? bid.job : job
  return (
    <div className={css.jobPreview}>
      <div className={css.firstLine}>
        <h2 className={css.title}>{jobHere.title}</h2>
        <p className={css.price}>{getJobPrice(jobHere)}</p>
      </div>
      <div className={css.secondLine}>
        <div className={css.data}>
          <p className={css.category}>
            Category:{' '}
            {jobHere.category ? (
              <b>
                {jobHere.category.categoryGroup.title} / {jobHere.category.title}
              </b>
            ) : (
              <b>Other</b>
            )}
          </p>
          <p className={css.location}>
            Location: <b>{jobHere.remote ? 'Remote work' : jobHere.address}</b>
          </p>
          {/*<p className={css.date}>*/}
          {/*  Posted at: <b>{toHumanDate(job.createdAt)}</b>*/}
          {/*</p>*/}
          <p className={css.date}>
            Offers: <b>{job.bidsCount ? job.bidsCount : 0}</b>
          </p>
          {!!job.deadline && (
            <p className={css.date}>
              Deadline: <b>{toHumanDate(job.deadline, 'MMMM Do YYYY')}</b>
            </p>
          )}
          {!!bid && (
            <p className={css.date}>
              Bidded at: <b>{toHumanDate(bid.createdAt)}</b>
            </p>
          )}
          {!!status && (
            <p className={css.status}>
              Status: <b>{status}</b>
            </p>
          )}
        </div>
        <div className={css.buttonPlace}>
          <Button outline to={getJobRoute(jobHere.id, jobHere.translitTitle)}>
            Read More
          </Button>
        </div>
      </div>
      {bid && bid.blocked && (
        <div className={css.alert}>
          <Alert
            message={
              <>
                Your bid has been blocked: {bid.blockComment ? bid.blockComment : ''}. Email us{' '}
                <a href="mailto:support@suretask.com">support@suretask.com</a> if you have any questions
              </>
            }
          />
        </div>
      )}
    </div>
  )
}

export const JobPreviews = ({ className, ...rerstProps }) => (
  <div className={cn({ [className]: !!className, [css.jobPreviews]: true })} {...rerstProps} />
)

export const jobPreviewFragment = gql`
  fragment JobPreview_job on Job {
    id
    translitTitle
    deadline
    title
    category {
      id
      title
      categoryGroup {
        id
        title
      }
    }
    maxPrice
    averagePrice
    city
    postalCode
    address
    remote
    createdAt
    bidsCount
  }
`

export const jobWithBidPreviewFragment = gql`
  fragment JobWithBidPreview_bid on Bid {
    id
    createdAt
    blocked
    blockComment
    job {
      ...JobPreview_job
    }
  }
  ${jobPreviewFragment}
`

export default JobPreview
