import React from 'react'
import Paper from 'components/Paper'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import PageWrapper from 'components/PageWrapper'
import ContactsEditor from 'components/ContactsEditor'
import ChangeEmail from 'components/ChangeEmail'
import ProfileEditor from 'components/ProfileEditor'
import MyJobPreferencesEditor from 'components/MyJobPreferencesEditor'
import Segment from 'components/Segment'
import Form from 'components/Form'
import parseQueryString from 'utils/parseQueryString'
import gql from 'graphql-tag'
import { getMyProfileRoute, getJobRoute } from 'routes'
import { useMutation } from '@apollo/react-hooks'
import { track, setUserData } from 'utils/mixpanel'
import DashboardLayout from 'components/DashboardLayout'

const ServiceProviderFormPage = ({ location }) => {
  const [becomeServiceProvider] = useMutation(
    gql`
      mutation($input: BecomeServiceProviderInput!) {
        becomeServiceProvider(input: $input) {
          me {
            id
            canBeAssignee
            balance
          }
        }
      }
    `
  )
  const { jobId, jobTranslitTitle } = parseQueryString(location.search)
  return (
    <PageWrapper
      Layout={DashboardLayout}
      authorizedOnly
      query={gql`
        query {
          getMe {
            me {
              id
              email
              categories {
                id
              }
              name
              emailConfirmed
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
              canBeAssignee
            }
          }
        }
      `}
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : !me.canBeAssignee ? (
          <PageSegment
            withPaper={false}
            titleAlign="left"
            title="Service provider profile"
            desc="Fill all forms and press “Become a service provider” at the bottom of the page. Receive complimentary £5 to the account, to bid on any 5 tasks for free!"
          >
            <Paper>
              <Segment
                success={me && me.emailConfirmed}
                title="Email"
                desc="Set up and confirm your email address to receive email notification when you get selected as a service provider."
              >
                <ChangeEmail />
              </Segment>
            </Paper>
            <Paper>
              <Segment
                success={me && me.name}
                title="Profile"
                desc="Fill out your profile to become more attractive and trustworthy as a service provider."
              >
                <ProfileEditor showAssigneeFields={true} />
              </Segment>
            </Paper>
            <Paper>
              <Segment
                success={
                  me.contacts.email ||
                  me.contacts.phone ||
                  me.contacts.instagram ||
                  me.contacts.facebook
                }
                title="Contacts"
                desc="Your contacts will be available to the customer as soon as you place a bid."
              >
                <ContactsEditor />
              </Segment>
            </Paper>
            <Paper>
              <Segment
                success={me && !!me.categories.length}
                title="My job preferences"
                desc="Selected categories will be marked as your professional area. You will be receiving email task offers for selected categories."
              >
                <MyJobPreferencesEditor />
              </Segment>
            </Paper>

            <Form
              onSubmit={() =>
                becomeServiceProvider({
                  variables: {
                    input: {},
                  },
                }).then(() => {
                  track('User become assignee')
                  setUserData({ 'Can be assignee': true })
                })
              }
              hideSuccessAlert
            >
              {({ alertExists, alertProps, buttonProps }) => {
                return (
                  <div style={{ marginTop: 50 }}>
                    {alertExists && <Alert {...alertProps} />}
                    <Button fullWidth {...buttonProps}>
                      Become a Service Provider
                    </Button>
                  </div>
                )
              }}
            </Form>
          </PageSegment>
        ) : (
          <PageSegment
            withPaper={false}
            titleAlign="left"
            title="Congratulations! You’re registered as a Service Provider. £5 were credited to your account."
          >
            <Segment title="Any data on this page can be changed on your Profile page">
              <Buttons>
                {!!jobId && !!jobTranslitTitle && (
                  <Button to={getJobRoute(jobId, jobTranslitTitle)}>Back to task</Button>
                )}
                <Button outline to={getMyProfileRoute()}>
                  My profile
                </Button>
              </Buttons>
            </Segment>
          </PageSegment>
        )
      }
    </PageWrapper>
  )
}

export default ServiceProviderFormPage
