import React from 'react'
import SocialLink from './SocialLink'
import { getMyProfileRoute } from '../routes'
import querystring from 'querystring'
import getBaseUrl from '../utils/getBaseUrl'
import gql from 'graphql-tag'
import Query from 'components/Query'
import { Buttons } from 'components/Button'
import Alert from 'components/Alert'

const providers = [
  { provider: 'facebook' },
  // { provider: 'instagram' },
  { provider: 'google' },
  // { provider: 'apple' },
]

const SocialLinks = ({ linkFailed, ...rest }) => {
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              oauth {
                provider
                key
              }
              hasPassword
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) => {
        if (!me) return null
        const qs = querystring.stringify({
          successUrl: `${getBaseUrl()}${getMyProfileRoute()}`,
          failUrl: `${getBaseUrl()}${getMyProfileRoute()}`,
          userId: me.id,
          action: 'link',
        })
        const oauth = me.oauth
        const shouldBlock = me.oauth.length < 2 && !me.hasPassword
        return (
          <>
            <Buttons>
              {providers.map(item => (
                <SocialLink
                  linkUrl={`${process.env.REACT_APP_BACKEND_URI}/oauth/${item.provider}?${qs}`}
                  provider={item.provider}
                  isSet={oauth.find(obj => obj.provider === item.provider)}
                  key={`link-social-${item.provider}`}
                  disabled={shouldBlock}
                />
              ))}
            </Buttons>
            {linkFailed && (
              <Alert
                message={`Linking account failed. You should unlink it from another account.`}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}
export default SocialLinks
