import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Query from 'components/Query'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Input from 'components/Input'
import CityInput from 'components/CityInput'
import { track, setUserData, toMixpanelCategories } from 'utils/mixpanel'
import CategoriesSelect, {
  getCheckedCategoryGroupIds,
} from 'components/CategoriesSelect'

const MyJobPreferencesEditor = () => {
  const [updateMyJobPreferences] = useMutation(gql`
    mutation($input: UpdateMyJobPreferencesInput!) {
      updateMyJobPreferences(input: $input) {
        me {
          id
          lat
          lng
          preferredDistance
          preferredCity
          preferredKeywords
          categories {
            id
          }
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              categories {
                id
              }
              lat
              lng
              preferredDistance
              preferredCity
              preferredKeywords
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              categories {
                id
                title
              }
            }
          }
        }
      `}
      withLoader
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['me', 'getMe.me'],
      ]}
    >
      {({ categoryGroups, me }) =>
        !me ? null : (
          <Form
            onSubmit={input =>
              updateMyJobPreferences({
                variables: {
                  input: {
                    categoryIds: input.categoryIds,
                    preferredDistance: input.preferredDistance
                      ? +input.preferredDistance
                      : null,
                    preferredCity: input.preferredCity,
                    preferredKeywords: input.preferredKeywords,
                  },
                },
              }).then(() => {
                const mixpanelCategories = toMixpanelCategories(
                  input.categoryIds,
                  categoryGroups
                )
                track('Update job preferences', {
                  Categories: mixpanelCategories,
                  'Preferred distance': input.preferredDistance
                    ? +input.preferredDistance
                    : null,
                  'Preferred city': input.preferredCity || null,
                  'Preferred keywords': input.preferredKeywords || null,
                })
                setUserData({
                  Categories: mixpanelCategories,
                  'Preferred distance': input.preferredDistance
                    ? +input.preferredDistance
                    : null,
                  'Preferred city': input.preferredCity || null,
                  'Preferred keywords': input.preferredKeywords || null,
                })
              })
            }
            initialValues={{
              preferredDistance: me.preferredDistance,
              preferredCity: me.preferredCity,
              preferredKeywords: me.preferredKeywords,
              categoryIds: me.categories.map(category => category.id),
              categoryGroupIds: getCheckedCategoryGroupIds(
                me.categories.map(category => category.id),
                categoryGroups
              ),
            }}
            resetOnSuccess
            successMessage="Preferences updated successfully"
            showValidationAlert
          >
            {({ alertExists, alertProps, buttonProps }) => {
              const hasLocation = me && me.lat && me.lng
              return (
                <>
                  <CategoriesSelect categoryGroups={categoryGroups} />
                  {/*}<Input label="Keywords" name="preferredKeywords" />*/}
                  <CityInput label="City" name="preferredCity" />
                  {hasLocation && (
                    <Input label="Distance, mi" name="preferredDistance" />
                  )}
                  {alertExists && <Alert {...alertProps} />}
                  <div className="field is-grouped is-grouped-multiline">
                    <div className="control">
                      <Button {...buttonProps}>Save Changes</Button>
                    </div>
                  </div>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default MyJobPreferencesEditor
