import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Button, { Buttons } from 'components/Button'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Segment from 'components/Segment'
import Alert from 'components/Alert'
import Textarea from 'components/Textarea'
import { createValidator, required } from 'lib/validation'
import { getMyChatRoute } from 'routes'
import { track } from 'utils/mixpanel'

const StartChatModal = ({ startChatModalData, setStartChatModalData }) => {
  const { jobId, bidOwnerId, isOpen } = startChatModalData
  const [startChat] = useMutation(gql`
    mutation($input: StartChatInput!) {
      startChat(input: $input) {
        chat {
          id
        }
        bid {
          id
          chatId
          owner {
            id
          }
        }
      }
    }
  `)
  const [isSuccess, setSuccess] = useState(false)
  const [chatId, setChatId] = useState(false)
  const onRequestClose = () => {
    setSuccess(false)
    setChatId(false)
    setStartChatModalData({
      ...startChatModalData,
      isOpen: false,
    })
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {!isSuccess ? (
        <Form
          validate={createValidator({
            text: [required],
          })}
          onSubmit={input => {
            return startChat({
              variables: {
                input: {
                  text: input.text,
                  jobId: jobId,
                  bidOwnerId: bidOwnerId,
                },
              },
            }).then(({ data }) => {
              track('User start chat', {
                'Recipient id': data.startChat.bid.owner.id,
                'Job id': jobId,
                'Chat id': data.startChat.chat.id,
              })
              setChatId(data.startChat.chat.id)
              setSuccess(true)
            })
          }}
          hideSuccessAlert
        >
          {({ alertExists, alertProps, buttonProps, values }) => (
            <Segment title="Send message">
              <Textarea label="Message" name="text" />
              {alertExists && <Alert {...alertProps} />}
              <Button fullWidth {...buttonProps}>
                Send Message
              </Button>
            </Segment>
          )}
        </Form>
      ) : (
        <Segment
          title="Message sent"
          desc="Your message was sent."
        >
          <Buttons>
            <Button outline type="button" onClick={onRequestClose}>Go Back</Button>
            <Button outline to={getMyChatRoute(chatId)}>Chat</Button>
          </Buttons>
        </Segment>
      )}
    </Modal>
  )
}

export default StartChatModal
