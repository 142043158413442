import React from 'react'
import { getSignInRoute } from 'routes'
import Form from 'components/Form'
import Input from 'components/Input'
import { createValidator, required, confirmPassword } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Query from 'components/Query'
import PageSegment from 'components/PageSegment'
import PageWrapper from 'components/PageWrapper'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const ResetPasswordPage = ({ match }) => {
  const [resetPassword] = useMutation(gql`
    mutation($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        success
      }
    }
  `)
  return (
    <PageWrapper>
      <PageSegment title="Set New Password">
        <Query
          query={gql`
            query($input: IsPasswordResetTokenValidInput!) {
              isPasswordResetTokenValid(input: $input) {
                valid
              }
            }
          `}
          variables={{
            input: {
              passwordResetToken: match.params.passwordResetToken,
            },
          }}
          withLoader
          parser={[
            ['isPasswordResetTokenValid', 'isPasswordResetTokenValid.valid'],
          ]}
        >
          {({ isPasswordResetTokenValid }) =>
            !isPasswordResetTokenValid ? (
              <Alert type="fail" message="Password reset token is invalid" />
            ) : (
              <Form
                onSubmit={input =>
                  resetPassword({
                    variables: {
                      input: {
                        newPassword: input.newPassword,
                        passwordResetToken: match.params.passwordResetToken,
                      },
                    },
                  })
                }
                validate={createValidator({
                  newPassword: [required],
                  confirmPassword: [
                    required,
                    confirmPassword({ passwordField: 'newPassword' }),
                  ],
                })}
                success={
                  <Alert
                    type="success"
                    message="New password successfully set"
                  />
                }
              >
                {({ alertExists, alertProps, buttonProps }) => {
                  return (
                    <>
                      <Input
                        type="password"
                        label="New password"
                        name="newPassword"
                      />
                      <Input
                        type="password"
                        label="Repeat new password"
                        name="confirmPassword"
                      />
                      {alertExists && <Alert {...alertProps} />}
                      <Buttons>
                        <Button {...buttonProps}>Set New Password</Button>
                        <Button to={getSignInRoute()} outline>
                          Log In
                        </Button>
                      </Buttons>
                    </>
                  )
                }}
              </Form>
            )
          }
        </Query>
      </PageSegment>
    </PageWrapper>
  )
}

export default ResetPasswordPage
