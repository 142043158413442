import React from 'react'
import cn from 'classnames'

const Alert = ({ type, message, ...etcProps }) => (
  <article
    className={cn({
      message: true,
      'is-danger': type === 'fail',
      'is-success': 'success' === type,
    })}
  >
    <div className="message-body">{message}</div>
  </article>
)

export default Alert
