import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import cn from 'classnames'
import css from './styles.module.scss'
import InfiniteScroll from 'react-infinite-scroller'
import Alert from 'components/Alert'
import Form from 'components/Form'
import Link from 'components/Link'
import UserContacts from 'components/UserContacts'
import Button, { Buttons } from 'components/Button'
import gql from 'graphql-tag'
import toHumanDate from 'utils/toHumanDate'
import { getJobRoute, getMyChatsRoute, getUserRoute } from 'routes'
import { track, fragments as mixpanelFragments } from 'utils/mixpanel'
import batchGet from 'utils/batchGet'
import { createValidator, required } from 'lib/validation'
import reverse from 'lodash/reverse'
import * as Sentry from '@sentry/browser'
import { ReactComponent as SendIcon } from 'assets/images/other/send-icon.svg'
import { Field } from 'react-final-form'
import uniqBy from 'lodash/uniqBy'

const getChatQuery = gql`
  query GetChat($input: GetChatInput) {
    getMe {
      me {
        id
      }
    }
    getChat(input: $input) {
      chat {
        id
        updatedAt
        recipient {
          id
          avatar
          displayName
          contacts {
            email
            phone
            instagram
            facebook
            website
          }
        }
        job {
          id
          translitTitle
          title
          owner {
            id
          }
          assignee {
            id
          }
          assigneeAcceptStartOffer
          ...Mixpanel_job
        }
        available
        lastMessageText
        hasUnreadMessages
      }
      messages {
        id
        text
        createdAt
        isMy
        owner {
          id
          displayName
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
  ${mixpanelFragments.job}
`

const ChatContent = ({ chatId }) => {
  const [makeStartOffer] = useMutation(gql`
    mutation($input: MakeStartOfferInput!) {
      makeStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
        }
      }
    }
  `)
  const [cancelStartOffer] = useMutation(gql`
    mutation($input: CancelStartOfferInput!) {
      cancelStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
        }
      }
    }
  `)
  const [acceptStartOffer] = useMutation(gql`
    mutation($input: AcceptStartOfferInput!) {
      acceptStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
          assigneeAcceptStartOffer
        }
      }
    }
  `)
  const [rejectStartOffer] = useMutation(gql`
    mutation($input: RejectStartOfferInput!) {
      rejectStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
          assigneeAcceptStartOffer
        }
      }
    }
  `)
  const [sendMessage] = useMutation(gql`
    mutation($input: SendMessageInput!) {
      sendMessage(input: $input) {
        message {
          id
          text
          createdAt
        }
        chat {
          id
          lastMessageText
          updatedAt
          available
          recipient {
            id
          }
          job {
            id
            translitTitle
          }
        }
      }
    }
  `)
  const containerEl = useRef()
  const messagesEndEl = useRef()
  const { data, loading, fetchMore, error } = useQuery(getChatQuery, {
    variables: {
      input: {
        chatId,
        limit: 10,
      },
    },
    fetchPolicy: 'network-only',
    skip: !chatId,
  })
  const { pageInfo, messages = [], chat, job, me, startCursor } = batchGet(
    data,
    [
      ['messages', 'getChat.messages'],
      ['chat', 'getChat.chat'],
      ['job', 'getChat.chat.job'],
      ['me', 'getMe.me'],
      ['pageInfo', 'getChat.pageInfo'],
      ['startCursor', 'getChat.pageInfo.startCursor'],
    ]
  )
  const scrollToEnd = () => {
    if (containerEl.current)
      containerEl.current.scrollTop = containerEl.current.scrollHeight
  }
  useEffect(() => {
    return () => setInitialScrollDone(false)
  }, [chatId])
  useEffect(() => {
    if (!loading && !error && chatId) {
      scrollToEnd()
      setInitialScrollDone(true)
      setTimeout(() => {
        scrollToEnd()
      }, 1)
    }
  }, [loading, chatId, error])
  const loadLatest = useCallback(
    () =>
      fetchMore({
        variables: {
          input: {
            chatId,
            startCursor: startCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          if (
            fetchMoreResult.getChat.pageInfo.startCursor !==
            prev.getChat.pageInfo.startCursor
          )
            setTimeout(scrollToEnd, 1)
          return {
            ...fetchMoreResult,
            getChat: {
              ...fetchMoreResult.getChat,
              pageInfo: {
                ...prev.getChat.pageInfo,
                startCursor: fetchMoreResult.getChat.pageInfo.startCursor,
              },
              messages: uniqBy(
                [...fetchMoreResult.getChat.messages, ...prev.getChat.messages],
                message => message.id
              ),
            },
          }
        },
      }),
    [startCursor, chatId, fetchMore]
  )
  useEffect(() => {
    if (loading || error || !chatId) return undefined
    const interval = setInterval(() => {
      loadLatest()
    }, 5000)
    return () => clearInterval(interval)
  }, [loading, error, chatId, loadLatest])
  const [initialScrollDone, setInitialScrollDone] = useState(false)
  const isMyJob = me && job && me.id === job.owner.id
  const isHeAssignee =
    job && chat && job.assignee && job.assignee.id === chat.recipient.id
  const isMeAssignee = job && me && job.assignee && job.assignee.id === me.id
  const canMakeStartOffer =
    job && isMyJob && !job.assigneeAcceptStartOffer && !isHeAssignee
  const canCancelStartOffer =
    job && isMyJob && !job.assigneeAcceptStartOffer && isHeAssignee
  const canAcceptOrRejectStartOffer =
    job && !isMyJob && !job.assigneeAcceptStartOffer && isMeAssignee
  if (!chatId)
    return (
      <div className={css.chatContent}>
        <div className={css.emptyChat}>
          <Alert message="Select chat from the list" />
        </div>
      </div>
    )
  if (loading) return <>Loading...</>
  if (error) {
    Sentry.captureException(error)
    return <>Error</>
  }
  return (
    <div className={css.chatContent}>
      <div className={css.header}>
        <div className={css.left}>
          <p className={css.toChats}>
            <Link to={getMyChatsRoute(chat.job.id)}>
              ← Chat list
            </Link>
          </p>
          <p className={css.name}>
            <Link type="none" to={getUserRoute(chat.recipient.id)}>
              {chat.recipient.displayName}
            </Link>
          </p>
          <p className={css.job}>
            <Link type="none" to={getJobRoute(chat.job.id, chat.job.translitTitle)}>{chat.job.title}</Link>
          </p>
          {chat.recipient.contacts && (
            <div className={css.contacts}>
              <UserContacts inline user={chat.recipient} />
            </div>
          )}
        </div>
        {(canMakeStartOffer ||
          canCancelStartOffer ||
          canAcceptOrRejectStartOffer) && (
          <div className={css.right}>
            {canAcceptOrRejectStartOffer && (
              <Buttons>
                <Form
                  onSubmit={() =>
                    acceptStartOffer({
                      variables: {
                        input: {
                          jobId: job.id,
                        },
                      },
                    }).then(() => {
                      track('Accept start offer', {
                        job,
                      })
                    })
                  }
                  hideSuccessAlert
                >
                  {({ alertExists, alertProps, buttonProps }) => {
                    return (
                      <>
                        {alertExists && <Alert {...alertProps} />}
                        <Button {...buttonProps}>Accept Offer</Button>
                      </>
                    )
                  }}
                </Form>
                <Form
                  onSubmit={() =>
                    rejectStartOffer({
                      variables: {
                        input: {
                          jobId: job.id,
                        },
                      },
                    }).then(() => {
                      track('Reject start offer', {
                        job,
                      })
                    })
                  }
                  hideSuccessAlert
                >
                  {({ alertExists, alertProps, buttonProps }) => {
                    return (
                      <>
                        {alertExists && <Alert {...alertProps} />}
                        <Button {...buttonProps}>Reject Offer</Button>
                      </>
                    )
                  }}
                </Form>
              </Buttons>
            )}
            {(canMakeStartOffer || canCancelStartOffer) && (
              <Form
                onSubmit={() =>
                  canCancelStartOffer
                    ? cancelStartOffer({
                        variables: {
                          input: {
                            jobId: job.id,
                          },
                        },
                      }).then(() => {
                        track('Cancel start offer', {
                          job,
                          'Assignee Id': chat.recipient.id,
                        })
                      })
                    : makeStartOffer({
                        variables: {
                          input: {
                            jobId: job.id,
                            userId: chat.recipient.id,
                          },
                        },
                      }).then(() => {
                        track('Make start offer', {
                          job,
                          'Assignee Id': chat.recipient.id,
                        })
                      })
                }
                hideSuccessAlert
              >
                {({ alertExists, alertProps, buttonProps }) => {
                  return (
                    <>
                      {alertExists && <Alert {...alertProps} />}
                      <Buttons>
                        <Button {...buttonProps}>
                          {canCancelStartOffer
                            ? 'Cancel Offer'
                            : 'Make an Offer'}
                        </Button>
                      </Buttons>
                    </>
                  )
                }}
              </Form>
            )}
          </div>
        )}
      </div>
      <div className={css.messagesPlace}>
        <div className={css.messages} ref={el => (containerEl.current = el)}>
          <InfiniteScroll
            loadMore={() => {
              fetchMore({
                variables: {
                  input: {
                    chatId,
                    endCursor: pageInfo.endCursor,
                    limit: 10,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  return {
                    ...fetchMoreResult,
                    getChat: {
                      ...fetchMoreResult.getChat,
                      pageInfo: {
                        ...fetchMoreResult.getChat.pageInfo,
                        startCursor: prev.getChat.pageInfo.startCursor,
                      },
                      messages: [
                        ...prev.getChat.messages,
                        ...fetchMoreResult.getChat.messages,
                      ],
                    },
                  }
                },
              })
            }}
            getScrollParent={() => containerEl.current}
            hasMore={initialScrollDone && pageInfo.hasNextPage}
            loader={<div className="loader" key={0} />}
            useWindow={false}
            isReverse={true}
          >
            {reverse([...messages]).map((message, i) => (
              <div
                key={i}
                className={cn({
                  [css.message]: true,
                  [css.isMy]: message.isMy,
                })}
              >
                <div className={css.messagePanel}>
                  <div className={css.messageHeader}>
                    <p className={css.name}>{message.owner.displayName}</p>
                    <p className={css.date}>{toHumanDate(message.createdAt)}</p>
                  </div>
                  <div className={css.text}>{message.text}</div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
          <div key="x" ref={el => (messagesEndEl.current = el)} />
        </div>
      </div>
      <div className={css.bottom}>
        {chat.available ? (
          <Form
            validate={createValidator({
              text: [required],
            })}
            onSubmit={input => {
              return sendMessage({
                variables: {
                  input: {
                    text: input.text,
                    chatId,
                  },
                },
              })
                .then(({ data }) => {
                  track('User send message', {
                    'Chat id': chat.id,
                    'Job id': chat.job.id,
                    'Recipient id': chat.recipient.id,
                  })
                  return loadLatest()
                })
                .then(scrollToEnd)
            }}
            resetOnSuccess
            hideSuccessAlert
            withPristine
          >
            {({
              alertExists,
              alertProps,
              buttonProps,
              values,
              handleSubmit,
            }) => (
              <div className={css.form}>
                <Field name="text">
                  {({ input }) => {
                    return (
                      <textarea
                        {...input}
                        onKeyDown={e => {
                          if (e.keyCode === 13 && e.shiftKey === false) {
                            e.preventDefault()
                            handleSubmit()
                          }
                        }}
                        placeholder="Message..."
                        className={cn({
                          [css.textarea]: true,
                        })}
                        autoComplete={'off'}
                      />
                    )
                  }}
                </Field>
                {alertExists && <Alert {...alertProps} />}
                <button
                  className={cn({
                    [css.button]: true,
                    [css.loading]: !!buttonProps.loading,
                  })}
                  disabled={buttonProps.disabled}
                >
                  <SendIcon className={css.sendIcon} />
                </button>
              </div>
            )}
          </Form>
        ) : (
          <div className={css.block}>
            <Alert message="This chat is now available for reading only" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatContent
