import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import Title from 'components/Title'

const Segment = ({
  title,
  titleTag = 'h3',
  titleSize = 3,
  titleAlign = 'left',
  titleType = 'noLine',
  children,
  className,
  success,
  ...restProps
}) => {
  return (
    <div
      className={cn({ [css.segment]: true, [className]: !!className })}
      {...restProps}
    >
      {!!title && (
        <Title
          className={css.title}
          tag={titleTag}
          size={titleSize}
          align={titleAlign}
          type={titleType}
          success={success}
          {...restProps}
        >
          {title}
        </Title>
      )}
      {!!children && <div className={css.content}>{children}</div>}
    </div>
  )
}

export default Segment
