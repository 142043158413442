const truncate = (string = '', maxLength = 100) => {
  if (string.length < maxLength) return string
  let trimmedString = string.substr(0, maxLength)
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  )
  if (trimmedString.match(/[.,?!:]$/)) {
    trimmedString = trimmedString.substr(0, trimmedString.length - 1)
  }
  return trimmedString + '...'
}
export default truncate
