import React, { useState } from 'react'
import cn from 'classnames'
import { Field, FormSpy } from 'react-final-form'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import getUploadUrl from 'utils/getUploadUrl'
import Button from 'components/Button'
import css from './styles.module.scss'

const UploadMultiple = ({
  label,
  hint,
  hideError,
  autoComplete = false,
  accept,
  uploadPreviewPreset = 'uploadPreview',
  previewSize = 'small',
  ...restProps
}) => {
  const [loading, setLoading] = useState(false)
  const [uploadMultiple] = useMutation(gql`
    mutation($files: [Upload!]!) {
      uploadMultiple(files: $files) {
        name
        error
      }
    }
  `)
  return (
    <FormSpy>
      {({ form }) => (
        <Field {...restProps}>
          {({ input, meta, placeholder, ...restFieldProps }) => {
            const inputValue = input.value || []
            const isInvalid =
              (meta.error || meta.submitError) && meta.submitFailed
            return (
              <div className={cn("field", css.uploadMultiple)}>
                {label && (
                  <label
                    className="label"
                    htmlFor={restFieldProps.id || input.name}
                  >
                    {label}
                  </label>
                )}
                <div className="control">
                  <>
                    <div className={css.previews}>
                      {inputValue.map((value, i) => (
                        <div
                          key={i}
                          className={cn({
                            [css.preview]: true,
                            [css[`previewSize-${previewSize}`]]: true,
                          })}
                        >
                          <img
                            alt=""
                            src={getUploadUrl(value, uploadPreviewPreset)}
                          />
                          <span
                            className={cn(css.delete, "delete")}
                            onClick={e => {
                              e.preventDefault()
                              form.mutators.setFieldValue(
                                input.name,
                                inputValue.filter((x, j) => j !== i)
                              )
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      className={cn(
                        'field is-grouped is-grouped-multiline',
                        css.buttons
                      )}
                    >
                      <div className="control">
                        <div
                          className="file"
                          style={{
                            pointerEvents: loading ? 'none' : 'auto',
                          }}
                        >
                          <label className="file-label">
                            <input
                              className="file-input"
                              type="file"
                              disabled={!!loading}
                              accept={accept}
                              multiple
                              onChange={async ({
                                target: { validity, files },
                              }) => {
                                if (!validity.valid)
                                  throw new Error('File validation failed')
                                setLoading(true)
                                try {
                                  const response = await uploadMultiple({
                                    variables: { files },
                                  })
                                  const newInputValue = [...inputValue]
                                  for (const uploadedFile of response.data
                                    .uploadMultiple) {
                                    if (uploadedFile.error) {
                                      console.error(uploadedFile.error)
                                    } else {
                                      newInputValue.push(uploadedFile.name)
                                    }
                                  }
                                  form.mutators.setFieldValue(
                                    input.name,
                                    newInputValue
                                  )
                                  form.mutators.setFieldTouched(input.name)
                                  setLoading(false)
                                } catch (err) {
                                  console.error(err)
                                  setLoading(false)
                                }
                              }}
                            />
                            <span className="file-cta">
                              <span className="file-label">
                                {loading
                                  ? 'Loading...'
                                  : inputValue.length
                                  ? 'Upload more'
                                  : 'Upload'}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                      {!!inputValue.length && !loading && (
                        <div className="control">
                          <Button
                            href="#null"
                            outline
                            onClick={e => {
                              e.preventDefault()
                              form.mutators.setFieldValue(input.name, [])
                              form.mutators.setFieldTouched(input.name)
                            }}
                          >
                            Remove all
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                  {isInvalid && !hideError && (
                    <p
                      className={cn({
                        help: true,
                        'is-danger': isInvalid,
                      })}
                    >
                      {meta.error || meta.submitError}
                    </p>
                  )}
                  {!!hint && <p className={'help'}>{hint}</p>}
                </div>
              </div>
            )
          }}
        </Field>
      )}
    </FormSpy>
  )
}

export default UploadMultiple
