import React from 'react'
import Form from 'components/Form'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import cookieStore from 'lib/cookieStore'

const DeleteMe = () => {
  const apolloClient = useApolloClient()
  const [deleteMe] = useMutation(gql`
    mutation {
      deleteMe
    }
  `)
  return (
    <Form
      onSubmit={() =>
        deleteMe().then(() => {
          cookieStore.remove('token')
          apolloClient.resetStore()
        })
      }
    >
      {({ alertExists, alertProps, buttonProps }) => {
        return (
          <>
            {alertExists && <Alert {...alertProps} />}
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <Button color='red' {...buttonProps}>Delete My Account</Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default DeleteMe
