import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import { createValidator, required } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'

const BlockComment = ({ comment }) => {
  const [blockComment] = useMutation(gql`
    mutation($input: BlockCommentInput!) {
      blockComment(input: $input) {
        comment {
          id
          blocked
          blockComment
        }
      }
    }
  `)
  const [unblockComment] = useMutation(gql`
    mutation($input: UnblockCommentInput!) {
      unblockComment(input: $input) {
        comment {
          id
          blocked
          blockComment
        }
      }
    }
  `)
  return (
    <>
      {!comment.blocked && (
        <Form
          validate={createValidator({
            blockComment: [required],
          })}
          onSubmit={input =>
            blockComment({
              variables: {
                input: {
                  blockComment: input.blockComment || null,
                  commentId: comment.id,
                },
              },
            })
          }
          withPristine
          resetOnSuccess
          hideSuccessAlert
        >
          {({ alertExists, alertProps, buttonProps }) => {
            return (
              <>
                <Input label="Block reason" name="blockComment" />
                {alertExists && <Alert {...alertProps} />}
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control">
                    <Button {...buttonProps}>Block comment</Button>
                  </div>
                </div>
              </>
            )
          }}
        </Form>
      )}
      {comment.blocked && (
        <Form
          onSubmit={() =>
            unblockComment({
              variables: {
                input: {
                  commentId: comment.id,
                },
              },
            })
          }
          resetOnSuccess
          hideSuccessAlert
        >
          {({ alertExists, alertProps, buttonProps }) => {
            return (
              <>
                {alertExists && <Alert {...alertProps} />}
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control">
                    <Button {...buttonProps}>Unblock comment</Button>
                  </div>
                </div>
              </>
            )
          }}
        </Form>
      )}
    </>
  )
}

export default BlockComment
