import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import Query from 'components/Query'
import ErrorPage from 'pages/ErrorPage'
import gql from 'graphql-tag'
import isFunction from 'lodash/isFunction'
import { Redirect, withRouter } from 'react-router'
import { getDashboardRoute } from 'routes'
import getAbsUrl from 'utils/getAbsUrl'
import parseQueryString from 'utils/parseQueryString'
import { useApolloClient } from '@apollo/react-hooks'
import DefaultLayout from 'components/Layout'
import stringifyQueryObject from 'utils/stringifyQueryObject'
import { track, setUserData, aliasUser, identifyUser, fragments as mixpanelFragments } from 'utils/mixpanel'
import get from 'lodash/get'
import cookieStore from 'lib/cookieStore'
import ym from 'react-yandex-metrika'

const PageWrapper = ({
  authorizedOnly,
  redirectAuthorized,
  checkAccess,
  checkExists,
  withMe,
  children,
  variables,
  location,
  match,
  Layout = DefaultLayout,
  history,
  ...restProps
}) => {
  const apolloClient = useApolloClient()
  const { oauthAction, oauthProvider, link_created, token, ...restQueryString } = parseQueryString(location.search)
  useEffect(() => {
    window.scrollTo(0, 0)
    const pageUrl = getAbsUrl(location.pathname) + location.search
    if (process.env.REACT_APP_GOOGLE_TAG) {
      ReactGA.pageview(pageUrl)
    }
    if (process.env.REACT_APP_YM_ID) {
      ym('hit', location.pathname + location.search)
    }
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      window.Intercom('update')
    }
    if (process.env.REACT_APP_DASHLY_ON === 'true') {
      const dashlyEl =
        document.querySelector('#carrotquest-messenger-collapsed-container') ||
        document.querySelector('#carrotquest-messenger-collapsed')
      if (dashlyEl) dashlyEl.style.display = 'block'
    }
    track('Visit page', { Url: pageUrl })
    const updateMixpanelInfo = async () => {
      const { data } = await apolloClient.query({
        query: gql`
          query {
            getMe {
              me {
                ...Mixpanel_user
              }
            }
          }
          ${mixpanelFragments.user}
        `,
      })
      const user = get(data, 'getMe.me', {})
      if (oauthAction === 'signIn') {
        await track('Sign in', { Method: oauthProvider })
        await identifyUser(user.id)
      } else if (oauthAction === 'signUp') {
        await track('Sign up', { Method: oauthProvider })
        await aliasUser(user.id)
      } else if (oauthAction === 'linking' && link_created === 'true') {
        await track('Link social network', { Method: oauthProvider })
      }
      await setUserData({ user })
    }
    const redirectUrl = getAbsUrl(location.pathname) + stringifyQueryObject(restQueryString)
    if (oauthAction) {
      if (token) {
        cookieStore.set('token', token)
        apolloClient.clearStore().then(() => {
          updateMixpanelInfo().then(() => {
            window.location.replace(redirectUrl)
          })
        })
      } else {
        updateMixpanelInfo().then(() => {
          window.location.replace(redirectUrl)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, oauthAction, oauthProvider, link_created, token])
  return !!oauthAction ? null : (
    <Query
      query={
        (authorizedOnly || checkAccess || redirectAuthorized || withMe) &&
        gql`
          query {
            getMe {
              me {
                id
                email
                name
                balance
                avatar
                canBeAssignee
                dashlyHash
                permissions
              }
            }
          }
        `
      }
      parser={['me', 'getMe.me']}
      loading={<Layout loading />}
      error={({ error }) => <ErrorPage Layout={Layout} error={error} />}
    >
      {({ me }) => {
        if (!me && authorizedOnly) return <ErrorPage Layout={Layout} preset="unauthorized" />

        if (me && redirectAuthorized) {
          if (redirectAuthorized === true) return <Redirect to={getDashboardRoute()} />
          return <Redirect to={redirectAuthorized} />
        }

        const variablesHere = isFunction(variables) ? variables(withMe ? { me } : {}) : variables

        return (
          <Query
            loading={<Layout loading />}
            error={({ error }) => <ErrorPage Layout={Layout} error={error} me={me} />}
            variables={variablesHere}
            {...restProps}
          >
            {queryResult => {
              const queryResultWithMe = { me, ...queryResult }

              if (checkExists && !checkExists(queryResultWithMe)) return <ErrorPage Layout={Layout} preset="404" />

              if (checkAccess && !checkAccess(queryResultWithMe)) return <ErrorPage Layout={Layout} preset="noperm" />

              return (
                <Layout>{isFunction(children) ? children(withMe ? queryResultWithMe : queryResult) : children}</Layout>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default withRouter(PageWrapper)
