import errorCodes from 'lib/errorCodes'
import get from 'lodash/get'

export default function(error, extraErrorsMap = {}) {
  let errorMessage
  errorMessage = get(
    error,
    'networkError.result.errors[0].stripeError.code',
    'UNKNOWN_ERROR'
  )
  if (errorMessage === 'UNKNOWN_ERROR')
    errorMessage = get(error, 'graphQLErrors[0].message', 'UNKNOWN_ERROR')
  if (errorMessage === 'UNKNOWN_ERROR')
    errorMessage = get(
      error,
      'networkError.result.errors[0].message',
      'UNKNOWN_ERROR'
    )
  if (errorMessage === 'UNKNOWN_ERROR')
  errorMessage = get(
    error,
    'message',
    'UNKNOWN_ERROR'
  )
  const rVal = [
    ...Object.keys(errorCodes),
    ...Object.keys(extraErrorsMap),
  ].filter(i => !!~errorMessage.search(i))[0]

  const errorMessageHuman =
    extraErrorsMap[rVal] ||
    extraErrorsMap[errorMessage] ||
    errorCodes[rVal] ||
    errorCodes[errorMessage] ||
    errorCodes.UNKNOWN_ERROR
  return {
    errorMessage: rVal || errorMessage,
    errorMessageHuman,
    errorMessageFull: errorMessage,
    isUnknown: errorMessageHuman === errorCodes.UNKNOWN_ERROR,
  }
}
