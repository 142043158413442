import React, { useState } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import getUploadUrl from 'utils/getUploadUrl'
import { getJobNewRoute, getJobsRoute, getJobRoute } from 'routes'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import PageWrapper from 'components/PageWrapper'
import CategoryIcon from 'components/CategoryIcon'
import Button from 'components/Button'
import sample from 'lodash/sample'
// import toHumanDate from 'utils/toHumanDate'
import { ReactComponent as PositiveIcon } from 'assets/images/reviews-icons/positive.svg'
import { ReactComponent as NeutralIcon } from 'assets/images/reviews-icons/neutral.svg'
import { ReactComponent as NegativeIcon } from 'assets/images/reviews-icons/negative.svg'
// import { ReactComponent as RightArrowIcon } from 'assets/images/other/arrow-right.svg'
// import triggerImgSrc1 from 'assets/images/landing-owner/triggers/1.png'
// import triggerImgSrc2 from 'assets/images/landing-owner/triggers/2.png'
// import triggerImgSrc3 from 'assets/images/landing-owner/triggers/3.png'
// import triggerImgSrc4 from 'assets/images/landing-owner/triggers/4.png'
// import pinIconSrc from 'assets/images/other/pin-icon.png'
import poundIconSrc from 'assets/images/other/pound-icon.png'
import { ReactComponent as StepIcon1 } from 'assets/images/landing-owner/steps/1.svg'
import { ReactComponent as StepIcon2 } from 'assets/images/landing-owner/steps/2.svg'
import { ReactComponent as StepIcon3 } from 'assets/images/landing-owner/steps/3.svg'
import { ReactComponent as StepIcon4 } from 'assets/images/landing-owner/steps/4.svg'
import { ReactComponent as StepIcon5 } from 'assets/images/landing-owner/steps/5.svg'
import Slider from 'react-slick'
import UserPreview, { userPreviewFragment } from 'components/UserPreview'
import Helmet from 'react-helmet'

const OwnerLandingPage = () => {
  const schemaOrg = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    name: 'SureTask',
    sameAs: 'https://suretask.com',
    logo: 'https://suretask.com/icon-192.png',
  }
  const reviews = [
    {
      image: '/images/promo-reviews/1v2.jpg',
      name: 'James',
      text: 'I was able to find someone to help with my gardening needs so quickly!',
    },
    {
      image: '/images/promo-reviews/2v2.jpg',
      name: 'Joanne',
      text: 'Easy to use and quick response from local professional, who I am very pleased with.',
    },
    {
      image: '/images/promo-reviews/3v2.jpg',
      name: 'Adam',
      text: 'Good platform to grow your business and get clients!',
    },
    {
      image: '/images/promo-reviews/4v2.jpg',
      name: 'Paul',
      text: 'Amazing platform for the clients and professionals.',
    },
    {
      image: '/images/promo-reviews/5v2.jpg',
      name: 'Ananya',
      text: 'Have used Suretast a couple of times and have finally found reliable craftsmen.',
    },
    {
      image: '/images/promo-reviews/6v2.jpg',
      name: 'Karen',
      text: 'I was contacted right away with highly qualified candidates. Fantastic service!',
    },
    {
      image: '/images/promo-reviews/7v2.jpg',
      name: 'Amanda',
      text: 'Efficient way to find highly qualified experts!',
    },
    {
      image: '/images/promo-reviews/8v2.jpg',
      name: 'Helen',
      text: 'I got quick responses.',
    },
    {
      image: '/images/promo-reviews/9v2.jpg',
      name: 'Keisha',
      text: 'The website works really well giving very fast results.',
    },
  ]
  const promoUsers = [
    {
      id: '5d7f93485fec0e1d371e4877',
      imageSrc: '/images/promo-persons/1.jpg',
      name: 'Adam Cole',
      profession: 'Web designer',
      asAssigneePoisitiveReviewsCount: 67,
      asAssigneeNegativeReviewsCount: 0,
      asAssigneeNeutralReviewsCount: 2,
    },
    {
      id: '5d7f93485fec0e1d371e4877',
      imageSrc: '/images/promo-persons/2.jpg',
      name: 'Austin Lloyd',
      profession: 'Corporate Law Attorney',
      asAssigneePoisitiveReviewsCount: 25,
      asAssigneeNegativeReviewsCount: 0,
      asAssigneeNeutralReviewsCount: 0,
    },
    {
      id: '5d7f93485fec0e1d371e4878',
      imageSrc: '/images/promo-persons/3.jpg',
      name: 'Kevin Baker',
      profession: 'DJ',
      asAssigneePoisitiveReviewsCount: 56,
      asAssigneeNegativeReviewsCount: 0,
      asAssigneeNeutralReviewsCount: 2,
    },
    {
      id: '5d7f93485fec0e1d371e4879',
      imageSrc: '/images/promo-persons/4.jpg',
      name: 'Erik Anderson',
      profession: 'Math tutor',
      asAssigneePoisitiveReviewsCount: 42,
      asAssigneeNegativeReviewsCount: 0,
      asAssigneeNeutralReviewsCount: 1,
    },
  ]
  const [promoUser] = useState(sample(promoUsers))
  return (
    <PageWrapper
      query={gql`
        query($input1: GetJobsInput, $input2: GetUserInput!, $input3: GetUsersInput!) {
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
                alias
              }
            }
          }
          getJobs(input: $input1) {
            jobs {
              id
              title
              createdAt
              category {
                id
                title
                categoryGroup {
                  id
                  title
                }
              }
              maxPrice
              averagePrice
              address
              remote
              owner {
                id
                displayName
                avatar
              }
            }
          }
          getUser(input: $input2) {
            user {
              id
              displayName
              asAssigneePoisitiveReviewsCount
              asAssigneeNegativeReviewsCount
              asAssigneeNeutralReviewsCount
            }
          }
          getUsers(input: $input3) {
            users {
              ...UserPreview_user
            }
          }
          getMe {
            me {
              id
            }
          }
        }
        ${userPreviewFragment}
      `}
      variables={{
        input1: {
          limit: 9,
          filter: 'opened',
        },
        input2: {
          userId: promoUser.id,
        },
        input3: {
          limit: 30,
        },
      }}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['jobs', 'getJobs.jobs'],
        ['user', 'getUser.user'],
        ['users', 'getUsers.users'],
        ['me', 'getMe.me'],
      ]}
    >
      {({ categoryGroups, jobs, user, users, me }) => (
        <>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrg) }} />
          <Helmet>
            <meta
              property="og:title"
              content={`SureTask connects you to safe and reliable professionals in your area`}
            />
            <meta property="og:image" content="https://suretask.com/icon-192.png" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://suretask.com" />
          </Helmet>
          <div className={cn(css.header, css.section)}>
            <div className={css.wrapper}>
              <div className={css.intro}>
                <h1 className={css.title}>
                  Do you have a job that needs to be done? Post a free ad and let local professionals help you.
                </h1>
                <Button color="green" shadow to={getJobNewRoute()}>
                  Post a task
                </Button>
              </div>
              <div className={css.person}>
                <div className={css.userInfoPlace}>
                  <div className={css.userInfo}>
                    <div className={css.stat}>
                      <span className={cn(css.item, css.positive)}>
                        <PositiveIcon className={css.icon} width={14} />
                        <span className={css.count}>{promoUser && promoUser.asAssigneePoisitiveReviewsCount}</span>
                      </span>
                      <span className={cn(css.item, css.negative)}>
                        <NegativeIcon className={css.icon} width={14} />
                        <span className={css.count}>{promoUser && promoUser.asAssigneeNegativeReviewsCount}</span>
                      </span>
                      <span className={cn(css.item, css.neutral)}>
                        <NeutralIcon className={css.icon} width={14} />
                        <span className={css.count}>{promoUser && promoUser.asAssigneeNeutralReviewsCount}</span>
                      </span>
                    </div>
                    <div className={css.text}>
                      <b>{promoUser.name},</b> {promoUser.profession}
                    </div>
                  </div>
                </div>
                <img alt="" className={css.image} src={promoUser.imageSrc} />
              </div>
            </div>
          </div>
          <div className={cn(css.categories1, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>Better way to find your local pro</h2>
              <div className={css.categoryGroups}>
                {categoryGroups.map((categoryGroup, i) => (
                  <div key={i} className={css.categoryGroup}>
                    <Link
                      className={css.categoryGroupLink}
                      to={getJobNewRoute({
                        categoryGroupAlias: categoryGroup.alias,
                      })}
                    >
                      <CategoryIcon name={categoryGroup.alias} className={css.categoryGroupIcon} />
                      <span className={css.categoryGroupNamePlace}>
                        <span className={css.categoryGroupName}>{categoryGroup.title}</span>
                      </span>
                    </Link>
                    <CategoriesList categoryGroup={categoryGroup} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/*}<div className={cn(css.categories, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Better way to find your local pro
              </h2>
              <div className={css.cards}>
                {categoryGroups.map((categoryGroup, i) => (
                  <div key={i} className={css.cardPlace}>
                    <Link
                      className={css.card}
                      to={getJobNewRoute({
                        categoryGroupAlias: categoryGroup.alias,
                      })}
                    >
                      <CategoryIcon
                        name={categoryGroup.alias}
                        className={css.icon}
                      />
                      <span className={css.namePlace}>
                        <span className={css.name}>{categoryGroup.title}</span>
                      </span>
                      <span className={css.more}>
                        Post a task{' '}
                        <RightArrowIcon
                          width={7}
                          height={9}
                          className={css.rightArrow}
                        />
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>*/}
          {/*}<div className={cn(css.triggers, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Quick replies, trusted service providers
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc1} className={css.img} />
                  <p className={css.text}>Convenient and secure payment</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc2} className={css.img} />
                  <p className={css.text}>Proven performers</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc3} className={css.img} />
                  <p className={css.text}>Reliable reviews</p>
                </div>
                <div className={css.item}>
                  <img alt="" src={triggerImgSrc4} className={css.img} />
                  <p className={css.text}>Expedient service</p>
                </div>
              </div>
            </div>
          </div>*/}
          {/*}<div className={cn(css.popular, css.section)}>

          </div>*/}
          {/*}<div className={cn(css.jobs, css.section)}>
            <div className={css.wrapper}>
              <div className={css.header}>
                <h2 className={cn(css.sectionTitle, css.title)}>
                  Latest tasks
                </h2>
                <Link className={css.all} to={getJobsRoute()}>
                  Browse all tasks →
                </Link>
              </div>
              <div className={css.cards}>
                {jobs.map((job, i) => (
                  <div key={i} className={css.card} to={getJobNewRoute()}>
                    <div className={css.avatarPlace}>
                      <img
                        alt=""
                        className={css.avatar}
                        src={getUploadUrl(job.owner.avatar, 'avatar')}
                      />
                    </div>
                    <div className={css.content}>
                      {job.category ? (
                        <p className={css.category}>
                          {job.category.categoryGroup.title} —{' '}
                          {job.category.title}
                        </p>
                      ) : (
                        <p className={css.category}>Other</p>
                      )}
                      <h3 className={css.jobTitle}>
                        <Link to={getJobRoute(job.id, job.translitTitle)}>{job.title}</Link>
                      </h3>
                      <p className={css.postedAt}>
                        Posted {toHumanDate(job.createdAt)} by{' '}
                        <Link to={getUserRoute(job.owner.id)}>
                          {job.owner.displayName}
                        </Link>
                      </p>
                      <div className={css.locationAndPrice}>
                        <p className={css.location}>
                          <img
                            width={11}
                            height={15}
                            alt=""
                            src={pinIconSrc}
                            className={css.icon}
                          />{' '}
                          {job.remote ? 'Remote work' : job.address}
                        </p>
                        <p className={css.price}>
                          <img
                            width={11}
                            height={13}
                            alt=""
                            src={poundIconSrc}
                            className={css.icon}
                          />{' '}
                          {job.maxPrice
                            ? `Up to £ ${job.maxPrice}`
                            : job.averagePrice
                            ? `About £ ${job.averagePrice}`
                            : `Contract price`}
                        </p>
                      </div>
                    </div>
                    <div className={css.buttonPlace}>
                      <Button color="green" outline to={getJobRoute(job.id, job.translitTitle)}>
                        Browse Task
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>*/}
          {/*}<div className={cn(css.video, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                How SureTask Works
              </h2>
              <div className={css.videoPlace}>
                <div className={css.videoWrapper}>
                  <iframe
                    className={css.video}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BmlgnZQ2oyQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="How SuraTask Works"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>*/}
          <div className={cn(css.jobs1, css.section)}>
            <div className={css.wrapper}>
              <div className={css.header}>
                <h2 className={cn(css.sectionTitle, css.title)}>Latest tasks</h2>
                <Link className={css.all} to={getJobsRoute()}>
                  Browse all tasks →
                </Link>
              </div>
              <div className={css.cards}>
                {jobs.map((job, i) => (
                  <div key={i} className={css.cardWrapper}>
                    <Link className={css.card} to={getJobRoute(job.id, job.translitTitle)}>
                      {job.category ? (
                        <p className={css.category}>{job.category.title}</p>
                      ) : (
                        <p className={css.category}>Other</p>
                      )}
                      <div className={css.content}>
                        <div className={css.avatarPlace}>
                          <img alt="" className={css.avatar} src={getUploadUrl(job.owner.avatar, 'avatar')} />
                        </div>
                        <h3 className={css.jobTitle}>{job.title}</h3>
                        {(job.maxPrice || job.averagePrice) && (
                          <p className={css.price}>
                            <img width={11} height={13} alt="" src={poundIconSrc} className={css.icon} />{' '}
                            {job.maxPrice || job.averagePrice}
                          </p>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={cn(css.users, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>Service Providers</h2>
              <div className={css.usersWrapper}>
                <div className={css.usersPlace}>
                  {users.map((user, i) => (
                    <UserPreview user={user} key={i} me={me} />
                  ))}
                  {users.map((user, i) => (
                    <UserPreview user={user} key={i + '_1'} me={me} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.reviews, css.v2, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>What our Customers say</h2>
              <div className={css.desktopItems}>
                {reviews.map((review, i) => (
                  <div key={i} className={css.item}>
                    <p className={css.imagePlace}>
                      <img alt="" src={review.image} className={css.image} />
                    </p>
                    <p className={css.name}>{review.name}</p>
                    <p className={css.text}>{review.text}</p>
                  </div>
                ))}
              </div>
              <div className={css.mobileItems}>
                <Slider dots={true}>
                  {reviews.map((review, i) => (
                    <div key={i} className={css.item}>
                      <p className={css.imagePlace}>
                        <img alt="" src={review.image} className={css.image} />
                      </p>
                      <p className={css.name}>{review.name}</p>
                      <p className={css.text}>{review.text}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/*}<div className={cn(css.reviews, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Real People, Real Views
              </h2>
              <div className={css.items}>
                {reviews.map((categoryGroup, i) => (
                  <div key={i} className={css.item}>
                    <div className={css.header}>
                      <div className={css.avatarPlace}>
                        <img
                          alt=""
                          src={getUploadUrl(null, 'avatar')}
                          className={css.avatar}
                        />
                      </div>
                      <div className={css.infoPlace}>
                        <div className={css.fromAndTo}>
                          <p className={css.from}>
                            <span className={css.label}>From:</span>{' '}
                            <span className={css.name}>Jane Doe,</span>{' '}
                            <span className={css.role}>customer</span>
                          </p>
                          <p className={css.to}>
                            <span className={css.label}>To:</span>{' '}
                            <span className={css.name}>John Doe,</span>{' '}
                            <span className={css.role}>executive</span>
                          </p>
                        </div>
                        <div className={cn(css.mark, css.positive)}>
                          <div className={css.icons}>
                            <PositiveIcon
                              className={cn(css.icon, css.positive)}
                              width={14}
                              height={14}
                            />
                            <NegativeIcon
                              className={cn(css.icon, css.negative)}
                              width={14}
                              height={14}
                            />
                            <NeutralIcon
                              className={cn(css.icon, css.neutral)}
                              width={14}
                              height={14}
                            />
                          </div>
                          <p className={css.text}>Good feedback</p>
                        </div>
                      </div>
                    </div>
                    <div className={css.reviewBody}>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>*/}
          <div className={cn(css.steps, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>How it works</h2>
              <div className={css.items}>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>1</p>
                    <StepIcon1 className={css.icon} />
                  </div>
                  <p className={css.text}>Post a task</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>2</p>
                    <StepIcon2 className={css.icon} />
                  </div>
                  <p className={css.text}>Receive bids from service providers</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>3</p>
                    <StepIcon3 className={css.icon} />
                  </div>
                  <p className={css.text}>Choose a service provider that suits you</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>4</p>
                    <StepIcon4 className={css.icon} />
                  </div>
                  <p className={css.text}>Agree to work together</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>5</p>
                    <StepIcon5 className={css.icon} />
                  </div>
                  <p className={css.text}>Rate the service provider</p>
                </div>
              </div>
              <div className={css.videoPlace}>
                <div className={css.videoWrapper}>
                  <iframe
                    className={css.video}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BmlgnZQ2oyQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="How SureTask Works"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.cta, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>Save your time with SureTask</h2>
              <Button color="green" shadow to={getJobNewRoute()}>
                Post a task
              </Button>
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  )
}

const CategoriesList = ({ categoryGroup }) => {
  const collapsable = categoryGroup.categories.length > 5
  const [collapsed, setCollapsed] = useState(collapsable)
  const categoriesHere = collapsed ? categoryGroup.categories.slice(0, 5) : categoryGroup.categories
  return (
    <div className={css.categoriesList}>
      {categoriesHere.map((category, i) => (
        <div className={css.categoryLinkPlace} key={i}>
          <Link
            to={getJobNewRoute({
              categoryGroupAlias: categoryGroup.alias,
              categoryAlias: category.alias,
            })}
          >
            {category.title}
          </Link>
        </div>
      ))}
      {collapsed && (
        <div className={css.morePlace}>
          <a
            onClick={e => {
              e.preventDefault()
              setCollapsed(false)
            }}
            href="#null"
            className={css.more}
          >
            Show more
          </a>
        </div>
      )}
    </div>
  )
}

export default OwnerLandingPage
