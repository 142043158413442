import React from 'react'
import Header from 'components/Header'
import cn from 'classnames'
import css from './styles.module.scss'

const ChatLayout = ({ leftCol, rightCol, chatId }) => {
  return (
    <div className={cn({ [css.layout]: true, [css.withChat]: !!chatId })}>
      <div className={css.header}>
        <Header />
      </div>
      <div className={cn(css.cols)}>
        <div className={css.leftCol}>{leftCol}</div>
        <div className={css.rightCol}>{rightCol}</div>
      </div>
    </div>
  )
}

export default ChatLayout
