import React from 'react'
import Form from 'components/Form'
import Alert from 'components/Alert'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import Hit from 'components/Hit'
import gql from 'graphql-tag'
import { track, setUserData } from 'utils/mixpanel'
import { useMutation } from '@apollo/react-hooks'

const UnsubscribeEmailPage = ({ match }) => {
  const [unsubscribeEmail] = useMutation(gql`
    mutation($input: UnsubscribeEmailInput!) {
      unsubscribeEmail(input: $input) {
        user {
          id
          acceptNotifications
        }
      }
    }
  `)
  return (
    <PageWrapper>
      <PageSegment title="Unsubscribe Email">
        <Form
          onSubmit={input =>
            unsubscribeEmail({
              variables: {
                input: {
                  token: input.token,
                  type: input.type,
                },
              },
            }).then(({ data }) => {
              console.log(data)
              track('Unsubscribe email', {
                'Accepted notifications':
                  data.unsubscribeEmail.user.acceptNotifications,
              })
              setUserData({
                'Accepted notifications':
                  data.unsubscribeEmail.user.acceptNotifications,
              })
            })
          }
          initialValues={{
            token: match.params.token,
            type: match.params.type,
          }}
          successMessage="Success"
        >
          {({ alertExists, alertProps, handleSubmit }) => {
            return (
              <>
                <Hit action={handleSubmit} />
                {alertExists && <Alert {...alertProps} />}
              </>
            )
          }}
        </Form>
      </PageSegment>
    </PageWrapper>
  )
}

export default UnsubscribeEmailPage
