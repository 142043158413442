export default function propsPreset(props = {}, presets = {}) {
  let preset = props.preset
  let allPresetsProps = {}
  while (preset) {
    const presetProps = presets[preset] || {}
    allPresetsProps = { ...presetProps, ...allPresetsProps }
    preset = presetProps.preset
  }
  return { ...allPresetsProps, ...props }
}
