import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import { createValidator, required } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Textarea from 'components/Textarea'
import { track } from 'utils/mixpanel'

const EditComment = ({ comment, ...restProps }) => {
  const [updateComment] = useMutation(gql`
    mutation($input: UpdateCommentInput!) {
      updateComment(input: $input) {
        comment {
          id
          text
        }
      }
    }
  `)
  return (
    <Form
      validate={createValidator({
        text: [required],
      })}
      onSubmit={({ text, price }) =>
        updateComment({
          variables: {
            input: {
              text,
              commentId: comment.id,
            },
          },
        }).then(() => {
          track('Update comment')
        })
      }
      initialValues={{
        text: comment.text,
      }}
      successMessage="Comment updated"
      withPristine
      {...restProps}
    >
      {({ alertExists, alertProps, buttonProps }) => {
        return (
          <>
            <Textarea label="Edit comment" name="text" />
            {alertExists && <Alert {...alertProps} />}
            <Buttons>
              <Button {...buttonProps}>Save Changes</Button>
            </Buttons>
          </>
        )
      }}
    </Form>
  )
}

export default EditComment
