import React from 'react'
import PageWrapper from 'components/PageWrapper'
import DashboardLayout from 'components/DashboardLayout'
import PageSegment from 'components/PageSegment'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import getJobStatus, { jobStatusFragment } from 'utils/getJobStatus'
import JobPreview, { jobWithBidPreviewFragment } from 'components/JobPreview'
import Link from 'components/Link'
import Button, { Buttons } from 'components/Button'
import { getJobsRoute } from 'routes'

const MyBidsPage = ({ match, history }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      authorizedOnly
      query={gql`
        query MyBids($input: GetMyBidsInput) {
          getMyBids(input: $input) {
            bids {
              ...JobWithBidPreview_bid
              job {
                ...JobStatus_job
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
        ${jobStatusFragment}
        ${jobWithBidPreviewFragment}
      `}
      parser={[['bids', 'getMyBids.bids'], ['pageInfo', 'getMyBids.pageInfo']]}
      variables={{ input: { limit: 30, filter: match.params.filter } }}
      fetchPolicy="network-only"
      withMe
    >
      {({ bids, pageInfo, fetchMore, me }) => (
        <PageSegment title="Bids">
          <div style={{ marginBottom: 35 }}>
            <Buttons style={{ marginBottom: 4 }}>
              <Link
                type="dashed"
                selected={match.params.filter === 'all'}
                onClick={e => {
                  e.preventDefault()
                  history.push('all')
                }}
              >
                All
              </Link>
              <Link
                type="dashed"
                selected={match.params.filter === 'in-progress'}
                onClick={e => {
                  e.preventDefault()
                  history.push('in-progress')
                }}
              >
                In progress
              </Link>
              <Link
                type="dashed"
                selected={match.params.filter === 'active'}
                onClick={e => {
                  e.preventDefault()
                  history.push('active')
                }}
              >
                Active
              </Link>
              <Link
                type="dashed"
                selected={match.params.filter === 'completed'}
                onClick={e => {
                  e.preventDefault()
                  history.push('completed')
                }}
              >
                Completed
              </Link>
              <Link
                type="dashed"
                selected={match.params.filter === 'archived'}
                onClick={e => {
                  e.preventDefault()
                  history.push('archived')
                }}
              >
                Archived
              </Link>
            </Buttons>
            {match.params.filter === 'all' && <p>All your submitted bids.</p>}
            {match.params.filter === 'in-progress' && (
              <p>Bids where service provider is not yet selected.</p>
            )}
            {match.params.filter === 'active' && (
              <p>
                Bids where you are service provider of you were offered to
                become one.
              </p>
            )}
            {match.params.filter === 'completed' && (
              <p>Bids for completed tasks as selected service provider.</p>
            )}
            {match.params.filter === 'archived' && (
              <p>
                Bids for completed tasks where customer selected other service
                provider.
              </p>
            )}
          </div>
          {!bids.length ? (
            match.params.filter === 'all' ? (
              <>
                <Alert
                  message={
                    "Can't find a matching customer's task? Let's fix that!"
                  }
                />
                <Button to={getJobsRoute()}>Browse Tasks</Button>
              </>
            ) : (
              <Alert message="No bids found for this filter." />
            )
          ) : (
            <InfiniteScroll
              loadMore={async () => {
                fetchMore({
                  variables: {
                    input: {
                      cursor: pageInfo.endCursor,
                      limit: 10,
                      filter: match.params.filter,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      getMyBids: {
                        ...fetchMoreResult.getMyBids,
                        bids: [
                          ...prev.getMyBids.bids,
                          ...fetchMoreResult.getMyBids.bids,
                        ],
                      },
                    }
                  },
                })
              }}
              hasMore={pageInfo.hasNextPage}
              loader={<div className="loader" key={0} />}
            >
              {bids.map((bid, i) => (
                <JobPreview
                  key={i}
                  job={bid.job}
                  status={getJobStatus(bid.job, me)}
                  bid={bid}
                />
              ))}
            </InfiniteScroll>
          )}
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default MyBidsPage
