import React from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import useGlobalState from 'lib/globalState'
import { withRouter } from 'react-router'
import { getSignInRoute } from 'routes'
import {
  createValidator,
  required,
  email,
  confirmPassword,
} from 'lib/validation'
import gql from 'graphql-tag'
import Button, { Buttons } from 'components/Button'
import cookieStore from 'lib/cookieStore'
import SocialButtons from 'components/SocialButtons'
import SmallSegment from 'components/SmallSegment'
import get from 'lodash/get'
import Alert from 'components/Alert'
import getAbsUrl from 'utils/getAbsUrl'
import {
  setUserData,
  aliasUser,
  track,
  fragments as mixpanelFragments,
} from 'utils/mixpanel'

const SignUpForm = ({
  onSuccess,
  onSuccessRoute,
  oauthSuccessUrl,
  history,
  hideSocials,
  hideLogin,
}) => {
  const dispatchGlobalAction = useGlobalState()[1]
  const apolloClient = useApolloClient()
  const [signUp] = useMutation(gql`
    mutation($input: SignUpInput!) {
      signUp(input: $input) {
        token
        user {
          id
          email
          ...Mixpanel_user
        }
      }
    }
    ${mixpanelFragments.user}
  `)
  return (
    <>
      <SmallSegment>
        <Form
          validate={createValidator({
            email: [required, email],
            password: [required],
            confirmPassword: [required, confirmPassword()],
          })}
          onSubmit={({ email, password }) =>
            signUp({ variables: { input: { email, password } } })
          }
          onSuccess={data => {
            const token = get(data, 'signUp.token', '')
            const user = get(data, 'signUp.user', {})
            aliasUser(user.id)
            track('Sign up', { Method: 'email' })
            setUserData({ user })
            dispatchGlobalAction({
              type: 'showAfterSignUpModal',
            })
            if (token) {
              cookieStore.set('token', token)
              apolloClient.resetStore().then(() => {
                onSuccess && onSuccess()
                onSuccessRoute && history.push(onSuccessRoute)
              })
            }
          }}
          hideSuccessAlert
        >
          {({ alertExists, alertProps, buttonProps }) => {
            return (
              <>
                <Input label="E-mail" name="email" type="email" />
                <Input label="Password" name="password" type="password" />
                <Input
                  label="Repeat password"
                  name="confirmPassword"
                  type="password"
                />
                {alertExists && <Alert {...alertProps} />}
                <Buttons>
                  <Button {...buttonProps}>Sign Up</Button>
                  {!hideLogin && (
                    <Button outline to={getSignInRoute()}>
                      Log In
                    </Button>
                  )}
                </Buttons>
              </>
            )
          }}
        </Form>
      </SmallSegment>
      {!hideSocials && (
        <SmallSegment
          titleType="noLine"
          titleSize={3}
          title="Or use social network"
        >
          <SocialButtons
            successUrl={oauthSuccessUrl || getAbsUrl(onSuccessRoute)}
          />
        </SmallSegment>
      )}
    </>
  )
}

export default withRouter(SignUpForm)
