import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import Wrapper from 'components/Wrapper'
import Title from 'components/Title'
import Paper from 'components/Paper'

const PageSegment = ({
  title,
  titleTag = 'h1',
  titleSize = 1,
  titleAlign = 'center',
  titleType = 'shortLine',
  children,
  withPaper = true,
  className,
  hideBody,
  ...restProps
}) => {
  return (
    <Wrapper
      className={cn({ [css.pageSegment]: true, [className]: !!className })}
      {...restProps}
    >
      <Title
        className={css.title}
        tag={titleTag}
        size={titleSize}
        align={titleAlign}
        type={titleType}
        {...restProps}
      >
        {title}
      </Title>
      {!hideBody &&
        (withPaper ? (
          <Paper className={css.content}>{children}</Paper>
        ) : (
          <div className={css.content}>{children}</div>
        ))}
    </Wrapper>
  )
}

export default PageSegment
