export default function plural(number, titles, returnCountValue) {
  const cases = [2, 0, 1, 1, 1, 2]
  const formula =
    titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  return returnCountValue ? `${number} ${formula}` : formula
}
// plural(minutes, ['минута', 'минуты', 'минут'])
// 1 минута, 2 минуты, ..., 5 минут
