import React from 'react'
import cn from 'classnames'
import { Field } from 'react-final-form'
import css from './styles.module.scss'
import ReactTagsInput from 'react-tagsinput'

const TagsInput = ({
  label,
  hint,
  hideError,
  autoComplete = false,
  maxWidth,
  ...restProps
}) => (
  <Field {...restProps}>
    {({ input, meta, placeholder = 'Add a tag', ...restFieldProps }) => {
      const isInvalid = (meta.error || meta.submitError) && meta.submitFailed
      return (
        <div className="field" style={{ maxWidth }}>
          {label && (
            <label className="label" htmlFor={restFieldProps.id || input.name}>
              {label}
            </label>
          )}
          <div className="control">
            <ReactTagsInput
              className={css.tagsInput}
              tagProps={{
                className: css.tag,
                classNameRemove: css.remove,
              }}
              focusedClassName={css.focused}
              inputProps={{
                className: css.input,
                placeholder,
              }}
              {...input}
              {...restFieldProps}
            />
            {isInvalid && !hideError && (
              <p
                className={cn({
                  help: true,
                  'is-danger': isInvalid,
                })}
              >
                {meta.error || meta.submitError}
              </p>
            )}
            {!!hint && <p className={'help'}>{hint}</p>}
          </div>
        </div>
      )
    }}
  </Field>
)

export default TagsInput
