import stringifyQueryObject from 'utils/stringifyQueryObject'

export const getHomeRoute = () => '/'
export const getSignInRoute = () => '/sign-in'
export const getSignUpRoute = () => '/sign-up'
export const getDashboardRoute = () => '/dashboard'
export const getRestorePasswordRoute = () => '/restore-password'
export const getConfirmEmailRoute = confirmEmailToken =>
  `/confirm-email/${confirmEmailToken}`
export const getConfirmNewEmailRoute = confirmEmailToken =>
  `/confirm-new-email/${confirmEmailToken}`
export const getResetPasswordRoute = passwordResetToken =>
  `/reset-password/${passwordResetToken}`
export const getJobsRoute = q => '/jobs' + stringifyQueryObject(q)
export const getBidsRoute = () => '/bids'
export const getCommentsRoute = () => '/comments'
export const getVacancyRoute = (cityAlias, vacancyAlias) => `/vacancies/${cityAlias}/${vacancyAlias}`
export const getJobRoute = (jobId, jobTranslitTitle) => `/jobs/${jobTranslitTitle || 'job'}-${jobId}`
export const getJobNewRoute = q => '/jobs/new' + stringifyQueryObject(q) // { categoryGroupAlias, sourceJobId }
export const getJobEditRoute = jobId => `/jobs/${jobId}/edit`
export const getMyProfileRoute = () => `/my-profile`
export const getMyChatsRoute = () => `/my-chats`
export const getMyChatRoute = chatId => `/my-chats/${chatId}`
export const getMyJobsRoute = filter => `/my-jobs/${filter}`
export const getMyBidsRoute = filter => `/my-bids/${filter}`
export const getUserRoute = userId => `/users/${userId}`
export const getMyBalanceRoute = () => `/my-balance`
export const getMyReviewsRoute = () => `/my-reviews`
export const getOwnerLandingRoute = () => `/landing/owner`
export const getAssigneeLandingRoute = () => `/landing/assignee`
export const getAssigneeLanding1Route = () => `/landing/providers`
export const getCategoriesRoute = () => `/categories`
export const getCategoryGroupRoute = categoryGroupAlias =>
  `/categories/${categoryGroupAlias}`
export const getCategoryRoute = (categoryGroupAlias, categoryAlias) =>
  `/categories/${categoryGroupAlias}/${categoryAlias}`
export const getTagRoute = tagAlias => `/tag/${tagAlias}`
export const getUnsubscribeEmailRoute = (token, type) =>
  `/unsubscribe/${token}/${type}`
export const getPolicyRoute = () => '/privacy-policy'
export const getServiceProviderFormRoute = q =>
  '/service-provider-form' + stringifyQueryObject(q)
export const getUsersRoute = q => '/users' + stringifyQueryObject(q)
