import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import Bid from 'components/Bid'
import BlockBid from 'components/BlockBid'
import DashboardLayout from 'components/DashboardLayout'
import { canBlockBid } from 'utils/can'

const BidsPage = ({ match, history }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      authorizedOnly
      query={gql`
        query Bids($input: GetBidsInput) {
          getBids(input: $input) {
            bids {
              id
              text
              price
              createdAt
              blocked
              blockComment
              owner {
                id
                displayName
                avatar
                asAssigneePoisitiveReviewsCount
                asAssigneeNegativeReviewsCount
                asAssigneeNeutralReviewsCount
                contacts {
                  email
                  phone
                  instagram
                  facebook
                  website
                }
              }
              job {
                id
                translitTitle
                title
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `}
      parser={[['bids', 'getBids.bids'], ['pageInfo', 'getBids.pageInfo']]}
      variables={{ input: { limit: 30 } }}
      fetchPolicy="network-only"
      checkAccess={({ me }) => canBlockBid(me)}
      withMe
    >
      {({ bids, pageInfo, fetchMore }) => (
        <PageSegment title="All Bids" titleAlign="left" withPaper={false}>
          {!bids.length ? (
            <Alert message="No bids found for this filter." />
          ) : (
            <InfiniteScroll
              loadMore={async () => {
                fetchMore({
                  variables: {
                    input: {
                      cursor: pageInfo.endCursor,
                      limit: 10,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      getBids: {
                        ...fetchMoreResult.getBids,
                        bids: [
                          ...prev.getBids.bids,
                          ...fetchMoreResult.getBids.bids,
                        ],
                      },
                    }
                  },
                })
              }}
              hasMore={pageInfo.hasNextPage}
              loader={<div className="loader" key={0} />}
            >
              {bids.map((bid, i) => (
                <Bid key={i} bid={bid}>
                  <BlockBid bid={bid} />
                </Bid>
              ))}
            </InfiniteScroll>
          )}
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default BidsPage
