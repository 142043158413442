import React from 'react'
import './styles/general.scss'
import AfterSignUpModal from 'components/AfterSignUpModal'
import DocumentTitle from 'react-document-title'
import SuccessEmailConfirmationModal from 'components/SuccessEmailConfirmationModal'
import PushAccessModal from 'components/PushAccessModal'
import JobsPage from 'pages/JobsPage'
import JobPage from 'pages/JobPage'
import JobNewPage from 'pages/JobNewPage'
import JobEditPage from 'pages/JobEditPage'
import SignUpPage from 'pages/SignUpPage'
import SignInPage from 'pages/SignInPage'
import RestorePasswordPage from 'pages/RestorePasswordPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import MyProfilePage from 'pages/MyProfilePage'
import MyJobsPage from 'pages/MyJobsPage'
import MyBidsPage from 'pages/MyBidsPage'
import UserPage from 'pages/UserPage'
import DashboardPage from 'pages/DashboardPage'
import BidsPage from 'pages/BidsPage'
import CommentsPage from 'pages/CommentsPage'
import UsersPage from 'pages/UsersPage'
import ConfirmEmailPage from 'pages/ConfirmEmailPage'
import ErrorPage from 'pages/ErrorPage'
import ServiceProviderFormPage from 'pages/ServiceProviderFormPage'
import { CategoriesPage, CategoryPage, TagPage, CategoryGroupPage } from 'pages/SeoLandingPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as routes from 'routes'
import { GlobalStateProvider } from 'lib/globalState'
import cookieStore from 'lib/cookieStore'
import Query from 'components/Query'
import AcceptCookies from 'components/AcceptCookies'
import Hit from 'components/Hit'
import { initialState, reducer } from './store'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import BalancePage from 'pages/BalancePage'
import MyReviewsPage from 'pages/MyReviewsPage'
import MyChatsPage from 'pages/MyChatsPage'
import OwnerLandingPage from 'pages/OwnerLandingPage'
import VacancyLandingPage from 'pages/VacancyLandingPage'
import AssigneeLandingPage from 'pages/AssigneeLandingPage'
import AssigneeLandingPage1 from 'pages/AssigneeLandingPage1'
import UnsubscribeEmailPage from './pages/UnsubscribeEmailPage'
import PolicyPage from './pages/PolicyPage'
import ReactGA from 'react-ga'
import { YMInitializer } from 'react-yandex-metrika'
import Page404 from 'pages/Page404'
import PageSegment from './components/PageSegment'

if (process.env.REACT_APP_GOOGLE_TAG) ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG)

const request = async operation => {
  const token = cookieStore.get('token')
  operation.setContext({
    headers: {
      ...(token && { authorization: `Bearer ${token}` }),
    },
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const apolloClient = new ApolloClient({
  name: 'react-web-client',
  version: '1.0',
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (networkError.bodyText === 'Unauthorized') {
          cookieStore.remove('token')
          apolloClient.resetStore()
        }
      }
    }),
    requestLink,
    createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_URI }),
  ]),
  cache: new InMemoryCache(),
})

function App() {
  return (
    <DocumentTitle title="SureTask connects you to safe and reliable professionals in your area">
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <GlobalStateProvider initialState={initialState} reducer={reducer}>
            <Query withoutLoader preset="me">
              {({ me }) => (
                <Hit
                  args={{ id: me && me.id, canBeAssignee: me && me.canBeAssignee }}
                  action={() => {
                    if (process.env.REACT_APP_INTERCOM_APP_ID) {
                      window.Intercom('boot', {
                        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                        ...(me
                          ? {
                              name: me.name,
                              email: me.email,
                              user_id: me.id,
                            }
                          : {}),
                      })
                    }
                    if (process.env.REACT_APP_DASHLY_ON === 'true' && me) {
                      window.dashly.auth(me.id, me.dashlyHash)
                      const identifyObj = {}
                      if (me.name) identifyObj.$name = me.name
                      if (me.email) identifyObj.$email = me.email
                      identifyObj.serviceProvider = !!me.canBeAssignee
                      window.dashly.identify(identifyObj)
                    }
                  }}
                />
              )}
            </Query>
            <Router>
              <Switch>
                <Route exact path={routes.getHomeRoute()} component={OwnerLandingPage} />
                <Route exact path={routes.getOwnerLandingRoute()} component={OwnerLandingPage} />
                <Route exact path={routes.getAssigneeLandingRoute()} component={AssigneeLandingPage} />
                <Route exact path={routes.getAssigneeLanding1Route()} component={AssigneeLandingPage1} />
                <Route
                  exact
                  path={routes.getVacancyRoute(':cityAlias', ':vacancyAlias')}
                  component={VacancyLandingPage}
                />
                <Route exact path={routes.getCategoriesRoute()} component={CategoriesPage} />
                <Route exact path={routes.getCategoryGroupRoute(':categoryGroupAlias')} component={CategoryGroupPage} />
                <Route
                  exact
                  path={routes.getCategoryRoute(':categoryGroupAlias', ':categoryAlias')}
                  component={CategoryPage}
                />
                <Route exact path={routes.getTagRoute(':tagAlias')} component={TagPage} />
                <Route exact path={routes.getJobsRoute()} component={JobsPage} />
                <Route exact path={routes.getJobNewRoute()} component={JobNewPage} />
                <Route exact path={routes.getJobEditRoute(':jobId')} component={JobEditPage} />
                <Route exact path={'/jobs/:jobIdAndTitle'} component={JobPage} />
                <Route exact path={routes.getSignUpRoute()} component={SignUpPage} />
                <Route exact path={routes.getSignInRoute()} component={SignInPage} />
                <Route exact path={routes.getRestorePasswordRoute()} component={RestorePasswordPage} />
                <Route exact path={routes.getResetPasswordRoute(':passwordResetToken')} component={ResetPasswordPage} />
                <Route exact path={routes.getMyProfileRoute()} component={MyProfilePage} />
                <Route exact path={routes.getMyJobsRoute(':filter')} component={MyJobsPage} />
                <Route exact path={routes.getMyBidsRoute(':filter')} component={MyBidsPage} />
                <Route exact path={routes.getMyBalanceRoute()} component={BalancePage} />
                <Route exact path={routes.getMyChatsRoute()} component={MyChatsPage} />
                <Route exact path={routes.getMyChatRoute(':chatId')} component={MyChatsPage} />
                <Route exact path={routes.getMyReviewsRoute()} component={MyReviewsPage} />
                <Route exact path={routes.getBidsRoute()} component={BidsPage} />
                <Route exact path={routes.getCommentsRoute()} component={CommentsPage} />
                <Route exact path={routes.getUserRoute(':userId')} component={UserPage} />
                <Route
                  exact
                  path={routes.getConfirmEmailRoute(':confirmEmailToken')}
                  render={props => <ConfirmEmailPage isNewEmail={false} {...props} />}
                />
                <Route
                  exact
                  path={routes.getConfirmNewEmailRoute(':confirmEmailToken')}
                  render={props => <ConfirmEmailPage isNewEmail={true} {...props} />}
                />
                <Route
                  exact
                  path={routes.getUnsubscribeEmailRoute(':token', ':type')}
                  render={props => <UnsubscribeEmailPage {...props} />}
                />
                <Route exact path={routes.getPolicyRoute()} component={PolicyPage} />
                <Route exact path={routes.getServiceProviderFormRoute()} component={ServiceProviderFormPage} />
                <Route exact path={routes.getUsersRoute()} component={UsersPage} />
                <Route exact path={routes.getDashboardRoute()} component={DashboardPage} />
                <Route render={props => <Page404 {...props} />} />
              </Switch>
            </Router>
            <AfterSignUpModal />
            <PushAccessModal />
            <SuccessEmailConfirmationModal />
            <AcceptCookies />
            {process.env.REACT_APP_YM_ID && (
              <YMInitializer
                accounts={[+process.env.REACT_APP_YM_ID]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                }}
              />
            )}
          </GlobalStateProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </DocumentTitle>
  )
}

export default App
