import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import toHumanDate from 'utils/toHumanDate'
import getUploadUrl from 'utils/getUploadUrl'
import { Link } from 'react-router-dom'
import PrettyText from 'components/PrettyText'
import Alert from 'components/Alert'
import { getUserRoute, getJobRoute } from 'routes'
import { canBlockBid } from 'utils/can'

const Comment = ({
  comment,
  canAnswer,
  className,
  children,
  me,
  answerForm,
  ...restProps
}) => {
  return (
    <div
      className={cn({
        [css.commentWithChildren]: true,
        [className]: !!className,
      })}
      {...restProps}
    >
      <div className={css.comment}>
        <div className={css.owner}>
          <img
            className={css.avatar}
            alt=""
            src={getUploadUrl(comment.owner.avatar, 'avatar')}
          />
          <div className={css.about}>
            <p className={css.name}>
              <Link className={css.link} to={getUserRoute(comment.owner.id)}>
                {comment.owner.displayName}
              </Link>
              , {toHumanDate(comment.createdAt)}
              {comment.job && (
                <>
                  ,{' '}
                  <Link to={getJobRoute(comment.job.id, comment.job.translitTitle)}>
                    {comment.job.title}
                  </Link>
                </>
              )}
            </p>
          </div>
        </div>
        {comment.blocked && (
          <div className={css.content}>
            <Alert
              message={
                <>
                  Comment has been blocked:{' '}
                  {comment.blockComment ? comment.blockComment : ''}
                </>
              }
            />
          </div>
        )}
        {(!comment.blocked || canBlockBid(me)) && (
          <PrettyText className={css.content}>{comment.text}</PrettyText>
        )}
        {!!children && <div className={css.children}>{children}</div>}
      </div>
      {comment.comments && !!comment.comments.length && (
        <div className={css.comments}>
          {comment.comments.map((subComment, i) => (
            <Comment me={me} comment={subComment} key={i} />
          ))}
        </div>
      )}
      {!!answerForm && canAnswer && (
        <div className={css.answerForm}>{answerForm}</div>
      )}
    </div>
  )
}

export default Comment
