import React from 'react'
import Form from 'components/Form'
import Textarea from 'components/Textarea'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Query from 'components/Query'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { FieldArray } from 'react-final-form-arrays'
import css from './styles.module.scss'
import { ReactComponent as CrossIcon } from 'assets/images/other/cross-icon.svg'
import { track, setUserData } from 'utils/mixpanel'

const MyBidsTemplatesEditor = () => {
  const [updateMyBidTemplates] = useMutation(gql`
    mutation($input: UpdateMyBidTemplatesInput!) {
      updateMyBidTemplates(input: $input) {
        me {
          id
          bidTemplates
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              bidTemplates
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : (
          <Form
            onSubmit={({ bidTemplates }) =>
              updateMyBidTemplates({
                variables: {
                  input: { bidTemplates },
                },
              }).then(({ data }) => {
                track('User update bid templates')
                setUserData({
                  'Bid templates count':
                    data.updateMyBidTemplates.me.bidTemplates.length,
                })
              })
            }
            initialValues={{
              bidTemplates: me.bidTemplates.length ? me.bidTemplates : [''],
            }}
            resetOnSuccess
            successMessage="Bid templates changed successfully"
          >
            {({ alertExists, alertProps, buttonProps, values }) => {
              return (
                <>
                  <FieldArray name="bidTemplates">
                    {({ fields }) => (
                      <>
                        {fields.map((name, index) => (
                          <div className={css.bidTemplate} key={name}>
                            <Textarea
                              inputClassName={css.textarea}
                              name={name}
                            />
                            <Button
                              className={css.remove}
                              square
                              color="red"
                              onClick={e => {
                                fields.remove(index)
                              }}
                            >
                              <CrossIcon className={css.cross} />
                            </Button>
                          </div>
                        ))}
                        <div className="field">
                          <Button
                            outline
                            type="button"
                            onClick={() => fields.push('')}
                          >
                            Add Template
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                  {alertExists && <Alert {...alertProps} />}
                  <Buttons>
                    <Button {...buttonProps}>Save Templates</Button>
                  </Buttons>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default MyBidsTemplatesEditor
