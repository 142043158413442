import React from 'react'
import cn from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import css from './styles.module.scss'

const Link = ({
  to,
  href,
  className,
  disabled,
  selected,
  bold,
  nowrap,
  type = 'solid',
  ...etcProps
}) => {
  const classNameHere = cn({
    [css.link]: true,
    [css.disabled]: disabled,
    [css.bold]: bold,
    [css.selected]: selected,
    [css.nowrap]: nowrap,
    [css[`type-${type}`]]: true,
    [className]: !!className,
  })
  return to ? (
    <RouterLink to={to} className={classNameHere} {...etcProps} />
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} className={classNameHere} {...etcProps} />
  )
}

export default Link
