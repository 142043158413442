import React, { forwardRef } from 'react'
import cn from 'classnames'
import { Field } from 'react-final-form'

const Input = forwardRef(
  (
    {
      label,
      hint,
      hideError,
      autoComplete = false,
      maxWidth,
      validateOnTouch,
      ...restProps
    },
    ref
  ) => (
    <Field {...restProps}>
      {({ input, meta, placeholder, ...restFieldProps }) => {
        const isInvalid = validateOnTouch
          ? (meta.error || meta.submitError) && meta.touched
          : (meta.error || meta.submitError) && meta.submitFailed
        return (
          <div className="field" style={{ maxWidth }}>
            {label && (
              <label
                className="label"
                htmlFor={restFieldProps.id || input.name}
              >
                {label}
              </label>
            )}
            <div className="control">
              <input
                {...input}
                {...restFieldProps}
                ref={ref}
                placeholder={placeholder}
                className={cn({
                  input: true,
                  'is-danger': isInvalid,
                })}
                autoComplete={autoComplete ? 'on' : 'off'}
              />
              {isInvalid && !hideError && (
                <p
                  className={cn({
                    help: true,
                    'is-danger': isInvalid,
                  })}
                >
                  {meta.error || meta.submitError}
                </p>
              )}
              {!!hint && <p className={'help'}>{hint}</p>}
            </div>
          </div>
        )
      }}
    </Field>
  )
)

export default Input
