import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import { createValidator, required } from 'lib/validation'
import Button from 'components/Button'
import Segment from 'components/Segment'
import Alert from 'components/Alert'

const BlockJob = ({ jobId }) => {
  const [blockJob] = useMutation(gql`
    mutation($input: BlockJobInput!) {
      blockJob(input: $input) {
        job {
          id
          translitTitle
          blocked
          blockComment
        }
      }
    }
  `)
  const [unblockJob] = useMutation(gql`
    mutation($input: UnblockJobInput!) {
      unblockJob(input: $input) {
        job {
          id
          translitTitle
          blocked
          blockComment
        }
      }
    }
  `)
  return (
    <Query
      query={gql`
        query($input: GetJobInput!) {
          getMe {
            me {
              id
            }
          }
          getJob(input: $input) {
            job {
              id
              translitTitle
              blocked
              blockComment
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me'], ['job', 'getJob.job']]}
      variables={{ input: { jobId: jobId } }}
    >
      {({ me, job }) =>
        !me ? null : (
          <Segment title="Block this job">
            {!job.blocked && (
              <Form
                validate={createValidator({
                  blockComment: [required],
                })}
                onSubmit={input =>
                  blockJob({
                    variables: {
                      input: {
                        blockComment: input.blockComment || null,
                        jobId: jobId,
                      },
                    },
                  })
                }
                withPristine
                resetOnSuccess
                successMessage="Success"
              >
                {({ alertExists, alertProps, buttonProps, forgetSuccess }) => {
                  return (
                    <>
                      <Input label="Reason" name="blockComment" />
                      {alertExists && <Alert {...alertProps} />}
                      <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                          <Button {...buttonProps}>Block job</Button>
                        </div>
                      </div>
                    </>
                  )
                }}
              </Form>
            )}
            {job.blocked && (
              <div className="field is-grouped is-grouped-multiline">
                <div className="control">
                  <Button
                    onClick={() =>
                      unblockJob({
                        variables: {
                          input: {
                            jobId: jobId,
                          },
                        },
                      })
                    }
                  >
                    Unblock job
                  </Button>
                </div>
              </div>
            )}
          </Segment>
        )
      }
    </Query>
  )
}

export default BlockJob
