import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Select from 'components/Select'
import Segment from 'components/Segment'
import Alert from 'components/Alert'
import Textarea from 'components/Textarea'
import Query from 'components/Query'
import { createValidator, required } from 'lib/validation'
import { getJobNewRoute } from 'routes'
import { track } from 'utils/mixpanel'

const JobInvationModal = ({ isJobInvationModalOpen, setJobInvationModalOpen, userId, userName }) => {
  const [createJobInvite] = useMutation(gql`
    mutation($input: CreateJobInviteInput!) {
      createJobInvite(input: $input) {
        invite {
          id
          text
          jobId
          recipientId
        }
      }
    }
  `)
  return (
    <Modal
      isOpen={isJobInvationModalOpen}
      onRequestClose={() => {
        setJobInvationModalOpen(false)
      }}
    >
      <Query
        query={gql`
          query($input: GetMyJobsInput!) {
            getMyJobs(input: $input) {
              jobs {
                id
                title
              }
            }
          }
        `}
        withLoader
        parser={[['jobs', 'getMyJobs.jobs']]}
        variables={{ input: { filter: 'opened' } }}
      >
        {({ jobs }) =>
          jobs.length ? (
            <Form
              validate={createValidator({
                text: [required],
                jobId: [required],
              })}
              onSubmit={input => {
                return createJobInvite({
                  variables: {
                    input: {
                      text: input.text,
                      jobId: input.jobId,
                      recipientId: userId,
                    },
                  },
                }).then(({ data }) => {
                  track('User send job invation', {
                    'Recipient id': data.createJobInvite.invite.recipientId,
                    'Job id': data.createJobInvite.invite.jobId,
                  })
                })
              }}
              initialValues={{ jobId: jobs[0].id }}
              resetOnSuccess
              successMessage={`Invitation was sent to ${userName}`}
            >
              {({ alertExists, alertProps, buttonProps, values }) => (
                <Segment
                  title={`Send your invitation to ${userName}`}
                  desc={`Your invitation will be sent to the ${userName}. You can send an invitation to no more than 5 Service Providers for the same task.`}
                >
                  <Select fullwidth label="Job" name="jobId" className="is-fullwidth">
                    {jobs.map((job, i) => (
                      <option key={i} value={job.id}>
                        {job.title}
                      </option>
                    ))}
                  </Select>
                  <Textarea label="Customer message" name="text" />
                  {alertExists && <Alert {...alertProps} />}
                  <Button fullWidth {...buttonProps}>
                    Send Invation
                  </Button>
                </Segment>
              )}
            </Form>
          ) : (
            <Segment title="Send your invitation to Service Provider" desc="To do this, you must first create a task">
              <Button to={getJobNewRoute()}>Post a Task</Button>
            </Segment>
          )
        }
      </Query>
    </Modal>
  )
}

export default JobInvationModal
