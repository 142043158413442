import React, { useEffect, useState } from 'react'
import Query from 'components/Query'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import Link from 'components/Link'
import Review, { reviewFragment } from 'components/Review'

const getReviewsQuery = gql`
  query getReviews($input: GetReviewsInput) {
    getReviews(input: $input) {
      reviews {
        ...Review_review
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${reviewFragment}
`

const getUserQuery = gql`
  query($input: GetUserInput!) {
    getUser(input: $input) {
      user {
        id
        asAssigneePoisitiveReviewsCount
        asAssigneeNegativeReviewsCount
        asAssigneeNeutralReviewsCount
        asOwnerPoisitiveReviewsCount
        asOwnerNegativeReviewsCount
        asOwnerNeutralReviewsCount
      }
    }
  }
`

const Reviews = ({ userId }) => {
  const [filter, setFilter] = useState({ recipientId: userId, limit: 5 })

  useEffect(() => {
    setFilter({ recipientId: userId, limit: 5 })
  }, [userId])

  return (
    <Query
      query={getUserQuery}
      variables={{ input: { userId: userId } }}
      parser={[['user', 'getUser.user']]}
      withLoader
    >
      {({ user }) => {
        const asOwnerTotal =
          user.asOwnerPoisitiveReviewsCount +
          user.asOwnerNegativeReviewsCount +
          user.asOwnerNeutralReviewsCount
        const asAssigneeTotal =
          user.asAssigneePoisitiveReviewsCount +
          user.asAssigneeNegativeReviewsCount +
          user.asAssigneeNeutralReviewsCount
        return (
          <>
            {(!!asOwnerTotal || !!asAssigneeTotal) && (
              <div style={{ marginBottom: 35 }}>
                {!!asOwnerTotal && (
                  <p>
                    Total{' '}
                    <Link
                      selected={
                        filter.senderRole === 'assignee' && !filter.mark
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'assignee',
                        })
                      }}
                    >
                      {asOwnerTotal} reviews
                    </Link>{' '}
                    as customer:{' '}
                    <Link
                      selected={
                        filter.senderRole === 'assignee' &&
                        filter.mark === 'positive'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'assignee',
                          mark: 'positive',
                        })
                      }}
                    >
                      {user.asOwnerPoisitiveReviewsCount} positive
                    </Link>
                    ,{' '}
                    <Link
                      selected={
                        filter.senderRole === 'assignee' &&
                        filter.mark === 'neutral'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'assignee',
                          mark: 'neutral',
                        })
                      }}
                    >
                      {user.asOwnerNeutralReviewsCount} neutral
                    </Link>
                    ,{' '}
                    <Link
                      selected={
                        filter.senderRole === 'assignee' &&
                        filter.mark === 'negative'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'assignee',
                          mark: 'negative',
                        })
                      }}
                    >
                      {user.asOwnerNegativeReviewsCount} negative
                    </Link>
                  </p>
                )}
                {!!asAssigneeTotal && (
                  <p>
                    Total{' '}
                    <Link
                      selected={filter.senderRole === 'owner' && !filter.mark}
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'owner',
                        })
                      }}
                    >
                      {asAssigneeTotal} reviews
                    </Link>{' '}
                    as service provider:{' '}
                    <Link
                      selected={
                        filter.senderRole === 'owner' &&
                        filter.mark === 'positive'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'owner',
                          mark: 'positive',
                        })
                      }}
                    >
                      {user.asAssigneePoisitiveReviewsCount} positive
                    </Link>
                    ,{' '}
                    <Link
                      selected={
                        filter.senderRole === 'owner' &&
                        filter.mark === 'neutral'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'owner',
                          mark: 'neutral',
                        })
                      }}
                    >
                      {user.asAssigneeNeutralReviewsCount} neutral
                    </Link>
                    ,{' '}
                    <Link
                      selected={
                        filter.senderRole === 'owner' &&
                        filter.mark === 'negative'
                      }
                      type="dashed"
                      onClick={e => {
                        e.preventDefault()
                        setFilter({
                          limit: 10,
                          recipientId: userId,
                          senderRole: 'owner',
                          mark: 'negative',
                        })
                      }}
                    >
                      {user.asAssigneeNegativeReviewsCount} negative
                    </Link>
                  </p>
                )}
              </div>
            )}
            <Query
              query={getReviewsQuery}
              variables={{ input: filter }}
              parser={[
                ['reviews', 'getReviews.reviews'],
                ['pageInfo', 'getReviews.pageInfo'],
              ]}
              withLoader
            >
              {({ pageInfo, reviews, fetchMore }) => {
                if (!reviews.length)
                  return <Alert message="There are no reviews." />
                return (
                  <InfiniteScroll
                    loadMore={() => {
                      if (pageInfo.hasNextPage) {
                        fetchMore({
                          variables: {
                            input: {
                              ...filter,
                              cursor: pageInfo.endCursor,
                              limit: 10,
                            },
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev
                            return {
                              getReviews: {
                                ...fetchMoreResult.getReviews,
                                reviews: [
                                  ...prev.getReviews.reviews,
                                  ...fetchMoreResult.getReviews.reviews,
                                ],
                              },
                            }
                          },
                        })
                      }
                    }}
                    hasMore={pageInfo.hasNextPage}
                    loader={<div className="loader" key={0} />}
                  >
                    {reviews.map((review, i) => (
                      <Review key={i} review={review} />
                    ))}
                  </InfiniteScroll>
                )
              }}
            </Query>
          </>
        )
      }}
    </Query>
  )
}

export default Reviews
