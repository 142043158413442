import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import { ReactComponent as CheckMark } from 'assets/images/other/checkmark.svg'

const Title = ({
  className,
  tag = 'h1',
  size = 1,
  align = 'center',
  type = 'shortLine',
  desc,
  success,
  children,
  ...restProps
}) => {
  return (
    <div
      className={cn({
        [css.title]: true,
        [css[`size-${size}`]]: true,
        [css[`type-${type}`]]: true,
        [css[`align-${align}`]]: true,
        [css.success]: !!success,
        [className]: !!className,
      })}
      {...restProps}
    >
      {tag === 'h1' ? (
        <h1 className={css.heading}>{children}</h1>
      ) : (
        <h2 className={css.heading}>{children}</h2>
      )}
      {!!desc && <p className={css.desc}>{desc}</p>}
      <div className={css.line} />
      {!!success && <CheckMark className={css.checkmark} />}
    </div>
  )
}

export default Title
