import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import { ReactComponent as PositiveIcon } from 'assets/images/reviews-icons/positive.svg'
import { ReactComponent as NeutralIcon } from 'assets/images/reviews-icons/neutral.svg'
import { ReactComponent as NegativeIcon } from 'assets/images/reviews-icons/negative.svg'
import gql from 'graphql-tag'

const ReviewsStat = ({ user, userRole, className, ...restProps }) => {
  return (
    <div
      className={cn({ [css.stat]: true, [className]: !!className })}
      {...restProps}
    >
      <span className={cn(css.item, css.positive)}>
        <PositiveIcon className={css.icon} width={14} />
        <span className={css.count}>
          {userRole === 'assignee'
            ? user.asAssigneePoisitiveReviewsCount
            : user.asOwnerPoisitiveReviewsCount}
        </span>
      </span>
      <span className={cn(css.item, css.negative)}>
        <NegativeIcon className={css.icon} width={14} />
        <span className={css.count}>
          {userRole === 'assignee'
            ? user.asAssigneeNegativeReviewsCount
            : user.asOwnerNegativeReviewsCount}
        </span>
      </span>
      <span className={cn(css.item, css.neutral)}>
        <NeutralIcon className={css.icon} width={14} />
        <span className={css.count}>
          {userRole === 'assignee'
            ? user.asAssigneeNeutralReviewsCount
            : user.asOwnerNeutralReviewsCount}
        </span>
      </span>
    </div>
  )
}

export const reviewsStatFragment = gql`
  fragment ReviewsStat_user on User {
    id
    asAssigneePoisitiveReviewsCount
    asAssigneeNegativeReviewsCount
    asAssigneeNeutralReviewsCount
    asOwnerPoisitiveReviewsCount
    asOwnerNegativeReviewsCount
    asOwnerNeutralReviewsCount
  }
`

export default ReviewsStat
