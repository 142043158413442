import React from 'react'
import Form from 'components/Form'
import Input from 'components/Input'
import {
  createValidator,
  required,
  confirmPassword,
  email,
} from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  track,
  setUserData,
  fragments as mixpanelFragments,
} from 'utils/mixpanel'

const SetEmailPassword = () => {
  const [setMyEmailPassword] = useMutation(gql`
    mutation($input: SetMyEmailPasswordInput!) {
      setMyEmailPassword(input: $input) {
        me {
          id
          hasPassword
          email
          ...Mixpanel_user
        }
      }
    }
    ${mixpanelFragments.user}
  `)
  return (
    <Form
      validate={createValidator({
        email: [required, email],
        newPassword: [required],
        confirmPassword: [
          required,
          confirmPassword({ passwordField: 'newPassword' }),
        ],
      })}
      onSubmit={({ email, newPassword }) =>
        setMyEmailPassword({
          variables: {
            input: { email, newPassword },
          },
        }).then(({ data }) => {
          track('Set email and password')
          setUserData({ user: data.setMyEmailPassword.me })
        })
      }
      resetOnSuccess
      successMessage="Email & password set successfully"
    >
      {({ alertExists, alertProps, buttonProps }) => {
        return (
          <>
            <Input label="Enter e-mail address" name="email" type="email" />
            <Input label="New password" name="newPassword" type="password" />
            <Input
              label="Repeat new password"
              name="confirmPassword"
              type="password"
            />
            {alertExists && <Alert {...alertProps} />}
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <Button {...buttonProps}>Set Email & Password</Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default SetEmailPassword
