import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import RichText from 'components/RichText'
import PageWrapper from 'components/PageWrapper'
import Button from 'components/Button'
import { getCategoryGroupRoute, getCategoryRoute, getTagRoute, getJobNewRoute } from 'routes'
import Helmet from 'react-helmet'
import DocumentTitle from 'react-document-title'

const Header = ({ h2, h1, buttonTo = getJobNewRoute(), buttonText, imageUrl }) => (
  <div
    className={cn(css.header, css.section, imageUrl && css.withImage)}
    style={imageUrl ? { backgroundImage: `url('${imageUrl}')` } : {}}
  >
    <div className={css.wrapper}>
      {h1 && <h1 className={css.title}>{h1}</h1>}
      <h2 className={css.subTitle}>{h2 || 'Receive free offers and hire a pro through SureTask today!'}</h2>
      <Button className={css.button} color="green" shadow to={buttonTo}>
        {buttonText || 'Post a Task'}
      </Button>
    </div>
  </div>
)

const Categories = ({ categoryGroups, categoryGroup, className }) => (
  <div className={cn(css.allCategories, css.section, className)}>
    <div className={css.wrapper}>
      <h1 className={cn(css.title, css.sectionTitle)}>{categoryGroup ? 'Relative Categories' : 'All Categories'}</h1>
      <div className={css.categoryGroups}>
        {categoryGroups.map((cg, i) =>
          !categoryGroup || categoryGroup.id === cg.id ? (
            <div key={i} className={css.categoryGroup}>
              <h3 className={css.categoryGroupTitle}>
                <Link className={css.categoryGroupLink} to={getCategoryGroupRoute(cg.alias)}>
                  {cg.title}
                </Link>
              </h3>
              <div className={css.categories}>
                {cg.categories.map((category, i) => (
                  <Link key={i} className={css.categoryLink} to={getCategoryRoute(cg.alias, category.alias)}>
                    {category.title}
                  </Link>
                ))}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  </div>
)

const Tags = ({ tags }) => (
  <div className={cn(css.tags, css.section)}>
    <div className={css.wrapper}>
      <h1 className={cn(css.title, css.sectionTitle)}>Relative Tags</h1>
      <div className={css.list}>
        {tags.map((tag, i) => (
          <Link key={i} className={css.tag} to={getTagRoute(tag.alias)}>
            {tag.title}
          </Link>
        ))}
      </div>
    </div>
  </div>
)

const Article = ({ articleText, articleTitle }) => (
  <div className={cn(css.article, css.section)}>
    <div className={css.wrapper}>
      <h1 className={cn(css.title, css.sectionTitle)}>{articleTitle}</h1>
      <RichText dangerouslySetInnerHTML={{ __html: articleText }} className={css.text} />
    </div>
  </div>
)

const Steps = ({ firstStep, secondStep, thirdStep }) => (
  <div className={cn(css.steps, css.section)}>
    <div className={css.wrapper}>
      <h2 className={cn(css.sectionTitle, css.title)}>How it works</h2>
      <div className={css.items}>
        <div className={css.item}>
          <div className={css.number}>1</div>
          <div className={css.text}>
            <p className={css.name}>Post a Task</p>
            <p className={css.desc}>{firstStep || 'Tell us what you need. It’s FREE to post'}</p>
          </div>
        </div>
        <div className={css.item}>
          <div className={css.number}>2</div>
          <div className={css.text}>
            <p className={css.name}>Get offers</p>
            <p className={css.desc}>{secondStep || 'Review offers from trusted service providers'}</p>
          </div>
        </div>
        <div className={css.item}>
          <div className={css.number}>3</div>
          <div className={css.text}>
            <p className={css.name}>Get things done</p>
            <p className={css.desc}>{thirdStep || 'Hire the right service provider for your Task'}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const CategoriesPage = () => {
  return (
    <PageWrapper
      query={gql`
        query {
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
                alias
              }
            }
          }
        }
      `}
      variables={{}}
      parser={[['categoryGroups', 'getCategoryGroups.categoryGroups']]}
    >
      {({ categoryGroups }) => (
        <div className={cn(css.section, css.categoriesPage)}>
          <Categories categoryGroups={categoryGroups} />
        </div>
      )}
    </PageWrapper>
  )
}

export const CategoryGroupPage = ({ match }) => {
  return (
    <PageWrapper
      query={gql`
        query($input: GetCategoryGroupInput!) {
          getCategoryGroup(input: $input) {
            categoryGroup {
              id
              title
              alias
              h2
              h1
              firstStep
              secondStep
              thirdStep
              articleTitle
              articleText
              buttonText
              imageUrl
              tags {
                id
                alias
                title
              }
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
                alias
              }
            }
          }
        }
      `}
      variables={{
        input: { categoryGroupAlias: match.params.categoryGroupAlias },
      }}
      checkExists={({ categoryGroup }) => categoryGroup}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['categoryGroup', 'getCategoryGroup.categoryGroup'],
      ]}
    >
      {({ categoryGroups, categoryGroup }) => (
        <>
          <Helmet>
            {categoryGroup.title && <title> {`SureTask | ${categoryGroup.title}`}</title>}
            {categoryGroup.textDesc && <meta name="description" content={categoryGroup.textDesc } />}
            <meta property="og:title" content={`SureTask | ${categoryGroup.title}`} />
            <meta property="og:description" content={categoryGroup.textDesc} />
            <meta property="og:image" content="https://suretask.com/icon-192.png" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <Header
            h1={categoryGroup.h1 || categoryGroup.title}
            h2={categoryGroup.h2}
            buttonTo={getJobNewRoute({
              categoryGroupAlias: categoryGroup.alias,
            })}
            buttonText={categoryGroup.buttonText}
            imageUrl={categoryGroup.imageUrl}
          />
          <Steps
            firstStep={categoryGroup.firstStep}
            secondStep={categoryGroup.secondStep}
            thirdStep={categoryGroup.thirdStep}
          />
          {!!categoryGroup.articleTitle && (
            <Article articleTitle={categoryGroup.articleTitle} articleText={categoryGroup.articleText} />
          )}
          <Categories categoryGroups={categoryGroups} categoryGroup={categoryGroup} />
          {!!categoryGroup.tags && !!categoryGroup.tags.length && <Tags tags={categoryGroup.tags} />}
        </>
      )}
    </PageWrapper>
  )
}

export const CategoryPage = ({ match }) => {
  return (
    <PageWrapper
      query={gql`
        query($input: GetCategoryInput!) {
          getCategory(input: $input) {
            category {
              id
              title
              alias
              h2
              h1
              firstStep
              secondStep
              thirdStep
              articleTitle
              articleText
              buttonText
              imageUrl
              titleMeta
              description
              categoryGroup {
                id
                title
                alias
                h2
                h1
                firstStep
                secondStep
                thirdStep
                articleTitle
                articleText
                buttonText
                imageUrl
              }
              tags {
                id
                alias
                title
              }
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
                alias
              }
            }
          }
        }
      `}
      variables={{
        input: { categoryAlias: match.params.categoryAlias },
      }}
      checkExists={({ category }) => category}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['category', 'getCategory.category'],
        ['categoryGroup', 'getCategory.category.categoryGroup'],
      ]}
    >
      {({ categoryGroups, categoryGroup, category }) => (
        <>
          <Helmet>
            {category.titleMeta && <title> {category.titleMeta}</title>}
            {category.description && <meta name="description" content={category.description} />}
            <meta property="og:title" content={`SureTask | ${category.titleMeta}`} />
            <meta property="og:description" content={category.description} />
            <meta property="og:image" content="https://suretask.com/icon-192.png" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <Header
            h1={category.h1 || category.title || categoryGroup.h1 || categoryGroup.title}
            h2={category.h2 || categoryGroup.h2}
            buttonTo={getJobNewRoute({
              categoryGroupAlias: categoryGroup.alias,
              categoryAlias: category.alias,
            })}
            buttonText={category.buttonText || categoryGroup.buttonText}
            imageUrl={category.imageUrl || categoryGroup.imageUrl}
          />
          <Steps
            firstStep={category.firstStep || categoryGroup.firstStep}
            secondStep={category.secondStep || categoryGroup.secondStep}
            thirdStep={category.thirdStep || categoryGroup.thirdStep}
          />
          {(!!category.articleTitle || !!categoryGroup.articleTitle) && (
            <Article
              articleTitle={category.articleTitle || categoryGroup.articleTitle}
              articleText={category.articleText || categoryGroup.articleText}
            />
          )}
          <Categories categoryGroups={categoryGroups} categoryGroup={category.categoryGroup} />
          {!!category.tags && !!category.tags.length && <Tags tags={category.tags} />}
        </>
      )}
    </PageWrapper>
  )
}

export const TagPage = ({ match }) => {
  return (
    <PageWrapper
      query={gql`
        query($input: GetTagInput!) {
          getTag(input: $input) {
            tag {
              id
              title
              alias
              h2
              h1
              firstStep
              secondStep
              thirdStep
              articleTitle
              articleText
              parentTag {
                id
                articleTitle
                articleText
              }
              buttonText
              imageUrl
              categoryGroup {
                id
                title
                alias
                h2
                h1
                firstStep
                secondStep
                thirdStep
                articleTitle
                articleText
                buttonText
                imageUrl
              }
              category {
                id
                title
                alias
                h2
                h1
                firstStep
                secondStep
                thirdStep
                articleTitle
                articleText
                buttonText
                imageUrl
              }
              tags {
                id
                alias
                title
              }
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
                alias
              }
            }
          }
        }
      `}
      variables={{
        input: { tagAlias: match.params.tagAlias },
      }}
      checkExists={({ tag }) => tag}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['tag', 'getTag.tag'],
        ['category', 'getTag.tag.category'],
        ['categoryGroup', 'getTag.tag.categoryGroup'],
      ]}
    >
      {({ categoryGroups, categoryGroup, category, tag }) => (
        <>
          <Helmet>
            {(tag.h1 || tag.title) && <title> {tag.h1 || tag.title}</title>}
            {tag.articleText ||
              (tag.parentTag && tag.parentTag.articleText) ||
              (category && category.articleText) ||
              (categoryGroup.articleText && (
                <meta
                  name="description"
                  content={
                    tag.articleText ||
                    (tag.parentTag && tag.parentTag.articleText) ||
                    (category && category.articleText) ||
                    categoryGroup.articleText
                  }
                />
              ))}
            <meta property="og:title" content={`SureTask | ${tag.h1 || tag.title}`} />
            <meta
              property="og:description"
              content={
                tag.articleText ||
                (tag.parentTag && tag.parentTag.articleText) ||
                (category && category.articleText) ||
                categoryGroup.articleText
              }
            />
            <meta property="og:image" content="https://suretask.com/icon-192.png" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <Header
            h1={
              tag.h1 ||
              tag.title ||
              (category && category.h1) ||
              (category && category.title) ||
              categoryGroup.h1 ||
              categoryGroup.title
            }
            h2={tag.h2 || (category && category.h2) || categoryGroup.h2}
            buttonTo={getJobNewRoute({
              categoryGroupAlias: categoryGroup.alias,
              ...(category ? { categoryAlias: category.alias } : {}),
            })}
            buttonText={tag.buttonText || (category && category.buttonText) || categoryGroup.buttonText}
            imageUrl={tag.imageUrl || (category && category.imageUrl) || categoryGroup.imageUrl}
          />
          <Steps
            firstStep={tag.firstStep || (category && category.firstStep) || categoryGroup.firstStep}
            secondStep={tag.secondStep || (category && category.secondStep) || categoryGroup.secondStep}
            thirdStep={tag.thirdStep || (category && category.thirdStep) || categoryGroup.thirdStep}
          />
          {(!!tag.articleTitle || (category && !!category.articleTitle) || !!categoryGroup.articleTitle) && (
            <Article
              articleTitle={
                tag.articleTitle ||
                (tag.parentTag && tag.parentTag.articleTitle) ||
                (category && category.articleTitle) ||
                categoryGroup.articleTitle
              }
              articleText={
                tag.articleText ||
                (tag.parentTag && tag.parentTag.articleText) ||
                (category && category.articleText) ||
                categoryGroup.articleText
              }
            />
          )}
          <Categories categoryGroups={categoryGroups} categoryGroup={categoryGroup} />
          {!!tag.tags && !!tag.tags.length && <Tags tags={tag.tags} />}
        </>
      )}
    </PageWrapper>
  )
}
