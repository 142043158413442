import React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
// import Loader from 'ui/Loader'
import parseError from 'utils/parseError'
import Alert from 'components/Alert'
import isFunction from 'lodash/isFunction'
import presets from 'lib/queryPresets'
import batchGet from 'utils/batchGet'
import propsPreset from 'utils/propsPreset'
import includes from 'lodash/includes'
import * as Sentry from '@sentry/browser'

const Query = props => {
  const {
    loading: loadingComponent,
    withLoader,
    withCenteredLoader,
    withInlineLoader,
    withoutLoader,
    error: errorComponent,
    withError,
    withoutError,
    children,
    alwaysLoading,
    alwaysError,
    parser,
    query,
    skipError,
    providedData,
    variables,
    ...restProps
  } = propsPreset(props, presets)
  if (!query) return children(isFunction(parser) ? { ...parser() } : {})

  const queryVariables = isFunction(variables)
    ? variables({ providedData })
    : variables

  return (
    <ApolloQuery query={query} variables={queryVariables} {...restProps}>
      {queryProps => {
        const { error, loading, data } = queryProps
        const parsedData = isFunction(parser)
          ? parser({ ...queryProps, providedData })
          : batchGet(data, parser)
        const resultProps = {
          ...queryProps,
          ...parsedData,
        }

        // if (loading || alwaysLoading)
        //   return withLoader ? (
        //     <Loader />
        //   ) : withCenteredLoader ? (
        //     <Loader centered />
        //   ) : withInlineLoader ? (
        //     <Loader inline />
        //   ) : withoutLoader ? null : loadingComponent ? (
        //     loadingComponent
        //   ) : (
        //     children({ ...resultProps })
        //   )

        if (loading || alwaysLoading)
          return withLoader ? (
            <div className="loader" />
          ) : withCenteredLoader ? (
            <div className="loader" />
          ) : withInlineLoader ? (
            <span className="loader" />
          ) : withoutLoader ? null : loadingComponent ? (
            loadingComponent
          ) : (
            children({ ...resultProps })
          )

        const errorHere = error || alwaysError

        if (errorHere) {
          console.error(errorHere)
          const parsedError = parseError(errorHere)
          if (parsedError.isUnknown) Sentry.captureException(errorHere)
          if (
            parsedError.errorMessage !== skipError &&
            !includes(skipError, parsedError.errorMessage)
          )
            return withError ? (
              <Alert type="fail" message={parsedError.errorMessageHuman} />
            ) : withoutError ? null : errorComponent ? (
              errorComponent({ parsedError, ...resultProps })
            ) : (
              children({ parsedError, ...resultProps })
            )
        }

        return children({ ...resultProps })
      }}
    </ApolloQuery>
  )
}

export default Query
