const getUploadUrl = (name, preset) => {
  switch (preset) {
    case 'uploadPreview':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_256,h_256,c_fill/${name}`
    case 'coverImagePreview':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_620,h_140,c_fill/${name}`
    case 'coverImage':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_1860,h_420,c_fill/${name}`
    case 'photo':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_1000,h_1000,c_limit,q_80/${name}`
    case 'photoPreview':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_600,h_150,c_fit,q_80/${name}`
    case 'avatar':
      return name
        ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_256,h_256,c_thumb,g_face,q_80/${name}`
        : '/images/other/avatar-placeholder.png'
    default:
      return name
  }
}

export default getUploadUrl
