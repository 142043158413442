import React, { useState } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import 'react-dates/initialize'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

const DatePicker = ({ label, formik, name, hint, onChange, disabled, deadlineValue, ...restProps }) => {
  const [isFocused, setFocused] = useState(false)
  const [isValue, setValue] = useState(deadlineValue)
  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <SingleDatePicker
        date={isValue ? moment(isValue) : null}
        onDateChange={date => {
          if (onChange) onChange(date)
          formik.mutators.setFieldValue(name, date)
          setValue(date)
        }}
        numberOfMonths={1}
        small={true}
        focused={isFocused}
        onFocusChange={({ focused }) => {
          setFocused(focused)
        }}
        id={name}
        disabled={disabled}
        {...restProps}
      />
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default DatePicker
