import React, { useState, useEffect } from 'react'
import PageWrapper from 'components/PageWrapper'
import gql from 'graphql-tag'
import flatten from 'lodash/flatten'
import find from 'lodash/find'
import Form from 'components/Form'
import Button from 'components/Button'
import Alert from 'components/Alert'
import PageSegment from 'components/PageSegment'
import CategoriesSelect from 'components/CategoriesSelect'
import Input from 'components/Input'
import Hit from 'components/Hit'
import CityInput from 'components/CityInput'
import { useApolloClient } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'
import useGlobalState from 'lib/globalState'
import { createValidator, integerPositive } from 'lib/validation'
import { geolocated } from 'react-geolocated'
import { track, toMixpanelCategories } from 'utils/mixpanel'
import JobPreview, { jobPreviewFragment } from 'components/JobPreview'
import parseQueryString from 'utils/parseQueryString'
import { getJobsRoute } from 'routes'

const getJobsQuery = gql`
  query($input: GetJobsInput) {
    getJobs(input: $input) {
      jobs {
        ...JobPreview_job
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${jobPreviewFragment}
`

const JobsPage = ({ coords, location, history }) => {
  return (
    <PageWrapper
      query={gql`
        query {
          getMe {
            me {
              id
              lat
              lng
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
              categories {
                id
                title
              }
            }
          }
        }
      `}
      parser={[
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
        ['me', 'getMe.me'],
      ]}
    >
      {({ me, categoryGroups }) => (
        <JobsPageInner {...{ me, categoryGroups, coords, location, history }} />
      )}
    </PageWrapper>
  )
}

const JobsPageInner = ({ me, categoryGroups, coords, location, history }) => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const initalValues = globalState.getJobsInput
    ? globalState.getJobsInput
    : {
        categoryIds: flatten(
          categoryGroups.map(categoryGroup =>
            categoryGroup.categories.map(category => category.id)
          )
        ),
        categoryGroupIds: categoryGroups.map(categoryGroup => categoryGroup.id),
        city: '',
        distance: '',
        keywords: '',
        minPrice: '',
      }

  const initCategoryGroupAlias = parseQueryString(location.search)
    .categoryGroupAlias
  const initCategoryGroup = find(
    categoryGroups,
    categoryGroup => categoryGroup.alias === initCategoryGroupAlias
  )
  const initCategoryGroupId = initCategoryGroup ? initCategoryGroup.id : ''

  useEffect(() => {
    if (initCategoryGroupId) {
      const categoryIds = find(
        categoryGroups,
        categoryGroup => categoryGroup.id === initCategoryGroupId
      ).categories.map(category => category.id)
      dispatchGlobalAction({
        type: 'saveGetJobsInput',
        input: {
          ...initalValues,
          categoryGroupIds: [initCategoryGroupId],
          categoryIds,
        },
      })
      history.replace(getJobsRoute())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCategoryGroupId, categoryGroups])

  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    endCursor: null,
  })
  const [jobs, setJobs] = useState([])
  const apolloClient = useApolloClient()

  const formInputToQueryInput = (formInput, myLocation) => {
    // const remote = formInput.city === 'remote'
    const queryInput = {
      city: formInput.city || null,
      // remote: remote ? remote : null,
      categoryIds: formInput.categoryIds,
      minPrice: formInput.minPrice ? +formInput.minPrice : null,
      keywords: formInput.keywords || null,
      filter: 'opened',
      ...(formInput.distance && myLocation && myLocation.lat && myLocation.lng
        ? {
            distance: +formInput.distance,
            lat: myLocation.lat,
            lng: myLocation.lng,
          }
        : {}),
    }
    return queryInput
  }

  const myLocation = coords
    ? { lat: coords.latitude, lng: coords.longitude }
    : me && me.lat && me.lng
    ? { lat: me.lat, lng: me.lng }
    : null

  return initCategoryGroupId ? null : (
    <PageSegment title="Find a Job" titleAlign="left" withPaper={false}>
      <Form
        validate={createValidator({
          minPrice: [integerPositive],
          distance: [
            integerPositive,
            value =>
              value && !myLocation && 'Your current location unavailable',
          ],
        })}
        onSubmit={async input => {
          const queryInput = formInputToQueryInput(input, myLocation)
          const { data, errors } = await apolloClient.query({
            query: getJobsQuery,
            variables: {
              input: {
                ...queryInput,
                limit: 30,
              },
            },
            fetchPolicy: 'network-only'
          })
          track('Job search', {
            Categories: toMixpanelCategories(
              queryInput.categoryIds,
              categoryGroups
            ),
            Distance: queryInput.distance || null,
            City: queryInput.city,
            'Min. price': queryInput.minPrice,
            Keywords: queryInput.keywords,
            'Results exists': !!data.getJobs.jobs.length,
          })
          if (!errors)
            dispatchGlobalAction({
              type: 'saveGetJobsInput',
              input,
            })
          setJobs(data.getJobs.jobs)
          setPageInfo(data.getJobs.pageInfo)
        }}
        initialValuesEqual={() => true}
        initialValues={initalValues}
        hideSuccessAlert
      >
        {({ buttonProps, handleSubmit, values, form }) => (
          <>
            <Hit action={handleSubmit} />
            <div className="columns is-variable is-8">
              <div className="column is-narrow mobile-hide" style={{ width: '300px' }}>
                <h3 className="title is-5">Categories</h3>
                <CategoriesSelect categoryGroups={categoryGroups} withOther />
              </div>
              <div className="column">
                <div className="columns is-variable is-3 ">
                  <div className="column mobile-hide">
                    <Input label="Keywords" name="keywords" />
                  </div>
                </div>
                <div className="columns is-variable is-3 mobile-hide">
                  <div className="column">
                    <div className="control is-expanded mobile-hide">
                      <CityInput label="City" name="city" />
                    </div>
                  </div>
                  {myLocation && (
                    <div className="column mobile-hide">
                      <Input label="Distance, mi" name="distance" />
                    </div>
                  )}
                  <div className="column mobile-hide">
                    <Input label="Lowest price" name="minPrice" />
                  </div>
                  <div className="column is-narrow mobile-hide  " style={{ paddingTop: 44 }}>
                    <Button {...buttonProps}>Search</Button>
                  </div>
                </div>
                <div className="container" style={{ marginTop: '3em' }}>
                  {!jobs.length ? (
                    <Alert message="There are no tasks matching your criteria" />
                  ) : (
                    <InfiniteScroll
                      loadMore={async () => {
                        const { data } = await apolloClient.query({
                          query: getJobsQuery,
                          variables: {
                            input: {
                              ...formInputToQueryInput(values, myLocation),
                              cursor: pageInfo.endCursor,
                              limit: 10,
                            },
                          },
                        })
                        setJobs([...jobs, ...data.getJobs.jobs])
                        setPageInfo(data.getJobs.pageInfo)
                      }}
                      hasMore={pageInfo.hasNextPage}
                      loader={<div className="loader" key={0} />}
                    >
                      {jobs.map((job, i) => (
                        <JobPreview job={job} key={i} />
                      ))}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Form>
    </PageSegment>
  )
}

export default geolocated()(JobsPage)
