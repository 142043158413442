import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import css from './styles.module.scss'

const Button = ({
  to,
  color = 'green',
  outline = false,
  shadow = false,
  href,
  square,
  className,
  loading,
  disabled,
  children,
  fullWidth,
  ...etcProps
}) => {
  const classNameHere = cn({
    [css.button]: true,
    [css.loading]: loading,
    [css.disabled]: disabled,
    [css[`color-${color}`]]: !!color,
    [css.outline]: !!outline,
    [css.filled]: !outline,
    [css.shadow]: !!shadow,
    [css.square]: !!square,
    [css.fullWidth]: !!fullWidth,
    [className]: !!className,
  })
  const childrenHere = <span className={css.content}>{children}</span>
  return to ? (
    <Link
      to={to}
      className={classNameHere}
      children={childrenHere}
      {...etcProps}
    />
  ) : href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={href}
      className={classNameHere}
      children={childrenHere}
      {...etcProps}
    />
  ) : (
    <button
      disabled={disabled}
      className={classNameHere}
      children={childrenHere}
      {...etcProps}
    />
  )
}

export const Buttons = ({ className, ...rerstProps }) => (
  <div
    className={cn({ [className]: !!className, [css.buttons]: true })}
    {...rerstProps}
  />
)

export default Button
