import React from 'react'
import PageWrapper from 'components/PageWrapper'
import DashboardLayout from 'components/DashboardLayout'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import Alert from 'components/Alert'
import CreditForm from 'components/CreditForm'
import PageSegment from 'components/PageSegment'
import Segment from 'components/Segment'
import toHumanDate from 'utils/toHumanDate'

const BalancePage = () => {
  const getLabelByTxCode = code => {
    return {
      BALANCE_REPLENISHMENT: 'User balance replenishment',
      SIGNUP_BONUS: 'Bonus for successful signup',
      TX_BECOME_SERVICE_PROVIDER_BONUS: 'Service Provider Welcome Bonus',
      BID_PURCHASE: 'Bid purchase',
    }[code]
  }
  return (
    <PageWrapper
      authorizedOnly
      Layout={DashboardLayout}
      query={gql`
        query MyTxs($input: GetMyTxsInput) {
          getMyTxs(input: $input) {
            txs {
              id
              desc
              createdAt
              sum
              type
              code
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          getMe {
            me {
              id
              balance
            }
          }
        }
      `}
      parser={[
        ['txs', 'getMyTxs.txs'],
        ['pageInfo', 'getMyTxs.pageInfo'],
        ['me', 'getMe.me'],
      ]}
      fetchPolicy='network-only'
      variables={{ input: { limit: 30 } }}
    >
      {({ txs, pageInfo, me, fetchMore }) => (
        <PageSegment title={`My Balance: £ ${me && me.balance.toFixed(2)}`}>
          <Segment
            title="Top Up Your Account"
            desc="
            Top up your account to bid. When you send your bid, you'll be charged £ 1."
          >
            <CreditForm />
          </Segment>

          <Segment title="Transactions">
            <InfiniteScroll
              loadMore={async () => {
                if (pageInfo.hasNextPage) {
                  fetchMore({
                    variables: {
                      input: {
                        cursor: pageInfo.endCursor,
                        limit: 10,
                      },
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev
                      return {
                        getMyTxs: {
                          ...fetchMoreResult.getMyTxs,
                          txs: [
                            ...prev.getMyTxs.txs,
                            ...fetchMoreResult.getMyTxs.txs,
                          ],
                        },
                      }
                    },
                  })
                }
              }}
              hasMore={pageInfo.hasNextPage}
              loader={<div className="loader" key={0} />}
            >
              <div style={{ overflowX: 'auto' }}>
                {!txs.length ? (
                  <Alert message="You don't have any transactions yet. But let's fix it!" />
                ) : (
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {txs.map((tx, i) => {
                        return (
                          <tr key={i}>
                            <td>{toHumanDate(tx.createdAt, 'LLL')}</td>
                            <td>£ {tx.sum.toFixed(2)}</td>
                            <td>{getLabelByTxCode(tx.code)}</td>
                            <td>{tx.desc} </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </InfiniteScroll>
          </Segment>
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default BalancePage
