import React from 'react'
import css from './styles.module.scss'
import { getUserRoute } from 'routes'
import gql from 'graphql-tag'
import getUploadUrl from 'utils/getUploadUrl'
import Link from 'components/Link'
import toHumanDate from 'utils/toHumanDate'
import ReviewMark from 'components/ReviewMark'
import PrettyText from 'components/PrettyText'

const Review = ({ review, recipient }) => {
  return (
    <div className={css.review}>
      <div className={css.header}>
        <div className={css.avatarPlace}>
          <img
            alt=""
            src={getUploadUrl(review.sender.avatar, 'avatar')}
            className={css.avatar}
          />
        </div>
        <div className={css.infoPlace}>
          <div className={css.fromAndTo}>
            <p className={css.from}>
              <span className={css.label}>From:</span>{' '}
              <span className={css.name}>
                <Link to={getUserRoute(review.sender.id)}>
                  {review.sender.displayName},
                </Link>
              </span>{' '}
              <span className={css.role}>
                {review.senderRole === 'owner' ? 'customer' : 'service provider'},{' '}
                {toHumanDate(review.createdAt)}
              </span>
            </p>
            {!!recipient && (
              <p className={css.to}>
                <span className={css.label}>To:</span>{' '}
                <span className={css.name}>
                  <Link to={getUserRoute(recipient.id)}>
                    {recipient.displayName},
                  </Link>
                </span>{' '}
                <span className={css.role}>
                  {review.senderRole === 'owner' ? 'service provider' : 'customer'}
                </span>
              </p>
            )}
          </div>
          <ReviewMark className={css.mark} mark={review.mark} />
        </div>
      </div>
      <PrettyText className={css.reviewBody}>{review.text}</PrettyText>
    </div>
  )
}

export const reviewFragment = gql`
  fragment Review_review on Review {
    id
    text
    mark
    createdAt
    senderRole
    job {
      id
      translitTitle
      title
    }
    sender {
      id
      avatar
      displayName
    }
  }
`

export default Review
