import React from 'react'
import { Field } from 'react-final-form'
import cn from 'classnames'

const RadioButton = ({ label, ...restProps }) => (
  <Field type="radio" {...restProps}>
    {({ input, meta, ...restFieldProps }) => {
      return (
        <label className="radio" {...restFieldProps}>
          <input {...input} /> {label}
        </label>
      )
    }}
  </Field>
)

export const RadioButtons = ({
  label,
  children,
  hideError,
  hint,
  ...restProps
}) => (
  <Field {...restProps}>
    {({ input, meta, ...restFieldProps }) => {
      const isInvalid = (meta.error || meta.submitError) && meta.submitFailed
      return (
        <div className="field">
          {label && <label className="label">{label}</label>}
          <div className="control">
            {children}
            {isInvalid && !hideError && (
              <p
                className={cn({
                  help: true,
                  'is-danger': isInvalid,
                })}
              >
                {meta.error || meta.submitError}
              </p>
            )}
            {!!hint && <p className={'help'}>{hint}</p>}
          </div>
        </div>
      )
    }}
  </Field>
)

export default RadioButton
