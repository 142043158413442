import React, { useState } from 'react'
import Link from 'components/Link'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import {
  getUserRoute,
  getJobEditRoute,
  getServiceProviderFormRoute,
  getMyProfileRoute,
  getJobNewRoute,
  getMyChatRoute,
  getJobRoute,
} from 'routes'
import DashboardLayout from 'components/DashboardLayout'
import _ from 'lodash'
import PrettyText, { toPrettyText } from 'components/PrettyText'
import Alert from 'components/Alert'
import Input from 'components/Input'
import gql from 'graphql-tag'
import PageWrapper from 'components/PageWrapper'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import UserContacts from 'components/UserContacts'
import SignUpForm from 'components/SignUpForm'
import Gallery from 'components/Gallery'
import Textarea from 'components/Textarea'
import { createValidator, required } from 'lib/validation'
import Button, { Buttons } from 'components/Button'
import getUploadUrl from 'utils/getUploadUrl'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import CreditForm from 'components/CreditForm'
import PageSegment from 'components/PageSegment'
import Segment from 'components/Segment'
import getJobStatus from 'utils/getJobStatus'
import toHumanDate from 'utils/toHumanDate'
import truncate from 'utils/truncate'
import BlockJob from 'components/BlockJob'
import BlockBid from 'components/BlockBid'
import StartChatModal from 'components/StartChatModal'
import Bid from 'components/Bid'
import Comment from 'components/Comment'
import { track, fragments as mixpanelFragments } from 'utils/mixpanel'
import css from './styles.module.scss'
import ReviewsStat from 'components/ReviewsStat'
import ReviewMark from 'components/ReviewMark'
import { canManageJob, canBlockBid } from 'utils/can'
import Hit from 'components/Hit'
import Select from 'components/Select'
import Helmet from 'react-helmet'
import Modal from '../../components/Modal'

const JobPage = ({ match, history, ...etcProps }) => {
  const [bidEditorVisible, setBidEditorVisibility] = useState(false)
  const [closeModalOpen, setCloseModalOpen] = useState(false)
  const [createBid] = useMutation(
    gql`
      mutation($input: CreateBidInput!) {
        createBid(input: $input) {
          bid {
            id
            text
            price
            createdAt
            blocked
            blockComment
            chatId
            owner {
              id
              displayName
              avatar
              asAssigneePoisitiveReviewsCount
              asAssigneeNegativeReviewsCount
              asAssigneeNeutralReviewsCount
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
            }
          }
          me {
            id
            balance
          }
        }
      }
    `,
    {
      update: (cache, { data }) => {
        const getJobData = cache.readQuery({
          query: getJobQuery,
          variables: getJobQueryVariables,
        })
        const newQueryData = {
          ...getJobData,
          getJob: {
            ...getJobData.getJob,
            job: {
              ...getJobData.getJob.job,
              myBid: data.createBid.bid,
            },
          },
        }
        cache.writeQuery({
          query: getJobQuery,
          data: newQueryData,
          variables: getJobQueryVariables,
        })
      },
    }
  )
  const [updateBid] = useMutation(gql`
    mutation($input: UpdateBidInput!) {
      updateBid(input: $input) {
        bid {
          id
          text
          createdAt
          blocked
          blockComment
          chatId
          owner {
            id
            displayName
            avatar
            contacts {
              email
              phone
              instagram
              facebook
              website
            }
          }
          price
        }
      }
    }
  `)
  const [makeStartOffer] = useMutation(gql`
    mutation($input: MakeStartOfferInput!) {
      makeStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
        }
      }
    }
  `)
  const [cancelStartOffer] = useMutation(gql`
    mutation($input: CancelStartOfferInput!) {
      cancelStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
        }
      }
    }
  `)
  const [acceptStartOffer] = useMutation(gql`
    mutation($input: AcceptStartOfferInput!) {
      acceptStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
          assigneeAcceptStartOffer
        }
      }
    }
  `)
  const [rejectStartOffer] = useMutation(gql`
    mutation($input: RejectStartOfferInput!) {
      rejectStartOffer(input: $input) {
        job {
          id
          translitTitle
          assignee {
            id
          }
          assigneeAcceptStartOffer
        }
      }
    }
  `)
  const [createReview] = useMutation(gql`
    mutation($input: CreateReviewInput!) {
      createReview(input: $input) {
        job {
          id
          translitTitle
          ownerReview {
            id
            text
            mark
          }
          assigneeReview {
            id
            text
            mark
          }
          assigneeAcceptStartOffer
        }
      }
    }
  `)
  const [closeJob] = useMutation(gql`
    mutation($input: CloseJobInput!) {
      closeJob(input: $input) {
        job {
          id
          translitTitle
          closed
          assignee {
            id
          }
        }
      }
    }
  `)
  const [sendComment] = useMutation(
    gql`
      mutation($input: SendCommentInput!) {
        sendComment(input: $input) {
          comment {
            id
            text
            createdAt
            blocked
            blockComment
            commentId
            owner {
              id
              displayName
              avatar
            }
          }
        }
      }
    `,
    {
      update: (cache, { data }) => {
        const getJobData = cache.readQuery({
          query: getJobQuery,
          variables: getJobQueryVariables,
        })
        const newQueryData = { ...getJobData }
        const newComment = data.sendComment.comment
        if (newComment.commentId) {
          const parentComment = newQueryData.getJob.job.comments.find(c => c.id === newComment.commentId)
          parentComment.comments.push(newComment)
        } else {
          newQueryData.getJob.job.comments.push({ ...newComment, comments: [] })
        }
        cache.writeQuery({
          query: getJobQuery,
          data: newQueryData,
          variables: getJobQueryVariables,
        })
      },
    }
  )
  const getJobQuery = gql`
    query($input: GetJobInput!) {
      getMe {
        me {
          id
          balance
          name
          email
          emailConfirmed
          categories {
            id
          }
          contacts {
            email
            phone
            instagram
            facebook
            website
          }
          permissions
          canBeAssignee
          bidTemplates
        }
      }
      getJob(input: $input) {
        job {
          id
          translitTitle
          deadline
          title
          photos
          owner {
            id
            displayName
            avatar
            asOwnerPoisitiveReviewsCount
            asOwnerNegativeReviewsCount
            asOwnerNeutralReviewsCount
            contacts {
              email
              phone
              instagram
              facebook
              website
            }
          }
          assignee {
            id
            displayName
            avatar
          }
          ownerReview {
            id
            text
            mark
          }
          assigneeReview {
            id
            text
            mark
          }
          assigneeAcceptStartOffer
          bidsCount
          closed
          text
          remote
          address
          city
          postalCode
          category {
            id
            title
            categoryGroup {
              id
              title
            }
          }
          averagePrice
          maxPrice
          createdAt
          bids {
            id
            text
            price
            createdAt
            blocked
            blockComment
            chatId
            owner {
              id
              displayName
              avatar
              asAssigneePoisitiveReviewsCount
              asAssigneeNegativeReviewsCount
              asAssigneeNeutralReviewsCount
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
            }
          }
          myBid {
            id
            text
            price
            createdAt
            blocked
            blockComment
            chatId
            owner {
              id
              displayName
              avatar
              asAssigneePoisitiveReviewsCount
              asAssigneeNegativeReviewsCount
              asAssigneeNeutralReviewsCount
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
            }
          }
          blocked
          blockComment
          comments {
            id
            text
            createdAt
            blocked
            blockComment
            owner {
              id
              displayName
              avatar
            }
            comments {
              id
              text
              createdAt
              blocked
              blockComment
              owner {
                id
                displayName
                avatar
              }
            }
          }
          ...Mixpanel_job
        }
      }
    }
    ${mixpanelFragments.job}
  `
  const jobIdAndTitle = match.params.jobIdAndTitle
  const jobIdAndTitleParts = jobIdAndTitle.split('-')
  const jobId = jobIdAndTitleParts[jobIdAndTitleParts.length - 1]
  const getJobQueryVariables = { input: { jobId } }
  const [startChatModalData, setStartChatModalData] = useState({
    isOpen: false,
  })
  return (
    <PageWrapper
      Layout={DashboardLayout}
      query={getJobQuery}
      parser={[['me', 'getMe.me'], ['job', 'getJob.job']]}
      variables={getJobQueryVariables}
      checkExists={({ job }) => !!job}
    >
      {({ me, job }) => {
        const isMyJob = me && me.id === job.owner.id
        const assigneeId = !!job.assignee && job.assignee.id
        const isMeAssignee = me && me.id === assigneeId
        const isMeCanBlockJob = canManageJob(me)
        const isMeCanBlockBid = canBlockBid(me)
        const assigneeBid =
          !!job.bids && !!job.bids.length && assigneeId && _.find(job.bids, bid => bid.owner.id === assigneeId)
        const cantCreateBidResult = !(me && me.canBeAssignee)
        const jobEditable = (isMyJob || canManageJob(me)) && !job.assigneeAcceptStartOffer && !job.closed
        const jobClosable = isMyJob && !job.closed && !job.assigneeAcceptStartOffer
        const schemaOrg = {
          '@context': 'https://schema.org/',
          '@type': 'JobPosting',
          title: job.title,
          description: toPrettyText(job.text),
          identifier: {
            '@type': 'PropertyValue',
            name: job.owner.displayName,
            value: job.owner.id,
          },
          datePosted: job.createdAt,
          validThrough: moment(job.createdAt).add(7, 'days'),
          employmentType: 'CONTRACTOR',
          hiringOrganization: {
            '@type': 'Organization',
            name: 'SureTask',
            sameAs: 'https://suretask.com',
            logo: 'https://suretask.com/icon-192.png',
          },
          ...(!job.address
            ? {
                jobLocationType: 'TELECOMMUTE',
                applicantLocationRequirements: {
                  '@type': 'Country',
                  name: 'United Kingdom',
                },
              }
            : {
                jobLocation: {
                  '@type': 'Place',
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: job.address,
                    addressCountry: 'UK',
                    ...(job.postalCode ? { postalCode: job.postalCode } : {}),
                    ...(job.city ? { addressRegion: job.city, addressLocality: job.city } : {}),
                  },
                },
              }),
        }
        return job.blocked && !isMeCanBlockJob && !isMyJob ? (
          <PageSegment title="Blocked job" titleAlign="left" titleType="longLine" withPaper={false}>
            <Alert message="Job has been blocked" />
          </PageSegment>
        ) : (
          <DocumentTitle title={`${job.title} | £ ${job.maxPrice || job.averagePrice} | SureTask`}>
            <>
              <Helmet>
                <meta property="og:title" content={`SureTask | ${job.title}`} />
                <meta name="description" content={job.text} />
                <meta property="og:image" content="https://suretask.com/icon-192.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrg) }} />
              </Helmet>
              <PageSegment
                title={job.title}
                desc={getJobStatus(job, me)}
                withPaper={false}
                titleAlign="left"
                titleType="longLine"
              >
                <StartChatModal {...{ startChatModalData, setStartChatModalData }} />
                <Segment>
                  <div className={css.jobInfo}>
                    <div className={css.sidebar}>
                      <div className={css.owner}>
                        <img className={css.avatar} alt="" src={getUploadUrl(job.owner.avatar, 'avatar')} />
                        <div className={css.about}>
                          <p className={css.name}>
                            <Link className={css.link} to={getUserRoute(job.owner.id)}>
                              {job.owner.displayName}
                            </Link>
                            , customer
                          </p>
                          <ReviewsStat className={css.reviews} user={job.owner} userRole="owner" />
                        </div>
                      </div>
                      <div className={css.offer}>
                        <p className={css.offerTitle}>Task Budget</p>
                        <p className={css.offerPrice}>
                          <b className={css.value}>
                            {job.maxPrice
                              ? `Up to £ ${job.maxPrice}`
                              : job.averagePrice
                              ? `£ ${job.averagePrice}`
                              : `Contract price`}
                          </b>
                        </p>
                        {!isMyJob && (
                          <Button
                            outline
                            type="button"
                            onClick={() => {
                              const el = document.getElementsByClassName('bidsPlace')[0]
                              if (el) {
                                const bodyRect = document.body.getBoundingClientRect()
                                const elemRect = el.getBoundingClientRect()
                                const offset = elemRect.top - bodyRect.top
                                const to = offset - 20
                                window.scrollTo({ top: to, behavior: 'smooth' })
                              }
                            }}
                          >
                            Make An Offer
                          </Button>
                        )}
                      </div>
                      <div className={css.actions}>
                        <Form initialValues={{ action: '' }} successMessage={false}>
                          {({ values }) => {
                            return (
                              <>
                                <Hit
                                  args={{ action: values.action }}
                                  action={() => {
                                    if (values.action === 'postSimilar') {
                                      history.push(getJobNewRoute({ sourceJobId: job.id }))
                                    }
                                  }}
                                />
                                <Select
                                  fullwidth
                                  name="action"
                                  className="is-fullwidth"
                                  placeholder={'Maga'}
                                  onChange={(...args) => {}}
                                >
                                  <option disabled value="">
                                    More Options
                                  </option>
                                  <option value="postSimilar">Post Similar Task</option>
                                </Select>
                              </>
                            )
                          }}
                        </Form>
                      </div>
                      <div className={css.share}>
                        <p className={css.shareTitle}>Share</p>
                        <Hit
                          args={[!!window.addthis, !!window['addthis_share']]}
                          action={() => {
                            if (window.addthis && window['addthis_share']) {
                              window.addthis.init()
                              window['addthis_share'].url = window.location.href
                              window['addthis_share'].title = window.document.title
                              window.addthis.toolbox('.addthis_inline_share_toolbox')
                              // console.log(889, window.addthis.layers, window.addthis.layers.refresh)
                              window.addthis.layers && window.addthis.layers.refresh && window.addthis.layers.refresh()
                              window.addthis.toolbox()
                              window['addthis_share'].url = window.location.href
                              window['addthis_share'].title = window.document.title
                              window.addthis.toolbox('.addthis_inline_share_toolbox')
                            }
                          }}
                        />
                        <div
                          className="addthis_inline_share_toolbox"
                          data-title={`${job.title} | £ ${job.maxPrice || job.averagePrice} | SureTask`}
                          data-media={'https://suretask.com/images/emails/suretask_logo.png'}
                        />
                      </div>
                    </div>
                    <div className={css.job}>
                      <div className={css.data}>
                        <p className={css.row}>
                          <span className={css.label}>Task budget:</span>{' '}
                          <b className={css.value}>
                            {job.maxPrice
                              ? `Up to £ ${job.maxPrice}`
                              : job.averagePrice
                              ? `£ ${job.averagePrice}`
                              : `Contract price`}
                          </b>
                        </p>
                        <p className={css.row}>
                          <span className={css.label}>Category:</span>{' '}
                          {!!job.category ? (
                            <b className={css.value}>
                              {job.category.categoryGroup.title} / {job.category.title}
                            </b>
                          ) : (
                            <b className={css.value}>Other</b>
                          )}
                        </p>
                        <p className={css.row}>
                          <span className={css.label}>Location:</span>{' '}
                          <b className={css.value}>{job.remote ? 'Remote work' : job.address}</b>
                        </p>
                        {/*<p className={css.row}>*/}
                        {/*  <span className={css.label}>Posted:</span>{' '}*/}
                        {/*  <b className={css.value}>{toHumanDate(job.createdAt)}</b>*/}
                        {/*</p>*/}
                        <p className={css.row}>
                          <span className={css.label}>Offers:</span>{' '}
                          <b className={css.value}>{job.bidsCount ? job.bidsCount : 0}</b>
                        </p>
                        {!!job.deadline && (
                          <p className={css.row}>
                            <span className={css.label}>Deadline:</span> <b className={css.value}>{toHumanDate(job.deadline, 'MMMM Do YYYY')}</b>
                          </p>
                        )}
                      </div>
                      {!!job.photos && !!job.photos.length && (
                        <div className={css.photos}>
                          <Gallery images={job.photos} title={job.title} />
                        </div>
                      )}
                      <PrettyText className={css.content}>{job.text}</PrettyText>
                      <Buttons className={css.buttons}>
                        {jobEditable && (
                          <Button outline to={getJobEditRoute(job.id)}>
                            Edit Task
                          </Button>
                        )}

                        {jobClosable && (
                          <Button outline onClick={() => setCloseModalOpen(true)}>
                            Close Task
                          </Button>
                        )}
                      </Buttons>
                      {jobClosable && (
                        <Modal
                          isOpen={closeModalOpen}
                          onRequestClose={() => {
                            setCloseModalOpen(false)
                          }}
                        >
                          <Segment title="Sorry to see you go..." desc="Are you sure?">
                            <Buttons>
                              {' '}
                              <Button outline onClick={() => setCloseModalOpen(false)}>
                                Continue task
                              </Button>
                              <Form
                                onSubmit={() =>
                                  closeJob({
                                    variables: {
                                      input: {
                                        jobId: job.id,
                                      },
                                    },
                                  }).then(() => {
                                    track('Close job', {
                                      job,
                                    })
                                  })
                                }
                                hideSuccessAlert
                              >
                                {({ alertExists, alertProps, buttonProps }) => {
                                  return (
                                    <>
                                      {alertExists && <Alert {...alertProps} />}
                                      <Button color="red" {...buttonProps}>
                                        Close task & exit
                                      </Button>
                                    </>
                                  )
                                }}
                              </Form>
                            </Buttons>
                          </Segment>
                        </Modal>
                      )}
                    </div>
                  </div>
                </Segment>
                {isMeCanBlockJob && <BlockJob jobId={job.id} />}

                {!job.blocked && !job.closed && !job.assigneeAcceptStartOffer && !me && (
                  <Segment className={'bidsPlace'} title="Register and become a service provider to bid on this task">
                    <SignUpForm
                      onSuccessRoute={getServiceProviderFormRoute({
                        jobId: job.id,
                        jobTranslitTitle: job.translitTitle,
                      })}
                    />
                  </Segment>
                )}
                {!job.blocked && !job.closed && !job.assigneeAcceptStartOffer && me && !isMyJob && cantCreateBidResult && (
                  <Segment className={'bidsPlace'} title="Become a service provider to bid on this task">
                    <Button
                      to={getServiceProviderFormRoute({
                        jobId: job.id,
                        jobTranslitTitle: job.translitTitle,
                      })}
                    >
                      Become a Service Provider
                    </Button>
                  </Segment>
                )}
                {!job.blocked && me && isMyJob && assigneeBid && (
                  <Segment title={!job.assigneeAcceptStartOffer ? 'Offer Sent To' : 'Assignee'}>
                    <Bid bid={assigneeBid}>
                      {!job.assigneeAcceptStartOffer ? (
                        <Form
                          onSubmit={() =>
                            cancelStartOffer({
                              variables: {
                                input: {
                                  jobId: job.id,
                                },
                              },
                            }).then(() => {
                              track('Cancel start offer', {
                                job,
                                'Assignee Id': assigneeBid.owner.id,
                              })
                            })
                          }
                          hideSuccessAlert
                        >
                          {({ alertExists, alertProps, buttonProps }) => {
                            return (
                              <Buttons>
                                {alertExists && <Alert {...alertProps} />}
                                <Button outline {...buttonProps}>
                                  Cancel Offer
                                </Button>
                                <ChatButton bid={assigneeBid} job={job} setStartChatModalData={setStartChatModalData} />
                              </Buttons>
                            )
                          }}
                        </Form>
                      ) : (
                        <Buttons>
                          <ChatButton bid={assigneeBid} job={job} setStartChatModalData={setStartChatModalData} />
                        </Buttons>
                      )}
                    </Bid>
                  </Segment>
                )}

                {(isMeCanBlockBid ||
                  (!job.blocked && me && isMyJob && !job.assigneeAcceptStartOffer && !job.closed)) && (
                  <Segment title="Bids">
                    {!job.bids.length ? (
                      <Alert message="There are no bids yet." />
                    ) : (
                      <>
                        {job.bids.map((bid, i) => (
                          <Bid bid={bid} key={i}>
                            {isMyJob && (
                              <Form
                                onSubmit={() =>
                                  assigneeId === bid.owner.id
                                    ? cancelStartOffer({
                                        variables: {
                                          input: {
                                            jobId: job.id,
                                          },
                                        },
                                      }).then(() => {
                                        track('Cancel start offer', {
                                          job,
                                          'Assignee Id': bid.owner.id,
                                        })
                                      })
                                    : makeStartOffer({
                                        variables: {
                                          input: {
                                            jobId: job.id,
                                            userId: bid.owner.id,
                                          },
                                        },
                                      }).then(() => {
                                        track('Make start offer', {
                                          job,
                                          'Assignee Id': bid.owner.id,
                                        })
                                      })
                                }
                                successMessage={
                                  assigneeId === bid.owner.id
                                    ? 'Success. Wait user to accept your offer'
                                    : 'Offer canceled'
                                }
                              >
                                {({ alertExists, alertProps, buttonProps }) => {
                                  return (
                                    <>
                                      {alertExists && <Alert {...alertProps} />}
                                      <Buttons>
                                        <Button outline {...buttonProps}>
                                          {assigneeId === bid.owner.id ? 'Cancel Offer' : 'Make an Offer'}
                                        </Button>
                                        <ChatButton bid={bid} job={job} setStartChatModalData={setStartChatModalData} />
                                      </Buttons>
                                    </>
                                  )
                                }}
                              </Form>
                            )}
                            {isMeCanBlockBid && <BlockBid bid={bid} />}
                          </Bid>
                        ))}
                      </>
                    )}
                  </Segment>
                )}
                {!job.blocked &&
                  !job.closed &&
                  !cantCreateBidResult &&
                  !isMyJob &&
                  !job.myBid &&
                  !job.assigneeAcceptStartOffer &&
                  me.balance >= 1 && (
                    <Segment
                      className={'bidsPlace'}
                      title="Post a bid"
                      desc={`When you send your bid, you'll be charged £ 1. Your balance: £ ${me.balance.toFixed(2)}.`}
                    >
                      <Form
                        validate={createValidator({
                          text: [required],
                        })}
                        onSubmit={({ text, price }) =>
                          createBid({
                            variables: {
                              input: {
                                text,
                                price: price ? +price : null,
                                jobId: job.id,
                              },
                            },
                          }).then(() => {
                            track('Create bid', {
                              job,
                              'Bid price': price ? +price : null,
                            })
                          })
                        }
                        hideSuccessAlert
                      >
                        {({
                          alertExists,
                          alertProps,
                          buttonProps,
                          form: {
                            mutators: { setFieldValue },
                          },
                        }) => {
                          return (
                            <>
                              <div className="field">
                                <label className="label">Offer templates</label>
                                <div className="control">
                                  {!me.bidTemplates.length ? (
                                    <p>
                                      You can set offer templates{' '}
                                      <Link to={getMyProfileRoute()}>in your profile settings</Link> to make it easier
                                      for you to respond
                                    </p>
                                  ) : (
                                    <div className={css.bidTemplates}>
                                      {me.bidTemplates.map((bidTemplate, i) => (
                                        <div className={css.bidTemplate} key={i}>
                                          <Link
                                            type="dashed"
                                            onClick={e => {
                                              e.preventDefault()
                                              setFieldValue('text', bidTemplate)
                                              track('User use bid template')
                                            }}
                                          >
                                            {truncate(bidTemplate, 150)}
                                          </Link>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <Textarea label="Message" name="text" />
                              <Input type="number" label="Price (optional)" name="price" min="1" />
                              {alertExists && <Alert {...alertProps} />}
                              <div className="field is-grouped is-grouped-multiline">
                                <div className="control">
                                  <Button {...buttonProps}>Post a Bid</Button>
                                </div>
                              </div>
                            </>
                          )
                        }}
                      </Form>
                    </Segment>
                  )}
                {!job.blocked &&
                  !cantCreateBidResult &&
                  !isMyJob &&
                  !job.myBid &&
                  !job.assigneeAcceptStartOffer &&
                  me.balance < 1 && (
                    <Segment
                      title="To post a bid, buy more credits"
                      desc="Top up your account to bid. When you send your bid, you'll be charged £ 1."
                    >
                      <CreditForm />
                    </Segment>
                  )}
                {!job.blocked && me && isMeAssignee && !job.assigneeAcceptStartOffer && (
                  <Segment title="You received an offer">
                    <Buttons>
                      <Form
                        onSubmit={() =>
                          acceptStartOffer({
                            variables: {
                              input: {
                                jobId: job.id,
                              },
                            },
                          }).then(() => {
                            track('Accept start offer', {
                              job,
                            })
                          })
                        }
                        hideSuccessAlert
                      >
                        {({ alertExists, alertProps, buttonProps }) => {
                          return (
                            <>
                              {alertExists && <Alert {...alertProps} />}
                              <Button {...buttonProps}>Accept Offer</Button>
                            </>
                          )
                        }}
                      </Form>
                      <Form
                        onSubmit={() =>
                          rejectStartOffer({
                            variables: {
                              input: {
                                jobId: job.id,
                              },
                            },
                          }).then(() => {
                            track('Reject start offer', {
                              job,
                            })
                          })
                        }
                        hideSuccessAlert
                      >
                        {({ alertExists, alertProps, buttonProps }) => {
                          return (
                            <>
                              {alertExists && <Alert {...alertProps} />}
                              <Button {...buttonProps}>Reject Offer</Button>
                            </>
                          )
                        }}
                      </Form>
                    </Buttons>
                  </Segment>
                )}
                {!job.blocked && me && isMeAssignee && job.owner.contacts && (
                  <Segment title="Job owner contacts">
                    <UserContacts user={job.owner} />
                  </Segment>
                )}
                {!job.blocked && me && !isMyJob && job.myBid && !bidEditorVisible && (
                  <Segment className={'bidsPlace'} title="Your bid">
                    <Bid bid={job.myBid}>
                      {!job.assigneeAcceptStartOffer && !job.closed && (
                        <Button outline href="#null" onClick={e => e.preventDefault() || setBidEditorVisibility(true)}>
                          Edit
                        </Button>
                      )}
                    </Bid>
                  </Segment>
                )}
                {!job.blocked && me && !isMyJob && job.myBid && bidEditorVisible && (
                  <Segment className={'bidsPlace'} title="Edit your bid">
                    <Form
                      validate={createValidator({
                        text: [required],
                      })}
                      onSubmit={({ text, price }) =>
                        updateBid({
                          variables: {
                            input: {
                              text,
                              bidId: job.myBid.id,
                              price: price ? +price : null,
                            },
                          },
                        }).then(() => {
                          track('Update bid', {
                            job,
                            'Bid price': price ? +price : null,
                          })
                        })
                      }
                      initialValues={{
                        text: job.myBid.text,
                        price: job.myBid.price,
                      }}
                      onSuccess={() => setBidEditorVisibility(false)}
                      hideSuccessAlert
                    >
                      {({ alertExists, alertProps, buttonProps }) => {
                        return (
                          <>
                            <Textarea label="Message" name="text" />
                            <Input type="number" label="Price (optional)" name="price" min="1" />
                            {alertExists && <Alert {...alertProps} />}
                            <Buttons>
                              <Button {...buttonProps}>Save Changes</Button>
                              <Button
                                outline
                                onClick={e => e.preventDefault() || setBidEditorVisibility(false)}
                                href="#null"
                              >
                                Discard Changes
                              </Button>
                            </Buttons>
                          </>
                        )
                      }}
                    </Form>
                  </Segment>
                )}
                <Segment title="Questions" desc="Please don't share personal info, website links or emails">
                  {!job.comments.length ? (
                    <Alert message="There are no questions yet." />
                  ) : (
                    job.comments.map((comment, i) => (
                      <Comment
                        comment={comment}
                        canAnswer={isMyJob || (me && comment.owner.id === me.id)}
                        key={i}
                        me={me}
                        answerForm={
                          <Form
                            validate={createValidator({
                              text: [required],
                            })}
                            onSubmit={({ text }) =>
                              sendComment({
                                variables: {
                                  input: {
                                    text,
                                    commentId: comment.id,
                                    jobId: job.id,
                                  },
                                },
                              }).then(() => {
                                track('Send comment', {
                                  job,
                                  'Comment Id': comment.id,
                                })
                              })
                            }
                            resetOnSuccess
                            hideSuccessAlert
                          >
                            {({ alertExists, alertProps, buttonProps }) => {
                              return (
                                <>
                                  <Textarea label="Your answer" name="text" />
                                  {alertExists && <Alert {...alertProps} />}
                                  <div className="field is-grouped is-grouped-multiline">
                                    <div className="control">
                                      <Button {...buttonProps}>Send</Button>
                                    </div>
                                  </div>
                                </>
                              )
                            }}
                          </Form>
                        }
                      />
                    ))
                  )}
                  {!job.blocked && !job.closed && !job.assigneeAcceptStartOffer && !me && (
                    <>
                      <p>Register to ask a question</p>
                    </>
                  )}
                  {!job.blocked && !job.closed && !job.assigneeAcceptStartOffer && me && !isMyJob && (
                    <div className={css.questionForm}>
                      <Form
                        validate={createValidator({
                          text: [required],
                        })}
                        onSubmit={({ text }) =>
                          sendComment({
                            variables: { input: { text, jobId: job.id } },
                          }).then(() => {
                            track('Send comment', {
                              job,
                              // 'Comment Id': comment.id,
                            })
                          })
                        }
                        resetOnSuccess
                        hideSuccessAlert
                      >
                        {({ alertExists, alertProps, buttonProps }) => {
                          return (
                            <>
                              <Textarea label="Your question" name="text" />
                              {alertExists && <Alert {...alertProps} />}
                              <div className="field is-grouped is-grouped-multiline">
                                <div className="control">
                                  <Button {...buttonProps}>Ask a Question</Button>
                                </div>
                              </div>
                            </>
                          )
                        }}
                      </Form>
                    </div>
                  )}
                </Segment>
                {!job.blocked && me && isMyJob && job.assigneeAcceptStartOffer && !job.assigneeReview && (
                  <Segment title="Wait service provider to post a review to you" />
                )}
                {!job.blocked && me && isMyJob && job.assigneeAcceptStartOffer && job.assigneeReview && (
                  <ReviewInfo title="Service provider review to you" review={job.assigneeReview} />
                )}
                {!job.blocked && me && isMyJob && job.assigneeAcceptStartOffer && !job.ownerReview && (
                  <Segment title="Post a review to service provider to complete this job">
                    <Form
                      validate={createValidator({
                        mark: [required],
                        text: [required],
                      })}
                      onSubmit={({ text, mark }) =>
                        createReview({
                          variables: { input: { text, mark, jobId: job.id } },
                        }).then(() => {
                          track('Create review', {
                            job,
                            Mark: mark,
                            'Sender role': 'owner',
                          })
                        })
                      }
                      hideSuccessAlert
                    >
                      {({ alertExists, alertProps, buttonProps }) => {
                        return (
                          <>
                            <RadioButtons label="Review type" name="mark">
                              <RadioButton name="mark" label="Positive" value="positive" />
                              <br />
                              <RadioButton name="mark" label="Neutral" value="neutral" />
                              <br />
                              <RadioButton name="mark" label="Negative" value="negative" />
                            </RadioButtons>
                            <Textarea label="Message" name="text" />
                            {alertExists && <Alert {...alertProps} />}
                            <div className="field is-grouped is-grouped-multiline">
                              <div className="control">
                                <Button {...buttonProps}>Post a Review</Button>
                              </div>
                            </div>
                          </>
                        )
                      }}
                    </Form>
                  </Segment>
                )}
                {!job.blocked && me && isMyJob && job.assigneeAcceptStartOffer && job.ownerReview && (
                  <ReviewInfo title="Your review to service provider" review={job.ownerReview} />
                )}
                {!job.blocked && me && isMeAssignee && job.assigneeAcceptStartOffer && !job.ownerReview && (
                  <Segment
                    title="
                      Wait customer to post a review to you"
                  />
                )}
                {!job.blocked && me && isMeAssignee && job.assigneeAcceptStartOffer && job.ownerReview && (
                  <ReviewInfo title="Customers review to you" review={job.ownerReview} />
                )}
                {!job.blocked && me && isMeAssignee && job.assigneeAcceptStartOffer && !job.assigneeReview && (
                  <Segment title="Post a review to customer to complete this job">
                    <Form
                      validate={createValidator({
                        mark: [required],
                        text: [required],
                      })}
                      onSubmit={({ text, mark }) =>
                        createReview({
                          variables: { input: { text, mark, jobId: job.id } },
                        }).then(() => {
                          track('Create review', {
                            job,
                            Mark: mark,
                            'Sender role': 'assignee',
                          })
                        })
                      }
                      hideSuccessAlert
                    >
                      {({ alertExists, alertProps, buttonProps }) => {
                        return (
                          <>
                            <RadioButtons label="Review type" name="mark">
                              <RadioButton name="mark" label="Positive" value="positive" />
                              <br />
                              <RadioButton name="mark" label="Neutral" value="neutral" />
                              <br />
                              <RadioButton name="mark" label="Negative" value="negative" />
                            </RadioButtons>
                            <Textarea label="Message" name="text" />
                            {alertExists && <Alert {...alertProps} />}
                            <div className="field is-grouped is-grouped-multiline">
                              <div className="control">
                                <Button {...buttonProps}>Post a Review</Button>
                              </div>
                            </div>
                          </>
                        )
                      }}
                    </Form>
                  </Segment>
                )}
                {!job.blocked && me && isMeAssignee && job.assigneeAcceptStartOffer && job.assigneeReview && (
                  <ReviewInfo title="Your review to customer" review={job.assigneeReview} />
                )}
              </PageSegment>
            </>
          </DocumentTitle>
        )
      }}
    </PageWrapper>
  )
}

const ReviewInfo = ({ review, title }) => (
  <Segment className={css.reviewInfo} title={title}>
    <ReviewMark className={css.mark} mark={review.mark} />
    <PrettyText className={css.text}>{review.text}</PrettyText>
  </Segment>
)

const ChatButton = ({ job, bid, setStartChatModalData }) => {
  const action = () => {
    setStartChatModalData({
      isOpen: true,
      jobId: job.id,
      bidOwnerId: bid.owner.id,
    })
  }
  if (bid.chatId)
    return (
      <Button outline to={getMyChatRoute(bid.chatId)}>
        Chat
      </Button>
    )
  if (job.closed) return null
  if (job.assigneeAcceptStartOffer && job.assignee && job.assignee.id === bid.owner.id)
    return (
      <Button outline type="button" onClick={action}>
        Send message
      </Button>
    )
  if (!job.assigneeAcceptStartOffer)
    return (
      <Button outline type="button" onClick={action}>
        Send message
      </Button>
    )
  return null
}

export default JobPage
