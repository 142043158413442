import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import css from './styles.module.scss'
import gql from 'graphql-tag'
import getJobStatus, { jobStatusFragment } from 'utils/getJobStatus'
import JobPreview, {
  jobPreviewFragment,
  jobWithBidPreviewFragment,
} from 'components/JobPreview'
import Link from 'components/Link'
import Button from 'components/Button'
import { getJobNewRoute } from 'routes'
import DashboardLayout from 'components/DashboardLayout'
import Segment from 'components/Segment'
import CategoryIcon from 'components/CategoryIcon'

const DashboardPage = ({ match, history }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      query={gql`
        query DashboardQuery(
          $myJobsInput: GetMyJobsInput
          $myBidsInput: GetMyBidsInput
        ) {
          getMyJobs(input: $myJobsInput) {
            jobs {
              ...JobStatus_job
              ...JobPreview_job
            }
          }
          getMyBids(input: $myBidsInput) {
            bids {
              ...JobWithBidPreview_bid
              job {
                ...JobStatus_job
              }
            }
          }
          getCategoryGroups {
            categoryGroups {
              id
              title
              alias
            }
          }
        }
        ${jobStatusFragment}
        ${jobPreviewFragment}
        ${jobWithBidPreviewFragment}
      `}
      parser={[
        ['jobs', 'getMyJobs.jobs'],
        ['bids', 'getMyBids.bids'],
        ['categoryGroups', 'getCategoryGroups.categoryGroups'],
      ]}
      authorizedOnly
      withMe
      variables={({ me }) => ({
        myJobsInput: { limit: 99, filter: 'active' },
        myBidsInput: { limit: 99, filter: 'active' },
      })}
      fetchPolicy="network-only"
      withLoader
    >
      {({ jobs, bids, categoryGroups, me }) => (
        <PageSegment title="Dashboard">
          <Segment title="Post a Task">
            <div className={css.postATask}>
              <div className={css.cards}>
                {categoryGroups.map((categoryGroup, i) => (
                  <div key={i} className={css.cardPlace}>
                    <Link
                      className={css.card}
                      to={getJobNewRoute({
                        categoryGroupAlias: categoryGroup.alias,
                      })}
                    >
                      <div className={css.iconPlace}>
                        <CategoryIcon
                          name={categoryGroup.alias}
                          className={css.icon}
                        />
                      </div>
                      <span className={css.name}>{categoryGroup.title}</span>
                    </Link>
                  </div>
                ))}
              </div>
              <div className={css.buttonPlace}>
                <Button to={getJobNewRoute()}>Post a Task</Button>
              </div>
            </div>
          </Segment>
          {!!jobs.length && (
            <Segment title="Active jobs">
              {jobs.map((job, i) => (
                <JobPreview key={i} job={job} status={getJobStatus(job, me)} />
              ))}
            </Segment>
          )}
          {!!bids.length && (
            <Segment title="Active bids">
              {bids.map((bid, i) => (
                <JobPreview
                  key={i}
                  job={bid.job}
                  status={getJobStatus(bid.job, me)}
                  bid={bid}
                />
              ))}
            </Segment>
          )}
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default DashboardPage
