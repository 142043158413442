import localStore from 'lib/localStore'
// https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

const initialState = {
  ...(localStore.get('globalState') || {}),
}

const reducer = (state, action) => {
  let newState
  switch (action.type) {
    case 'saveGetJobsInput':
      newState = {
        ...state,
        getJobsInput: {
          city: action.input.city,
          categoryIds: action.input.categoryIds,
          minPrice: action.input.minPrice,
          keywords: action.input.keywords,
          categoryGroupIds: action.input.categoryGroupIds,
          distance: action.input.distance,
        },
      }
      break

    case 'saveGetUsersInput':
      newState = {
        ...state,
        getUsersInput: {
          city: action.input.city,
          categoryIds: action.input.categoryIds,
          keywords: action.input.keywords,
          categoryGroupIds: action.input.categoryGroupIds,
        },
      }
      break

    case 'showAfterSignUpModal':
      newState = {
        ...state,
        isAfterSignUpModalOpen: true,
      }
      break

    case 'hideAfterSignUpModal':
      newState = {
        ...state,
        isAfterSignUpModalOpen: false,
      }
      break

    case 'showSuccessEmailConfirmationModal':
      newState = {
        ...state,
        isSuccessEmailConfirmationModalOpen: true,
      }
      break

    case 'hideSuccessEmailConfirmationModal':
      newState = {
        ...state,
        isSuccessEmailConfirmationModalOpen: false,
      }
      break

    case 'showPushAccessModal':
      newState = {
        ...state,
        isPushAccessModalOpen: true,
      }
      break

    case 'hidePushAccessModal':
      newState = {
        ...state,
        askedForPushAccess: true,
        isPushAccessModalOpen: false,
      }
      break

    case 'acceptCookies':
      newState = {
        ...state,
        cookiesAccepted: true,
      }
      break

    default:
      newState = state
  }

  localStore.set('globalState', newState)
  return newState
}

export { initialState, reducer }
