import React from 'react'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import PageSegment from 'components/PageSegment'

const PolicyPage = () => {
  return (
    <PageWrapper>
      <PageSegment title="Privacy notice">
        <div className={css.policy}>
          <p>This privacy policy is effective immediately.</p>
          <h2>Introduction</h2>
          <p>
            At SureTask (the "Company" or "We"), we respect your privacy and are
            committed to protecting it through our compliance with this policy.
          </p>
          <p>
            This policy describes the types of information we may collect from
            you or that you may provide when you visit this website (our
            "Website") and our practices for collecting, using, maintaining,
            protecting and disclosing that information.
          </p>
          <p>This policy applies to information we collect:</p>
          <ul>
            <li>On this Website.</li>
            <li>
              In e-mail, text and other electronic messages between you and this
              Website.
            </li>
            <li>
              Through mobile and desktop applications you download from this
              Website, which provide dedicated non-browser-based interaction
              between you and this Website.
            </li>
            <li>
              When you interact with our advertising and applications on
              third-party websites and services, if those applications or
              advertising include links to this policy.
            </li>
          </ul>
          <p>It does not apply to information collected by:</p>
          <ul>
            <li>
              us offline or through any other means, including on any other
              website operated by Company or any third party (including our
              affiliates and subsidiaries); or
            </li>
            <li>
              any third party (including our affiliates and subsidiaries),
              including through any application or content (including
              advertising) that may link to or be accessible from or on the
              Website.
            </li>
          </ul>
          <p>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. By accessing or using this Website, you agree to
            this privacy policy. This policy may change from time to time. Your
            continued use of this Website after we make changes is deemed to be
            acceptance of those changes, so please check the policy periodically
            for updates.
          </p>
          <h2>What data we collect</h2>
          <p>The personal data we collect from you includes:</p>
          <ul>
            <li>
              {' '}
              questions, queries or feedback you leave, including your email
              address if you contact <b>suretask.com</b>
            </li>
            <li>directly from you when you provide it to us</li>
            <li>
              {' '}
              your email address and subscription preferences when you sign up
              to our email alerts
            </li>
            <li>
              how you use our emails - for example whether you open them and
              which links you click on
            </li>
            <li>
              automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses
              and information collected through cookies, web beacons and other
              tracking technologies
            </li>
            <li>
              {' '}
              information on how you use the site, using cookies and page
              tagging techniques
            </li>
          </ul>
          <p>
            We use Google Analytics and MixPanel software to collect information
            about how you use <b>suretask.com</b>
          </p>
          <p>Google Analytics stores information about:</p>
          <ul>
            <li>
              the pages you visit on <b>suretask.com</b>
            </li>
            <li>
              how long you spend on each <b>suretask.com</b> page
            </li>
            <li>how you got to the site</li>
            <li>what you click on while you’re visiting the site</li>
          </ul>
          <p>
            We do not collect or store your personal information through Google
            Analytics (for example your name or address) so this information
            cannot be used to identify who you are.
          </p>
          <h2>Information You Provide to Us</h2>
          <p>
            The information we collect on or through our Website may include:
          </p>
          <ul>
            <li>
              Information that you provide by filling in forms on our Website.
              This includes information provided at the time of registering to
              use our Website, subscribing to our service, posting material or
              requesting further services. We may also ask you for information
              when you report a problem with our Website.
            </li>
            <li>
              Records and copies of your correspondence (including e-mail
              addresses), if you contact us.
            </li>
            <li>
              Your responses to surveys that we might ask you to complete for
              research purposes.
            </li>
            <li>
              Details of transactions you carry out through our Website and of
              the fulfillment of your orders. You may be required to provide
              financial information before placing an order through our Website.
            </li>
            <li>Your search queries on the Website.</li>
          </ul>
          <p>
            You also may provide information to be published or displayed
            (hereinafter, "posted") on public areas of the Website, or
            transmitted to other users of the Website or third parties
            (collectively, "User Contributions"). Your User Contributions are
            posted on and transmitted to others at your own risk. Additionally,
            we cannot control the actions of other users of the Website with
            whom you may choose to share your User Contributions. Therefore, we
            cannot and do not guarantee that your User Contributions will not be
            viewed by unauthorized persons.
          </p>
          <h2>Why we need your data</h2>
          <p>
            We collect information through Google Analytics to monitor how you
            use the site. We do this to help:
          </p>
          <ul>
            <li>make sure the site is meeting the needs of its users</li>
            <li>make improvements, for example improving site search</li>
          </ul>
          <p>We also collect data in order to:</p>
          <ul>
            <li>
              gather feedback to improve our services, for example our email
              alerts
            </li>
            <li>respond to any feedback you send us, if you’ve asked us to</li>
            <li>send email alerts to users who request them</li>
            <li>
              allow you to access government services and make transactions
            </li>
            <li>
              provide you with information about local services if you want it
            </li>
            <li>monitor use of the site to identify security threats</li>
          </ul>
          <p>
            The legal basis for processing personal data in relation to site
            security is our legitimate interests, and the legitimate interests
            of our users, in ensuring the security and integrity of{' '}
            <b>suretask.com</b>
          </p>
          <h2>How We Use Your Information</h2>
          <p>
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </p>
          <ul>
            <li>To present our Website and its contents to you.</li>
            <li>
              To provide you with information, products or services that you
              request from us.
            </li>
            <li>To provide you with information about our services</li>
            <li>
              To provide you with notices about your account/subscription,
              including expiration and renewal notices.
            </li>
            <li>
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To notify you about changes to our Website or any products or
              services we offer or provide though it.
            </li>
            <li>
              To allow you to participate in interactive features on our
              Website.
            </li>
            <li>
              In any other way we may describe when you provide the information.
            </li>
            <li>To fulfill any purpose for which you provide it.</li>
            <li>For any other purpose with your consent.</li>
          </ul>
          <p>
            We may use the information we have collected from you to enable us
            to display advertisements to our advertisers' target audiences. Even
            though we do not disclose your personal information for these
            purposes without your consent, if you click on or otherwise interact
            with an advertisement, the advertiser may assume that you meet its
            target criteria.
          </p>
          <p>
            We will share your data if we are required to do so by law – for
            example, by court order, or to prevent fraud or other crime.
          </p>
          <h2>Disclosure of Your Information</h2>
          <p>
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </p>
          <p>
            {' '}
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </p>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep personal information confidential and use it
              only for the purposes for which we disclose it to them.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution or other
              sale or transfer of some or all of the Company's assets, whether
              as a going concern or as part of bankruptcy, liquidation or
              similar proceeding, in which personal information held by the
              Company about our Website users is among the assets transferred.
            </li>
            <li>
              To third parties to market their products or services to you if
              you have consented to these disclosures. We contractually require
              these third parties to keep personal information confidential and
              use it only for the purposes for which we disclose it to them.
            </li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>
            <li>With your consent.</li>
          </ul>
          <p>We may also disclose your personal information:</p>
          <ul>
            <li>
              To comply with any court order, law or legal process, including to
              respond to any government or regulatory request.
            </li>
            <li>
              To enforce or apply our terms of use and other agreements,
              including for billing and collection purposes.
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers or
              others. This includes exchanging information with other companies
              and organizations for the purposes of fraud protection and credit
              risk reduction.
            </li>
          </ul>
          <h2>How long we keep your data</h2>
          <p>We will only retain your personal data for as long as:</p>
          <ul>
            <li>it is needed for the purposes set out in this document</li>
            <li>the law requires us to</li>
          </ul>
          <h2>Children’s privacy protection</h2>
          <p>
            Our Website is not intended for children under 13 years of age. No
            one under age 13 may provide any personal information to or on the
            Website. We do not knowingly collect personal information from
            children under 13. If you are under 13, do not use or provide any
            information on this Website or on or through any of its
            features/register on the Website, make any purchases through the
            Website, use any of the interactive or public comment features of
            this Website or provide any information about yourself to us,
            including your name, address, telephone number, e-mail address or
            any screen name or user name you may use.
          </p>
          <h2>Where your data is processed and stored</h2>
          <p>
            We design, build and run our systems to make sure that your data is
            as safe as possible at all stages, both while it’s processed and
            when it’s stored.
          </p>
          <p>
            Information that you submit via the Site and Application is sent to
            and stored on secure servers located in the European Union. This is
            necessary in order to process the information. Information submitted
            by you may be transferred by us to our other offices, which may be
            situated outside the European Economic Area (EEA) and may be
            processed by staff operating outside the EEA. The countries
            concerned may not have similar data protection laws to the EEA.
            Where we transfer your information we will take all reasonable steps
            to ensure that your privacy rights continue to be protected. By
            submitting information via the Site, you agree to this storing,
            processing and/or transfer.
          </p>
          <h2>How we protect your data and keep it secure</h2>
          <p>
            We are committed to doing all that we can to keep your data secure.
            We have set up systems and processes to prevent unauthorised access
            or disclosure of your data – for example, we protect your data using
            varying levels of encryption.
          </p>
          <p>
            We also make sure that any third parties that we deal with keep all
            personal data they process on our behalf secure.
          </p>
          <h2>Your rights</h2>
          <p>You have the right to request:</p>
          <ul>
            <li>information about how your personal data is processed</li>
            <li>a copy of that personal data</li>
            <li>
              that anything inaccurate in your personal data is corrected
              immediately
            </li>
          </ul>
          <p>You can also:</p>
          <ul>
            <li>
              raise an objection about how your personal data is processed
            </li>
            <li>
              request that your personal data is erased if there is no longer a
              justification for it
            </li>
            <li>
              ask that the processing of your personal data is restricted in
              certain circumstances
            </li>
          </ul>
          <h2>Links to other websites</h2>
          <p>
            <b>suretask.com</b> contains links to other websites.
          </p>
          <p>
            This privacy policy only applies to <b>suretask.com</b>, and does
            not cover other services and transactions that we link to. These
            services have their own terms and conditions and privacy policies.
          </p>
          <h3>Following a link to another website</h3>
          <p>
            If you go to another website from this one, read the privacy policy
            on that website to find out what it does with your information.
          </p>
          <h3>Following a link to suretask.com from another website</h3>
          <p>
            If you come to <b>suretask.com</b> from another website, we may
            receive information from the other website. We do not use this data.
            You should read the privacy policy of the website you came from to
            find out more about this.
          </p>
          <h2>Changes to this policy</h2>
          <p>
            We may change this privacy policy from time to time when we make a
            major change we will inform you via email. We may also post minor
            changes to this Policy, along with their effective date, at a
            well-articulated part of the Site. Please note that your continued
            use of SureTask after any change means that you agree with, and
            consent to be bound by the changes. If you disagree with any changes
            in this Policy please discontinue use of the Site.
          </p>
          <h2>Contact us or make a complaint</h2>
          <p>Contact us if you:</p>
          <ul>
            <li>have a question about anything in this privacy notice</li>
            <li>
              think that your personal data has been misused or mishandled
            </li>
          </ul>
          <p>
            Please contact us if you have any questions about this privacy
            notice or the information we hold about you.
          </p>
          <p>
            If you wish to contact us, please send an email to{' '}
            <a href="mailto:support@suretask.com">support@suretask.com </a>{' '}
            or write to 41 Florence Avenue, Hove, UK, BN3 7GX
          </p>
        </div>
      </PageSegment>
    </PageWrapper>
  )
}

export default PolicyPage
