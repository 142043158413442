import React from 'react'
import Form from 'components/Form'
import Input from 'components/Input'
import { createValidator, required, confirmPassword } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { track, setUserData } from 'utils/mixpanel'

const SetPassword = () => {
  const [setMyPassword] = useMutation(gql`
    mutation($input: SetMyPasswordInput!) {
      setMyPassword(input: $input) {
        me {
          id
          hasPassword
        }
      }
    }
  `)
  return (
    <Form
      validate={createValidator({
        newPassword: [required],
        confirmPassword: [
          required,
          confirmPassword({ passwordField: 'newPassword' }),
        ],
      })}
      onSubmit={({ newPassword }) =>
        setMyPassword({
          variables: {
            input: { newPassword },
          },
        }).then(() => {
          track('Set password')
          setUserData({ 'Has password': true })
        })
      }
      resetOnSuccess
      successMessage="Password set successfully"
    >
      {({ alertExists, alertProps, buttonProps }) => {
        return (
          <>
            <Input label="New password" name="newPassword" type="password" />
            <Input
              label="Repeat new password"
              name="confirmPassword"
              type="password"
            />
            {alertExists && <Alert {...alertProps} />}
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <Button {...buttonProps}>Set Password</Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default SetPassword
