import React from 'react'
// import React, { useState } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import SignUpForm from 'components/SignUpForm'
import { Buttons } from 'components/Button'
// import Button, { Buttons } from 'components/Button'
import howImgSrc1 from 'assets/images/landing-assignee/how/1.png'
import howImgSrc2 from 'assets/images/landing-assignee/how/2.png'
import howImgSrc3 from 'assets/images/landing-assignee/how/3.png'
import iphoneImageSrc from 'assets/images/other/iphone.jpg'
import { getServiceProviderFormRoute } from 'routes'
import { ReactComponent as IntroducingIcon1 } from 'assets/images/landing-assignee/introducing/1.svg'
import { ReactComponent as IntroducingIcon2 } from 'assets/images/landing-assignee/introducing/2.svg'
import { ReactComponent as IntroducingIcon3 } from 'assets/images/landing-assignee/introducing/3.svg'
import { ReactComponent as CheckMark } from 'assets/images/other/checkmark.svg'
import Slider from 'react-slick'
import SocialIcon from 'components/SocialIcon'

const AssigneeLandingPage = () => {
  // const [isSignUpModalOpen, setSignUpModalOpen] = useState(false)
  const reviews = [
    {
      image: '/images/promo-reviews/1v2.jpg',
      name: 'James',
      text:
        'I was able to find someone to help with my gardening needs so quickly!',
    },
    {
      image: '/images/promo-reviews/2v2.jpg',
      name: 'Joanne',
      text:
        'Easy to use and quick response from local professional, who I am very pleased with.',
    },
    {
      image: '/images/promo-reviews/3v2.jpg',
      name: 'Adam',
      text: 'Good platform to grow your business and get clients!',
    },
    {
      image: '/images/promo-reviews/4v2.jpg',
      name: 'Paul',
      text: 'Amazing platform for the clients and professionals.',
    },
    {
      image: '/images/promo-reviews/5v2.jpg',
      name: 'Ananya',
      text:
        'Have used Suretast a couple of times and have finally found reliable craftsmen.',
    },
    {
      image: '/images/promo-reviews/6v2.jpg',
      name: 'Karen',
      text:
        'I was contacted right away with highly qualified candidates. Fantastic service!',
    },
    {
      image: '/images/promo-reviews/7v2.jpg',
      name: 'Amanda',
      text: 'Efficient way to find highly qualified experts!',
    },
    {
      image: '/images/promo-reviews/8v2.jpg',
      name: 'Helen',
      text: 'I got quick responses.',
    },
    {
      image: '/images/promo-reviews/9v2.jpg',
      name: 'Keisha',
      text: 'The website works really well giving very fast results.',
    },
  ]
  return (
    <PageWrapper withMe>
      {({ me }) => (
        <>
          <div className={cn(css.header, css.section)}>
            <div className={css.wrapper}>
              <div className={css.cols}>
                <div className={css.left}>
                  <h1 className={css.title}>
                    <span className={css.firstLine}>Join Our Community</span>
                    <br />
                    Get Connected With Customers
                  </h1>
                  <p className={css.desc}>
                    Get a £5 free credit when you sign-up today with SureTask
                  </p>
                  <div className={css.triggersPlace}>
                    <div className={css.triggers}>
                      <div className={css.trigger}>
                        <CheckMark className={css.checkMark} />
                        <p className={css.text}>Decent income</p>
                      </div>
                      <div className={css.trigger}>
                        <CheckMark className={css.checkMark} />
                        <p className={css.text}>Flexible schedule</p>
                      </div>
                      <div className={css.trigger}>
                        <CheckMark className={css.checkMark} />
                        <p className={css.text}>Safe service</p>
                      </div>
                      <div className={css.trigger}>
                        <CheckMark className={css.checkMark} />
                        <p className={css.text}>Advertising savings</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.right}>
                  <div className={css.signUpForm}>
                    <h2 className={css.title}>
                      Sign up to your Pro account today and receive exclusive
                      tasks
                    </h2>
                    <SignUpForm
                      hideLogin
                      onSuccessRoute={getServiceProviderFormRoute()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.introducing, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Introducing SureTask
              </h2>
              <div className={css.content}>
                <p>
                  SureTask is a convenient service that helps UK customers
                  quickly and easily find trusted contractors for personal and
                  business needs.
                </p>
                <p>
                  Our goal is to build a trusted community platform that
                  connects people who need to outsource tasks and find local
                  services, with people who are looking to earn money and ready
                  to work.
                </p>
              </div>
              <div className={css.items}>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>1</p>
                    <IntroducingIcon1 className={css.icon} />
                  </div>
                  <p className={css.text}>Create your user profile</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>2</p>
                    <IntroducingIcon2 className={css.icon} />
                  </div>
                  <p className={css.text}>Get matched with customers</p>
                </div>
                <div className={css.item}>
                  <div className={css.pic}>
                    <p className={css.number}>3</p>
                    <IntroducingIcon3 className={css.icon} />
                  </div>
                  <p className={css.text}>Win the task</p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.howItWorks, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                How SureTask works
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <p className={css.imgPlace}>
                    <img alt="" src={howImgSrc1} className={css.img} />
                  </p>
                  <p className={css.itemTitle}>Customer places an ad</p>
                  <p className={css.text}>
                    Customer describes job that needs to be done.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.imgPlace}>
                    <img alt="" src={howImgSrc2} className={css.img} />
                  </p>
                  <p className={css.itemTitle}>
                    Select the one you want to complete
                  </p>
                  <p className={css.text}>
                    Browse a list of tasks, check the job details and reply
                    quickly. It’s now up to you to win the job.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.imgPlace}>
                    <img alt="" src={howImgSrc3} className={css.img} />
                  </p>
                  <p className={css.itemTitle}>Win the task</p>
                  <p className={css.text}>
                    Meet customers through our platform. Confirm details with
                    your customer. Complete the task nad submit your feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(css.tellMeMore, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Ok, tell me more
              </h2>
              <div className={css.items}>
                <div className={css.item}>
                  <p className={css.itemTitle}>How much will it cost me?</p>
                  <p className={css.text}>
                    Creating an account is free. There’s no signup or
                    subscription fee. You only pay £1 when you find a matching
                    customer's task and send a bid. If the customer does not
                    choose a service provider within 7 days then we'll refund
                    your bid in SureTask credit. We will credit you with £5 to
                    try our service.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.itemTitle}>What kinds of jobs can i get?</p>
                  <p className={css.text}>
                    Customers come to SureTask to get all kinds of projects
                    done. From fixing a leaking tap to a total home remodel —
                    pretty much anything. That means your jobs can range in
                    size, whether you want to pick up small jobs here and there,
                    or you’re looking for your next big job. Just create
                    business profile and start receiving leads.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.itemTitle}>
                    Why do customers use SureTask?
                  </p>
                  <p className={css.text}>
                    SureTask shows customers the pros who are available, ready
                    and willing to do exactly what they need. They can see
                    prices up front, view a professional’s past work, read
                    reviews and see if the pro they’re hiring has a background
                    check or valid license.
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.itemTitle}>
                    How do I find tasks on SureTask?
                  </p>
                  <p className={css.text}>
                    When a customer needs a professional, they’ll put in their
                    location and their task details. Then we show you a list of
                    tasks that could be a good fit. If what you want
                    matches what they want, we’ll put tasks on that list.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={cn(css.signUpSection, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                Sign up to your Pro account today and receive exclusive tasks
              </h2>
              <div className={css.signUp}>
                <SignUpForm
                  hideLogin
                  onSuccessRoute={getServiceProviderFormRoute()}
                />
              </div>
            </div>
          </div>

          <div className={cn(css.reviews, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>
                What customers are saying
              </h2>
              <div className={css.desktopItems}>
                {reviews.map((review, i) => (
                  <div key={i} className={css.item}>
                    <p className={css.imagePlace}>
                      <img alt="" src={review.image} className={css.image} />
                    </p>
                    <p className={css.name}>{review.name}</p>
                    <p className={css.text}>{review.text}</p>
                  </div>
                ))}
              </div>
              <div className={css.mobileItems}>
                <Slider dots={true}>
                  {reviews.map((review, i) => (
                    <div key={i} className={css.item}>
                      <p className={css.imagePlace}>
                        <img alt="" src={review.image} className={css.image} />
                      </p>
                      <p className={css.name}>{review.name}</p>
                      <p className={css.text}>{review.text}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div className={cn(css.contacts, css.section)}>
            <div className={css.wrapper}>
              <h2 className={cn(css.sectionTitle, css.title)}>Contacts</h2>
              <div className={css.items}>
                <div className={css.item}>
                  <p className={css.label}>Email us</p>
                  <p className={css.value}>
                    <Link href="mailto:support@suretask.com">
                      support@suretask.com
                    </Link>
                  </p>
                </div>
                <div className={css.item}>
                  <p className={css.label}>Follow us</p>
                  <Buttons>
                    <a href="https://www.facebook.com/SureTask.co.uk">
                      <SocialIcon name="facebook" />
                    </a>
                    <a href="https://www.instagram.com/SureTask.co.uk/">
                      <SocialIcon name="instagram" />
                    </a>
                  </Buttons>
                </div>
                <div className={css.item}>
                  <p className={css.label}>Chat with us</p>
                  <p className={css.value}>
                    The chat icon in the bottom right of your screen
                  </p>
                </div>
              </div>
              <div className={css.iphonePlace}>
                <img alt="" src={iphoneImageSrc} className={css.iphone} />
              </div>
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  )
}

// const FAQItem = ({ question, answer }) => {
//   const [visible, setVisibility] = useState(false)
//   return (
//     <div className={cn({ [css.item]: true, [css.open]: visible })}>
//       <div className={css.question} onClick={() => setVisibility(!visible)}>
//         <span className={css.text}>{question}</span>
//         <RightArrowIcon className={css.arrow} />
//       </div>
//       <div className={css.answer}>{answer}</div>
//     </div>
//   )
// }

export default AssigneeLandingPage
