import isEmail from 'validator/lib/isEmail'
import isInt from 'validator/lib/isInt'
import isIn from 'validator/lib/isIn'
import isURL from 'validator/lib/isURL'
import plural from 'utils/plural'

export const isEmpty = value => value === undefined || value === null || value === ''

const join = rules => (value, data) =>
  rules.map(rule => rule(value, data)).filter(error => !!error)[0]

export const email = value =>
  !isEmpty(value) && !isEmail(value) && value !== 'root' && 'Wrong e-mail'

export const url = value => !isEmpty(value) && !isURL(value) && 'Wrong URL'

export const required = value => isEmpty(value) && 'Required field'

export const minLength = (min = 6) => value =>
  !isEmpty(value) &&
  value.length < min &&
  `Must be at least ${min} ${plural(min, [
    'character',
    'characters',
    'characters',
  ])} long`

export const maxLength = (max = 128) => value =>
  !isEmpty(value) &&
  value.length > max &&
  `Should be no more than ${max} ${plural(max, [
    'character',
    'characters',
    'characters',
  ])}`

export const confirmPassword = ({ passwordField = 'password' } = {}) => (
  value,
  data
) => {
  return data && value !== data[passwordField] && 'Passwords should be same'
}

export const integer = value => !isEmpty(value) && !isInt(value) && 'Should be a number'

export const integerPositive = value => !isEmpty(value) && (!isInt(value) || +value < 0) && 'Should be a number'

export const oneOf = values => value =>
  !isIn(value, values) && `Должно быть одним из: ${values.join(', ')}`

export const match = field => (value, data) =>
  data && value !== data[field] && 'Должно совпадать'

export const createValidator = rules => (data = {}) => {
  const errors = {}

  Object.keys(rules).forEach(key => {
    const rule = join([].concat(rules[key]))
    const error = rule(data[key], data)
    if (error) {
      errors[key] = error
    }
  })
  return errors
}

export const minSum = (min = 5) => value =>  !isEmpty(value) &&
    value < min &&
    `Amount should be more or equal £ ${min}`
