import React from 'react'
import errorParser from 'utils/parseError'
import propsPreset from 'utils/propsPreset'
import { getSignUpRoute, getSignInRoute } from 'routes'
import PageSegment from 'components/PageSegment'
import Button, { Buttons } from 'components/Button'

const presets = {
  '404': {
    title: 'Page Not Found',
  },
  default: {
    title: 'Error',
  },
  unauthorized: {
    title: 'Login to view this page',
    children: () => (
      <Buttons>
        <Button to={getSignUpRoute()}>Sign Up</Button>
        <Button to={getSignInRoute()}>Log In</Button>
      </Buttons>
    ),
  },
  noperm: {
    title: 'Permission denied',
    message: 'You do not have permission to view this page.',
  },
}

const ErrorPageContent = props => {
  const { title, message, children, error } = propsPreset(props, presets)

  if (error) {
    const parsedError = errorParser(error)
    return (
      <ErrorPageContent
        title={presets.default.title}
        message={
          parsedError.isUnknown
            ? presets.default.message
            : parsedError.errorMessageHuman
        }
      />
    )
  }

  return (
    <PageSegment title={title} hideBody={!children && !message}>
      {message && <p>{message || children()}</p>}
      {children && children()}
    </PageSegment>
  )
}

export default ErrorPageContent
