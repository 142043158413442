import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

const Paper = ({ className, ...restProps }) => (
  <div
    className={cn({ [css.paper]: true, [className]: !!className })}
    {...restProps}
  />
)

export default Paper
