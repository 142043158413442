import React, { Component } from 'react'
import { Form as ReactFinalForm } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import parseError from 'utils/parseError'
import isFunction from 'lodash/isFunction'
import setFieldData from 'final-form-set-field-data'
import * as Sentry from '@sentry/browser'
import arrayMutators from 'final-form-arrays'

class Form extends Component {
  state = {
    submittedValues: null,
    submitSucceeded: false,
  }

  submitHandler = (values, formApi) => {
    const {
      onSubmit,
      onSuccess = () => {},
      onError = () => {},
      errorHandler,
      errorCodes,
      resetOnSuccess,
      hideSuccessAlert,
    } = this.props

    this.setState({ submittedValues: values, submitSucceeded: false })
    const submitResult = onSubmit(values, formApi)
    if (!submitResult || !submitResult.then) return submitResult
    return new Promise(resolve =>
      submitResult
        .then(result => {
          if (!hideSuccessAlert) this.setState({ submitSucceeded: true })
          onSuccess(result && result.data ? result.data : null, values)
          resetOnSuccess && setTimeout(() => formApi.reset(), 1)
          resolve()
        })
        .catch(err => {
          const parsedError = parseError(err, errorCodes)
          if (parsedError.isUnknown) Sentry.captureException(err)
          onError(parsedError)
          console.error(err)
          resolve(
            errorHandler
              ? errorHandler(err)
              : {
                  [FORM_ERROR]: parsedError.isUnknown
                    ? parsedError.errorMessageFull
                    : parsedError.errorMessageHuman,
                }
          )
        })
    )
  }

  render() {
    const {
      children,
      className,
      successMessage = 'Success',
      withPristine,
      hideSuccessAlert,
      showValidationAlert,
      success,
      ...restProps
    } = this.props

    return (
      <ReactFinalForm
        {...restProps}
        mutators={{
          setFieldValue: ([name, value], state, { setIn }) => {
            state.formState.values =
              setIn(state.formState.values, name, value) || {}
          },
          setFieldTouched: ([name], state) => {
            state.fields[name].touched = true
          },
          setFieldData,
          ...arrayMutators,
        }}
        onSubmit={this.submitHandler}
      >
        {({ ...reactFinalFormArgs }) => {
          const {
            handleSubmit,
            submitError,
            submitFailed,
            // submitSucceeded,
            hasValidationErrors,
            submitting,
            pristine,
          } = reactFinalFormArgs
          const validationFailed = submitFailed && hasValidationErrors
          const alertExists =
            !submitting &&
            (submitError ||
              (this.state.submitSucceeded && !hideSuccessAlert) ||
              (validationFailed && showValidationAlert))
          const alertType = !alertExists
            ? null
            : submitError || validationFailed
            ? 'fail'
            : 'success'
          const alertMessage = !alertExists
            ? null
            : submitError
            ? submitError
            : validationFailed
            ? 'Please fill the highlighted fields to continue'
            : isFunction(successMessage)
            ? successMessage({
                submittedValues: this.state.submittedValues,
              })
            : successMessage
          const childrenProps = {
            ...reactFinalFormArgs,
            alertExists,
            alertProps: { type: alertType, message: alertMessage },
            buttonProps: {
              disabled: submitting || (withPristine && pristine),
              loading: submitting,
            },
            submittedValues: this.state.submittedValues,
            forgetSuccess: () =>
              this.setState({
                submitSucceeded: false,
              }),
          }

          if (this.state.submitSucceeded && success)
            return (
              <div className={className}>
                {isFunction(success) ? success(childrenProps) : success}
              </div>
            )

          return (
            <form className={className} onSubmit={handleSubmit}>
              {children(childrenProps)}
            </form>
          )
        }}
      </ReactFinalForm>
    )
  }
}

export default Form
