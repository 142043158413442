import React from 'react'
import Modal from 'components/Modal'
import Segment from 'components/Segment'
import useGlobalState from 'lib/globalState'

const SuccessEmailConfirmationModal = () => {
  const [globalState, dispatchGlobalAction] = useGlobalState()
  return (
    <Modal
      isOpen={globalState.isSuccessEmailConfirmationModalOpen}
      onRequestClose={() =>
        dispatchGlobalAction({
          type: 'hideSuccessEmailConfirmationModal',
        })
      }
    >
      <Segment title="Your email successfully confirmed" titleType="noLine" />
    </Modal>
  )
}

export default SuccessEmailConfirmationModal
