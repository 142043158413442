import React from 'react'
import PageWrapper from 'components/PageWrapper'
import ChangeEmail from 'components/ChangeEmail'
import ProfileEditor from 'components/ProfileEditor'
import ContactsEditor from 'components/ContactsEditor'
import DeleteMe from 'components/DeleteMe'
import NewPassword from 'components/NewPassword'
import SetPassword from 'components/SetPassword'
import SetEmailPassword from 'components/SetEmailPassword'
import SocialLinks from 'components/SocialLinks'
import MyJobPreferencesEditor from 'components/MyJobPreferencesEditor'
import NotificationSettings from 'components/NotificationSettings'
import PageSegment from 'components/PageSegment'
import Paper from 'components/Paper'
import MyBidsTemplatesEditor from 'components/MyBidsTemplatesEditor'
import Alert from 'components/Alert'
import Segment from 'components/Segment'
import parseQueryString from 'utils/parseQueryString'
import gql from 'graphql-tag'
import DashboardLayout from 'components/DashboardLayout'

const MyProfilePage = ({ location }) => {
  return (
    <PageWrapper
      Layout={DashboardLayout}
      authorizedOnly
      query={gql`
        query {
          getMe {
            me {
              id
              email
              hasPassword
              canBeAssignee
              blocked
              blockComment
            }
          }
        }
      `}
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) => (
        <PageSegment title="Profile Settings" withPaper={false}>
          {me && me.blocked && (
            <Alert
              message={
                <>
                  Your account has been blocked:{' '}
                  {me.blockComment ? me.blockComment : ''}. Email us{' '}
                  <a href="mailto:support@suretask.com">
                    support@suretask.com
                  </a>{' '}
                  if you have any questions
                </>
              }
            />
          )}
          <Paper>
            <Segment title="General">
              <ProfileEditor />
            </Segment>
          </Paper>
          <Paper>
            <Segment
              title="Contacts"
              desc={
                me && me.canBeAssignee ? (
                  <>
                    If you are a service provider, your contacts will be
                    available to the customer as soon as you place a bid.
                    <br />
                    If you are a customer, your contacts will be only available
                    to the service provider you choose.
                  </>
                ) : (
                  <>
                    Your contacts will be only available to the service provider
                    you choose.
                  </>
                )
              }
            >
              <ContactsEditor />
            </Segment>
          </Paper>
          {me && me.canBeAssignee && (
            <Paper>
              <Segment
                collapsable
                initCollapsed={true}
                title="My job preferences"
                desc="
            Selected categories will be marked as your professional area. You will be receiving email task offers for selected categories. Email notifications can be disabled under Notification settings"
              >
                <MyJobPreferencesEditor />
              </Segment>
            </Paper>
          )}
          {me && me.canBeAssignee && (
            <Paper>
              <Segment
                collapsable
                initCollapsed={true}
                title="Response templates"
                desc="Prepare answer templates to use when responding to tasks"
              >
                <MyBidsTemplatesEditor />
              </Segment>
            </Paper>
          )}
          <Paper>
            <Segment
              collapsable
              initCollapsed={true}
              title="Linked social accounts"
              desc="Connect your social media accounts for single sign-on"
            >
              <SocialLinks
                linkFailed={
                  parseQueryString(location.search).link_created === 'false'
                }
              />
            </Segment>
          </Paper>
          {me && me.hasPassword && (
            <Paper>
              <Segment collapsable initCollapsed={true} title="Change password">
                <NewPassword />
              </Segment>
            </Paper>
          )}
          {me && !me.hasPassword && me.email && (
            <Paper>
              <Segment
                collapsable
                initCollapsed={true}
                title="Set password"
                desc="
              Set your password to login with email and password"
              >
                <SetPassword />
              </Segment>
            </Paper>
          )}
          {me && (me.hasPassword || me.email) && (
            <Paper>
              <Segment collapsable initCollapsed={true} title="E-mail settings">
                <ChangeEmail />
              </Segment>
            </Paper>
          )}
          {me && !me.hasPassword && !me.email && (
            <Paper>
              <Segment
                collapsable
                initCollapsed={true}
                title="Set e-mail & password"
                desc="Set your email and password to login with email and password"
              >
                <SetEmailPassword />
              </Segment>
            </Paper>
          )}
          <Paper>
            <Segment
              collapsable
              initCollapsed={true}
              title="Notifications settings"
            >
              <NotificationSettings />
            </Segment>
          </Paper>
          <Paper>
            <Segment
              collapsable
              initCollapsed={true}
              title="Delete your account"
            >
              <DeleteMe />
            </Segment>
          </Paper>
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default MyProfilePage
