import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Form from 'components/Form'
import Input from 'components/Input'
import Query from 'components/Query'
import { createValidator, email } from 'lib/validation'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { track, setUserData } from 'utils/mixpanel'
import Url from 'url-parse'

const ContactsEditor = () => {
  const [updateMyContacts] = useMutation(gql`
    mutation($input: UpdateMyContactsInput!) {
      updateMyContacts(input: $input) {
        me {
          id
          contacts {
            email
            phone
            instagram
            facebook
            website
          }
        }
      }
    }
  `)

  const instagramFieldHelper = value => {
    return socialFieldHelper('instagram', value)
  }
  const facebookFieldHelper = value => {
    return socialFieldHelper('facebook', value)
  }
  const socialFieldHelper = (host, value) => {
    if (!value) return null
    // eslint-disable-next-line no-useless-escape
    const pattern = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    const cleanedValue = value.replace('@', '')
    if (!cleanedValue) return null
    if (pattern.test(cleanedValue)) {
      const parsed = new Url(cleanedValue)
      parsed.set('protocol', 'https')
      parsed.set('hostname', `${host}.com`)
      parsed.set('port', '443')
      return parsed.href
    } else {
      return `https://${host}.com/${cleanedValue}`
    }
  }

  return (
    <Query
      query={gql`
        query {
          getMe {
            me {
              id
              contacts {
                email
                phone
                instagram
                facebook
                website
              }
            }
          }
        }
      `}
      withLoader
      parser={[['me', 'getMe.me']]}
    >
      {({ me }) =>
        !me ? null : (
          <Form
            validate={createValidator({
              email: [email],
            })}
            onSubmit={input =>
              updateMyContacts({
                variables: {
                  input: {
                    email: input.email || null,
                    phone: input.phone || null,
                    instagram: instagramFieldHelper(input.instagram) || null,
                    facebook: facebookFieldHelper(input.facebook) || null,
                    website: input.website,
                  },
                },
              }).then(() => {
                track('Update contacts', {
                  Email: input.email || null,
                  Phone: input.phone || null,
                  Facebook: input.facebook || null,
                  Instagram: input.instagram || null,
                })
                setUserData({
                  'Contact email': input.email || null,
                  'Contact phone': input.phone || null,
                  'Contact facebook': input.facebook || null,
                  'Contact instagram': input.instagram || null,
                })
              })
            }
            initialValues={{
              ...me.contacts,
            }}
            resetOnSuccess
            successMessage="Contacts updated successfully"
          >
            {({ alertExists, alertProps, buttonProps }) => {
              return (
                <>
                  <Input label="Phone" name="phone" />
                  <Input label="E-mail" name="email" />
                  <Input label="Instagram" name="instagram" />
                  <Input label="Facebook" name="facebook" />
                  <Input label="Website" name="website" />
                  {alertExists && <Alert {...alertProps} />}
                  <div className="field is-grouped is-grouped-multiline">
                    <div className="control">
                      <Button {...buttonProps}>Save Changes</Button>
                    </div>
                  </div>
                </>
              )
            }}
          </Form>
        )
      }
    </Query>
  )
}

export default ContactsEditor
