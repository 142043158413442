import mixpanel from 'mixpanel-browser'
import get from 'lodash/get'
import toPairs from 'lodash/toPairs'
import find from 'lodash/find'
import pick from 'lodash/pick'
import gql from 'graphql-tag'
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY)
const defaultSilent = false
const shouldSendData = process.env.REACT_APP_MIXPANEL_ON === 'true'

export const events = {
  'Click link': ({ To, From, Tags = [] }) => {
    return { To, From, Tags }
  },
  'Visit page': ({ Url }) => {
    return { Url }
  },
  'Create job': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Is new user', 'Is business account']),
    }
  },
  'Update job': ({ job }) => {
    return entities.job({ job })
  },
  'Close job': ({ job }) => {
    return entities.job({ job })
  },
  'Create bid': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Bid price']),
    }
  },
  'Update bid': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Bid price']),
    }
  },
  'Make start offer': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Assignee Id']),
    }
  },
  'Cancel start offer': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Assignee Id']),
    }
  },
  'Accept start offer': ({ job }) => {
    return entities.job({ job })
  },
  'Reject start offer': ({ job }) => {
    return entities.job({ job })
  },
  'Create review': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Mark', 'Sender role']),
    }
  },
  'Send comment': data => {
    return {
      ...entities.job({ job: data.job }),
      ...pick(data, ['Comment id']),
    }
  },
  'Sign in': ({ Method }) => {
    return { Method }
  },
  'Sign up': ({ Method }) => {
    return { Method }
  },
  'Link social network': ({ Method }) => {
    return { Method }
  },
  'Request email changing': ({ Email }) => {
    return { Email }
  },
  'Update contacts': ({ Email, Phone, Facebook, Instagram }) => {
    return { Email, Phone, Facebook, Instagram }
  },
  Transaction: data => {
    return pick(data, ['Amount', 'With card saving', 'By saved card'])
  },
  'Delete stripe card': () => ({}),
  'Change password': () => ({}),
  'Set email and password': () => ({}),
  'Set password': () => ({}),
  'Confirm new email': () => ({}),
  'Confirm email': () => ({}),
  'Update job preferences': data =>
    pick(data, [
      'Categories',
      'Preferred distance',
      'Preferred city',
      'Preferred keywords',
    ]),
  'Job search': data =>
    pick(data, [
      'Categories',
      'Distance',
      'City',
      'Min. price',
      'Keywords',
      'Results exists',
    ]),
  'Users search': data =>
    pick(data, ['Categories', 'City', 'Keywords', 'Results exists']),
  'Request password reset': ({ Email }) => ({ Email }),
  'Update notification preferences': data =>
    pick(data, ['Accepted notifications']),
  'Update push notification preferences': data =>
    pick(data, ['Accepted push notifications']),
  'Unsubscribe email': data => pick(data, ['Accepted notifications']),
  'User become assignee': () => ({}),
  'User update bid templates': () => ({}),
  'User use bid template': () => ({}),
  'Request resend email confirmation': () => ({}),
  'Update profile info': () => ({}),
  'User send job invation': data => pick(data, ['Recipient id', 'Job id']),
  'User start chat': data => pick(data, ['Recipient id', 'Job id', 'Chat id']),
  'User send message': data =>
    pick(data, ['Recipient id', 'Job id', 'Chat id']),
  'Add push subscription': () => ({}),
  'Update comment': () => ({}),
}

export const entities = {
  job: ({ job }) => {
    return {
      'Job id': get(job, 'id'),
      'Job title': get(job, 'title'),
      'Job average price': get(job, 'averagePrice'),
      'Job max price': get(job, 'maxPrice'),
      'Job created at': get(job, 'createdAt'),
      'Job remote': get(job, 'remote'),
      'Job city': get(job, 'city'),
      'Job category group': get(job, 'category.categoryGroup.title', null),
      'Job category': get(job, 'category.title', null),
      'Job bids count': job.bids ? job.bids.length : null,
      'Job photos count': get(job, 'photos', []).length,
      'Job assignee id': valueOrNull(job, 'assignee.id'),
      'Job owner id': get(job, 'owner.id'),
      'Job assigneeAcceptStartOffer': get(job, 'assigneeAcceptStartOffer'),
      'Job owner review mark': valueOrNull(job, 'ownerReview.mark'),
      'Job assignee review mark': valueOrNull(job, 'assigneeReview.mark'),
      'Job closed': get(job, 'closed'),
      'Job blocked': get(job, 'blocked'),
    }
  },
  bid: ({ bid }) => {
    return {
      // courseTitle: get(courseVersion, 'title'),
      // courseAlias: get(course, 'alias'),
      // courseId: get(course, 'id'),
      // courseVersionId: get(courseVersion, 'id'),
      // courseVersionNumber: get(courseVersion, 'number'),
      // courseVersionTrialLength: get(courseVersion, 'trialLength'),
      // courseVersionDaysCount: get(courseVersion, 'daysCount'),
    }
  },
  user: ({ user }) => {
    const oauths = get(user, 'oauth')
    return {
      'User Id': get(user, 'id'),
      $email: get(user, 'email'),
      'About exists': booleanOrUndefined(user, 'about'),
      $name: get(user, 'name'),
      City: get(user, 'city'),
      Avatar: get(user, 'avatar'),
      'Cover image': get(user, 'coverImage'),
      'Contact email': valueOrNull(user, 'contacts.email'),
      'Contact phone': valueOrNull(user, 'contacts.phone'),
      'Contact facebook': valueOrNull(user, 'contacts.facebook'),
      'Contact instagram': valueOrNull(user, 'contacts.instagram'),
      'Email confirmed': get(user, 'emailConfirmed'),
      'As assignee poisitive reviews count': get(
        user,
        'asAssigneePoisitiveReviewsCount'
      ),
      'As assignee negative reviews count': get(
        user,
        'asAssigneeNegativeReviewsCount'
      ),
      'As assignee neutral reviews count': get(
        user,
        'asAssigneeNeutralReviewsCount'
      ),
      'As owner poisitive reviews count': get(
        user,
        'asOwnerPoisitiveReviewsCount'
      ),
      'As owner negative reviews count': get(
        user,
        'asOwnerNegativeReviewsCount'
      ),
      'As owner neutral reviews count': get(user, 'asOwnerNeutralReviewsCount'),
      Balance: get(user, 'balance'),
      'Stripe cards count': get(user, 'stripeCards', []).length,
      'Bid templates count': get(user, 'bidTemplates', []).length,
      'Has password': get(user, 'hasPassword'),
      'Is business account': get(user, 'isBusiness'),
      'Registration number': get(user, 'regNumber'),
      'Can be assignee': get(user, 'canBeAssignee'),
      'Linked with facebook': !!find(oauths, { provider: 'facebook' }),
      'Linked with instagram': !!find(oauths, { provider: 'instagram' }),
      'Linked with google': !!find(oauths, { provider: 'google' }),
      $created: get(user, 'createdAt'),
    }
  },
}

const attrs = [
  'Url',
  'Recipient id',
  'Chat id',
  'User id',
  'Job id',
  'Job title',
  'Job average price',
  'Job max price',
  'Job created at',
  'Job remote',
  'Job city',
  'Job category group',
  'Job category',
  'Job bids count',
  'Job photos count',
  'Job assignee id',
  'Job owner id',
  'Job assigneeAcceptStartOffer',
  'Job owner review mark',
  'Job assignee review mark',
  'Job closed',
  'Job blocked',
  'User Id',
  '$email',
  'About exists',
  '$name',
  'City',
  'Avatar',
  'Contact email',
  'Contact phone',
  'Contact facebook',
  'Contact instagram',
  'Email confirmed',
  'As assignee poisitive reviews count',
  'As assignee negative reviews count',
  'As assignee neutral reviews count',
  'As owner poisitive reviews count',
  'As owner negative reviews count',
  'As owner neutral reviews count',
  'Balance',
  'Stripe cards count',
  'Has password',
  'Linked with facebook',
  'Linked with instagram',
  'Linked with google',
  '$created',
  'Method',
  'Email',
  'Phone',
  'Facebook',
  'Instagram',
  'Amount',
  'With card saving',
  'By saved card',
  'Bid price',
  'Assignee Id',
  'Mark',
  'Sender role',
  'Categories',
  'Distance',
  'Min. price',
  'Keywords',
  'Results exists',
  'Is new user',
  'Accepted notifications',
  'Accepted push notifications',
  'Preferred distance',
  'Preferred city',
  'Preferred keywords',
  'Is business account',
  'Company website',
  'Registration number',
  'Can be assignee',
  'Bid templates count',
  'Recipient id',
  'Has push subscriptions',
  'Cover image',
  'Comment Id',
]

export const fragments = {}

fragments.user = gql`
  fragment Mixpanel_user on User {
    id
    email
    about
    name
    city
    avatar
    coverImage
    contacts {
      phone
      email
      facebook
      instagram
      website
    }
    emailConfirmed
    asAssigneePoisitiveReviewsCount
    asAssigneeNegativeReviewsCount
    asAssigneeNeutralReviewsCount
    asOwnerPoisitiveReviewsCount
    asOwnerNegativeReviewsCount
    asOwnerNeutralReviewsCount
    balance
    stripeCards {
      paymentMethodId
    }
    hasPassword
    oauth {
      provider
    }
    createdAt
    isBusiness
    regNumber
    canBeAssignee
    bidTemplates
  }
`

fragments.job = gql`
  fragment Mixpanel_job on Job {
    id
    translitTitle
    title
    averagePrice
    maxPrice
    createdAt
    remote
    city
    category {
      id
      title
      categoryGroup {
        id
        title
      }
    }
    bids {
      id
    }
    photos
    owner {
      id
    }
    assignee {
      id
    }
    ownerReview {
      id
      mark
    }
    assigneeReview {
      id
      mark
    }
    assigneeAcceptStartOffer
    closed
    blocked
  }
`

function booleanOrUndefined(obj, path) {
  const value = get(obj, path)
  return value === undefined ? undefined : !!value
}

function valueOrNull(obj, path) {
  const value = get(obj, path)
  return value === undefined ? null : value
}

// function booleanOrUndefinedInverse(obj, path) {
//   const value = get(obj, path)
//   return value === undefined ? undefined : !value
// }

function getMixpanelData(data, resolver, methodName) {
  const parsedData = resolver(data)
  const mixpanelData = {}

  for (const [parsedDataKey, parsedDataValue] of toPairs(parsedData)) {
    if (parsedDataValue === undefined) {
      console.warn(`Mixpanel ${methodName} missing attr "${parsedDataKey}".`)
      return false
    } else if (!attrs.includes(parsedDataKey)) {
      console.warn(
        `Mixpanel ${methodName} undefined attr "${parsedDataKey}". Add it to available attrs or fix it name.`
      )
      return false
    } else {
      mixpanelData[parsedDataKey] = parsedDataValue
    }
  }

  return mixpanelData
}

export const track = (eventName, eventData = {}, silent = defaultSilent) => {
  return new Promise(resolve => {
    const event = events[eventName]
    if (!event) {
      console.warn(`Mixpanel undefined event "${eventName}"`)
      return resolve()
    }
    const mixpanelEventData = getMixpanelData(
      eventData,
      event,
      `event "${eventName}"`
    )
    if (!mixpanelEventData) {
      return resolve()
    }
    if (!silent)
      console.info('Mixpanel event', eventName, {
        ...mixpanelEventData,
      })
    if (shouldSendData) {
      return mixpanel.track(eventName, mixpanelEventData, resolve)
    } else {
      return resolve()
    }
  })
}

export const setUserData = (userData = {}, silent = defaultSilent) => {
  return new Promise(resolve => {
    const mixpanelUserData = userData.user
      ? getMixpanelData({ user: userData.user }, entities.user, 'set user data')
      : userData
    if (!mixpanelUserData) {
      return resolve()
    }
    if (!silent) console.info('Mixpanel set user data', { ...mixpanelUserData })
    mixpanelUserData['User Id'] &&
      identifyUser(mixpanelUserData['User Id'], silent)
    if (shouldSendData) {
      return mixpanel.people.set(mixpanelUserData, resolve)
    } else {
      return resolve()
    }
  })
}

export const trackCharge = (amount, silent = defaultSilent) => {
  return new Promise(resolve => {
    if (!silent) console.info('Mixpanel track charge', amount)
    if (shouldSendData) {
      mixpanel.people.increment('Total spent', amount)
      return mixpanel.people.track_charge(amount)
    } else {
      return resolve()
    }
  })
}

export const identifyUser = (userId, silent = defaultSilent) => {
  if (!silent) console.info('Mixpanel identify', userId)
  if (shouldSendData) return mixpanel.identify(userId)
}

export const aliasUser = (userId, silent = defaultSilent) => {
  return new Promise(resolve => {
    if (!silent) console.info('Mixpanel alias', userId)
    if (shouldSendData) {
      mixpanel.alias(userId)
      return resolve()
    } else {
      return resolve()
    }
  })
}

export const getMixpanelId = () => {
  return shouldSendData ? mixpanel.get_distinct_id() : null
}

export const toMixpanelCategories = (categoryIds, categoryGroups) => {
  const lang = {}
  for (const categoryGroup of categoryGroups) {
    for (const category of categoryGroup.categories) {
      lang[category.id] = `${categoryGroup.title}: ${category.title}`
    }
  }
  return categoryIds.map(categoryId => lang[categoryId])
}
