import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import SignUpForm from 'components/SignUpForm'

const SignUpPage = () => {
  return (
    <PageWrapper redirectAuthorized>
      <PageSegment title="Sign Up">
        <SignUpForm />
      </PageSegment>
    </PageWrapper>
  )
}

export default SignUpPage
