import React from 'react'
import Reviews from 'components/Reviews'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import DashboardLayout from 'components/DashboardLayout'

const MyReviewsPage = () => {
  return (
    <PageWrapper Layout={DashboardLayout} authorizedOnly withMe>
      {({ me }) => (
        <PageSegment title="My Reviews">
          <Reviews userId={me.id} />
        </PageSegment>
      )}
    </PageWrapper>
  )
}

export default MyReviewsPage
