import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import { ReactComponent as PositiveIcon } from 'assets/images/reviews-icons/positive.svg'
import { ReactComponent as NeutralIcon } from 'assets/images/reviews-icons/neutral.svg'
import { ReactComponent as NegativeIcon } from 'assets/images/reviews-icons/negative.svg'
import uppercaseFirst from 'utils/uppercaseFirst'

const ReviewMark = ({ mark, className, ...restProps }) => {
  return (
    <div
      className={cn({ [css.reviewMark]: true, [css[mark]]: true, [className]: !!className })}
      {...restProps}
    >
      <div className={css.icons}>
        <PositiveIcon
          className={cn(css.icon, css.positive)}
          width={14}
          height={14}
        />
        <NegativeIcon
          className={cn(css.icon, css.negative)}
          width={14}
          height={14}
        />
        <NeutralIcon
          className={cn(css.icon, css.neutral)}
          width={14}
          height={14}
        />
      </div>
      <p className={css.text}>{uppercaseFirst(mark)} feedback</p>
    </div>
  )
}

export default ReviewMark
