import React, { useEffect } from 'react'
import ChatLayout from 'components/ChatLayout'
import Layout from 'components/Layout'
import ChatsList from 'components/ChatsList'
import ErrorPage from 'pages/ErrorPage'
import ChatContent from 'components/ChatContent'
import PageWrapper from 'components/PageWrapper'
import PageSegment from 'components/PageSegment'
import { getMyChatRoute } from 'routes'
import { useQuery } from '@apollo/react-hooks'
import batchGet from 'utils/batchGet'
import DashboardLayout from 'components/DashboardLayout'
import gql from 'graphql-tag'

const turnDashlyOff = () => {
  const dashlyEl =
    document.querySelector('#carrotquest-messenger-collapsed-container') ||
    document.querySelector('#carrotquest-messenger-collapsed')
  if (dashlyEl) {
    dashlyEl.style.display = 'none'
  }
}

const MyChatsPage = ({ match, history }) => {
  const chatId = match.params.chatId
  const openChat = chatId => {
    history.push(getMyChatRoute(chatId))
  }
  useEffect(() => {
    if (process.env.REACT_APP_DASHLY_ON === 'true') {
      turnDashlyOff()
      const interval = setInterval(() => {
        turnDashlyOff()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [])
  const { loading, data } = useQuery(
    gql`
      query {
        getMe {
          me {
            id
            hasChats
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const { me } = batchGet(data, [['me', 'getMe.me']])
  if (loading) return <Layout>Loading...</Layout>
  if (!me) return <ErrorPage Layout={Layout} preset="unauthorized" />
  if (!me.hasChats)
    return (
      <PageWrapper Layout={DashboardLayout}>
        <PageSegment
          title="Your chats"
          desc="You don’t have any active chats yet. You can start chatting with Service Providers who bid on your jobs. If you become Service Provider and bid on other jobs, task owners might message you for details."
          titleAlign="left"
          withPaper={false}
        />
      </PageWrapper>
    )
  return (
    <ChatLayout
      chatId={chatId}
      leftCol={<ChatsList openChat={openChat} chatId={chatId} />}
      rightCol={<ChatContent chatId={chatId} />}
    />
  )
}

export default MyChatsPage
