import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import Button from 'components/Button'
import PageWrapper from 'components/PageWrapper'
import { getHomeRoute } from '../../routes'

const Page404 = () => {
  return (
    <PageWrapper>
      {' '}
      <div className={cn('columns','is-centered', css.wrapper)}>
        <div className="column is-3" />
        <div className="column">
          <img alt="" className={css.image} src="images/404/logo_404.png" />
        </div>
        <div className={cn('column', 'is-6', css.rightColumn)}>
          <div>
            <h1 className={css.h1}>OOPS!</h1>
            <h2 className={css.h2}>We couldn't find the page you are looking for.</h2>
            <Button color="green" className={css.btn} shadow to={getHomeRoute()}>
              Homepage
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Page404
