import React from 'react'

export default {
  UNKNOWN_ERROR: 'Unknown error',
  EMAIL_IS_INVALID: 'E-mail address is invalid',
  USER_ALREADY_EXISTS: 'User with this e-mail address already exists',
  INVALID_EMAIL_OR_PASS: 'Wrong e-mail or password',
  CITY_AND_ADDRESS_REQUIRED_FOR_LOCAL_JOB:
    'City and address required for not remote job',
  MAX_OR_AVERAGE_PRICE_REQUIRED: 'Price required',
  MAX_OR_AVERAGE_PRICE_ONLY_ONE_OF:
    'Only one of avarage or max price should be setted',
  INVALID_PASSWORD: 'Wrong old password',
  UNAUTHORIZED: 'Unauthorized',
  CAN_EDIT_YOUR_JOB_ONLY: 'You can edit your job only',
  NOT_FOUND: 'Not found',
  CANT_BID_YOUR_JOB: `You can't post a bid to your job`,
  BID_ALREADY_EXISTS: 'You already post a bid for this job',
  INSUFFICIENT_BALANCE: 'Insufficient balance',
  CAN_EDIT_YOUR_BID_ONLY: 'You can edit your bids onlye',
  INVALID_CONFIRM_EMAIL_TOKEN: 'Email confirmation token is invalid',
  INVALID_PASSWORD_RESET_TOKEN: 'Your password reset token is invalid',
  AT_LEAST_ONE_CONTACT_NEEDED: 'At least one contact needed',
  AT_LEAST_ONE_CATEGORY_NEEDED: 'Please, choose at least one category',
  FORBIDDEN: 'This action is forbidden',
  JOB_BLOCKED: 'This job has been suspended by our moderators',
  NOT_ALL_FORMS_FILLED: 'Please fill all required forms to continue',
  USER_ALREADY_INVITED: 'You have already invited this user to this task.',
  INVITE_LIMIT_REACHED:
    'You have reached maximum invitation limit for this task.',
  // USER_BLOCKED: 'Your account has been blocked.',
  USER_BLOCKED: (
    <>
      Your account has been blocked.
      Email us{' '}
      <a href="mailto:support@suretask.com">support@suretask.com</a> if you
      have any questions
    </>
  ),
}
