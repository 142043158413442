import React, { useCallback, useEffect } from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import InfiniteScroll from 'react-infinite-scroller'
import Alert from 'components/Alert'
import gql from 'graphql-tag'
import getUploadUrl from 'utils/getUploadUrl'
import truncate from 'utils/truncate'
import toHumanDate from 'utils/toHumanDate'
import orderBy from 'lodash/orderBy'
import * as Sentry from '@sentry/browser'
import batchGet from 'utils/batchGet'
import { useQuery } from '@apollo/react-hooks'
import uniqBy from 'lodash/uniqBy'

const getMyChatsQuery = gql`
  query GetMyChats($input: GetMyChatsInput) {
    getMyChats(input: $input) {
      chats {
        id
        updatedAt
        lastMessageText
        hasUnreadMessages
        recipient {
          id
          avatar
          displayName
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`

const ChatsList = ({ chatId, openChat }) => {
  const { data, loading, fetchMore, error } = useQuery(getMyChatsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: 1,
      },
    },
  })
  const { pageInfo, chats = [], startCursor } = batchGet(data, [
    ['chats', 'getMyChats.chats'],
    ['pageInfo', 'getMyChats.pageInfo'],
    ['startCursor', 'getMyChats.pageInfo.startCursor'],
  ])
  const loadLatest = useCallback(
    () =>
      fetchMore({
        variables: {
          input: {
            startCursor: startCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return {
            getMyChats: {
              ...fetchMoreResult.getMyChats,
              pageInfo: {
                ...prev.getMyChats.pageInfo,
                startCursor: fetchMoreResult.getMyChats.pageInfo.startCursor,
              },
              chats: uniqBy(
                [...fetchMoreResult.getMyChats.chats, ...prev.getMyChats.chats],
                chat => chat.id
              ),
            },
          }
        },
      }),
    [startCursor, fetchMore]
  )
  useEffect(() => {
    if (loading || error) return undefined
    const interval = setInterval(() => {
      loadLatest()
    }, 5000)
    return () => clearInterval(interval)
  }, [loading, error, loadLatest])
  if (loading) return <>Loading...</>
  if (error) {
    Sentry.captureException(error)
    return <>Error</>
  }
  return (
    <div className={css.chatsList}>
      {!chats.length ? (
        <Alert message="There are no chats." />
      ) : (
        <InfiniteScroll
          loadMore={() => {
            if (pageInfo.hasNextPage) {
              fetchMore({
                variables: {
                  input: {
                    endCursor: pageInfo.endCursor,
                    limit: 10,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  return {
                    getMyChats: {
                      ...fetchMoreResult.getMyChats,
                      pageInfo: {
                        ...fetchMoreResult.getMyChats.pageInfo,
                        startCursor: prev.getMyChats.pageInfo.startCursor,
                      },
                      chats: [
                        ...prev.getMyChats.chats,
                        ...fetchMoreResult.getMyChats.chats,
                      ],
                    },
                  }
                },
              })
            }
          }}
          hasMore={pageInfo.hasNextPage}
          loader={<div className="loader" key={0} />}
          useWindow={false}
        >
          {orderBy(chats, ['updatedAt'], ['desc']).map((chat, i) => (
            <div
              key={i}
              className={cn({
                [css.item]: true,
                [css.unread]: chat.hasUnreadMessages,
                [css.active]: chat.id === chatId,
              })}
              onClick={() => openChat(chat.id)}
            >
              <div className={css.avatarPlace}>
                <img
                  className={css.avatar}
                  src={getUploadUrl(chat.recipient.avatar, 'avatar')}
                  alt=""
                />
              </div>
              <div className={css.content}>
                <p className={css.name}>{chat.recipient.displayName}</p>
                <p className={css.date}>{toHumanDate(chat.updatedAt)}</p>
                <p className={css.lastMessageText}>
                  {truncate(chat.lastMessageText, 50)}
                </p>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default ChatsList
