import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Button from 'components/Button'

const SocialLink = ({ provider, linkUrl, isSet, disabled }) => {
  const name = {
    facebook: 'Facebook',
    instagram: 'Instagram',
    google: 'Google +',
    apple: 'Apple',
  }[provider]

  const [unLink] = useMutation(gql`
    mutation($input: UnlinkSocialInput!) {
      unlinkSocial(input: $input) {
        me {
          id
          oauth {
            provider
            key
          }
        }
      }
    }
  `)
  return !isSet ? (
    <Button color={provider} href={linkUrl}>
      Link {name}
    </Button>
  ) : (
    <Button
      href="#null"
      color={provider}
      disabled={disabled}
      onClick={e => {
        e.preventDefault()
        if (!disabled) {
          unLink({
            variables: {
              input: {
                provider: provider,
              },
            },
          })
        }
      }}
    >
      Unlink {name}
    </Button>
  )
}

export default SocialLink
